<template>
  <el-drawer
    v-model="show"
    :with-header="false"
    direction="btt"
    :append-to-body="true"
    size="100%"
  >
    <div class="allContent divFlex alignCenter flexCenter">
      <div class="box_camera">
        <svg
          @click="clearYSY()"
          class="box_camera_clear"
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="currentColor"
            d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm0 393.664L407.936 353.6a38.4 38.4 0 1 0-54.336 54.336L457.664 512 353.6 616.064a38.4 38.4 0 1 0 54.336 54.336L512 566.336 616.064 670.4a38.4 38.4 0 1 0 54.336-54.336L566.336 512 670.4 407.936a38.4 38.4 0 1 0-54.336-54.336L512 457.664z"
          ></path>
        </svg>
        <div class="topLine"></div>
        <!-- 顶部菜单 -->
        <div class="cameraInfo divFlex alignCenter flexBet">
          <div class="imgNameCollect divFlex alignEnd">
            <img
              v-if="globalData.monitorType == '智能球机'"
              src="@/assets/detection/2048.png"
              alt=""
            />

            <img
              v-else-if="globalData.monitorType == '一体机'"
              src="@/assets/detection/2049.png"
              alt=""
            />

            <img v-else src="@/assets/detection/2045.png" alt="" />
            <div class="cameraName">{{ camera.monitorName }}</div>
            <svg
              @click="setCollect(camera.isCollect ? false : true)"
              :style="
                camera.isCollect
                  ? 'width:18px;color:rgba(255, 205, 6, 1);'
                  : 'width:18px;color:rgba(161, 184, 205, 1);'
              "
              viewBox="0 0 1024 1024"
              xmlns="http://www.w3.org/2000/svg"
              data-v-ea893728=""
            >
              <path
                fill="currentColor"
                d="M283.84 867.84 512 747.776l228.16 119.936a6.4 6.4 0 0 0 9.28-6.72l-43.52-254.08 184.512-179.904a6.4 6.4 0 0 0-3.52-10.88l-255.104-37.12L517.76 147.904a6.4 6.4 0 0 0-11.52 0L392.192 379.072l-255.104 37.12a6.4 6.4 0 0 0-3.52 10.88L318.08 606.976l-43.584 254.08a6.4 6.4 0 0 0 9.28 6.72z"
              ></path>
            </svg>
            <span
              @click="setCollect(false)"
              v-if="camera.isCollect"
              class="focusPoint"
              >取消关注</span
            >
            <span @click="setCollect(true)" v-else class="focusPoint"
              >关注站点</span
            >
          </div>
          <div class="divFlex riBtns">
            <div
              @click="
                () => {
                  chooseBtn = '实时直播';
                }
              "
              :style="chooseBtn == '实时直播' ? '' : 'background:none'"
              class="eventBtn divFlex alignCenter flexCenter"
              v-if="this.menuConfigArr.includes('实时直播')"
            >
              <img
                v-if="chooseBtn == '实时直播'"
                src="@/assets/detection/d1.png"
                alt=""
              />
              <span>实时直播</span>
            </div>
            <div
              @click="
                () => {
                  chooseBtn = '录像拍照';
                }
              "
              v-if="this.menuConfigArr.includes('录像拍照')"
              :style="chooseBtn == '录像拍照' ? '' : 'background:none'"
              class="eventBtn divFlex alignCenter flexCenter"
            >
              <img
                v-if="chooseBtn == '录像拍照'"
                src="@/assets/detection/d2.png"
                alt=""
              />
              <span>录像拍照</span>
            </div>
            <div
              @click="
                () => {
                  chooseBtn = '云闪播';
                }
              "
              :style="chooseBtn == '云闪播' ? '' : 'background:none'"
              class="eventBtn divFlex alignCenter flexCenter"
              v-if="this.menuConfigArr.includes('云闪播')"
            >
              <img
                v-if="chooseBtn == '云闪播'"
                src="@/assets/detection/d3.png"
                alt=""
              />
              <span>云闪播</span>
            </div>

            <div
              @click="
                () => {
                  chooseBtn = '历史记录';
                }
              "
              :style="chooseBtn == '历史记录' ? '' : 'background:none'"
              class="eventBtn divFlex alignCenter flexCenter"
              v-if="this.menuConfigArr.includes('历史记录')"
            >
              <img
                v-if="chooseBtn == '历史记录'"
                src="@/assets/detection/d1.png"
                alt=""
              />
              <span>历史记录</span>
            </div>

            <div
              @click="
                () => {
                  chooseBtn = '预警事件';
                }
              "
              :style="chooseBtn == '预警事件' ? '' : 'background:none'"
              class="eventBtn divFlex alignCenter flexCenter"
              v-if="this.menuConfigArr.includes('预警事件')"
            >
              <img
                v-if="chooseBtn == '预警事件'"
                src="@/assets/detection/d4.png"
                alt=""
              />
              <el-badge
                :value="
                  detailType.indexOf('重点人员') != -1
                    ? peopleTotal
                    : detailType.indexOf('AI识别') != -1
                    ? AITotal
                    : warnList.length
                "
                :max="10000000"
                class="item"
                type="primary"
              >
                <span>{{
                  detailType.indexOf("重点人员") != -1
                    ? "预警事件"
                    : detailType.indexOf("AI识别") != -1
                    ? "AI识别"
                    : "预警事件"
                }}</span>
              </el-badge>
            </div>
            <div
              @click="
                () => {
                  chooseBtn = '车辆通行';
                }
              "
              :style="chooseBtn == '车辆通行' ? '' : 'background:none'"
              class="eventBtn divFlex alignCenter flexCenter"
              v-if="this.menuConfigArr.includes('车辆通行')"
            >
              <img
                v-if="chooseBtn == '车辆通行'"
                src="@/assets/dete/eee.png"
                alt=""
              />
              <el-badge
                :value="carList.length"
                :max="10000000"
                class="item"
                type="primary"
              >
                <span>车辆通行</span>
              </el-badge>
            </div>
          </div>
        </div>
        <div class="div10"></div>

        <!-- 内容部分 -->
        <!-- 历史记录 -->
        <transition name="el-zoom-in-top">
          <!-- @change="calendarChange" -->
          <div class="column" v-if="chooseBtn == '历史记录'">
            <div class="divFlex piker" style="margin-top: 50px; width: 500px">
              <el-date-picker
                size="small"
                value-format="YYYY-MM-DD HH:mm:ss"
                v-model="recordTime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              />
            </div>
            <div class="box_camera_content_record">
              <playback
                :dateTime="recordTime"
                :cameraIndexCode="camera.deviceNumber"
                ref="childRef"
                classStr="box_camera_content_record"
              />
            </div>
          </div>
        </transition>

        <!-- 实时直播 -->
        <transition name="el-zoom-in-top">
          <div class="box_camera_content" v-show="chooseBtn == '实时直播'">
            <div v-if="isDrawing" class="selection" :style="divStyle"></div>
            <!-- <div
            style="height: 100%;width:100%"
              @mousedown="startSelection"
              @mouseup="endSelection"
              @mousemove="updateSelection"
            > -->
            <div
              class="allOtherView"
              v-if="
                moreCameraData && moreCameraData.length > 0 && switchVideoShow
              "
              v-drag
            >
              <div v-for="(item, idx) in moreCameraData" :key="item + idx">
                <div
                  class="viewItem hid"
                  @mouseout="mouseoutFun(item)"
                  @mouseover="mouseenterFun(item)"
                  @click="switchVideo(item, idx)"
                  v-show="state[`videContainerssShow` + item.id]"
                >
                  <div
                    class="viewTop divFlex flexBet alignCenter"
                    v-show="state['videContainerss' + item.id]"
                    @mouseout="mouseoutFun(item)"
                    @mouseover="mouseenterFun(item)"
                  >
                    <span>{{ item.monitorName || "--" }}</span>
                    <svg
                      @click.stop="hideItem(item)"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1024 1024"
                    >
                      <path
                        fill="currentColor"
                        d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z"
                      ></path>
                    </svg>
                  </div>
                  <Hk
                    :idStr="'videContainerss' + item.id"
                    :allUrls="livePlayUrlOther"
                    splitNum="1"
                    :mode="1"
                    :chooseBtn="chooseBtn"
                    :needCapture="true"
                  />
                </div>
                <img
                  class="hideV"
                  @click.stop="state[`videContainerssShow` + item.id] = true"
                  v-show="!state[`videContainerssShow` + item.id]"
                  src="@/assets/xinan/ooo.png"
                  alt=""
                />
              </div>
            </div>
            <Hk
              v-if="livePlayUrl && !isHui && switchVideoShow"
              idStr="videContainer"
              :camera="camera"
              :allUrls="livePlayUrl"
              splitNum="1"
              :mode="1"
              :chooseBtn="chooseBtn"
              :needCapture="true"
            />
            <!-- </div> -->

            <!-- <div class="recordBox relative" v-if="!isHui">
              <live-player
                video-url="http://192.168.110.53:83/openUrl/bvQuvEA/playback.m3u8?beginTime=20240205T231055&endTime=20240206T145752"
                live="true"
                autoplay="true"
                fluent
                stretch
                aspect="fullscreen"
              ></live-player>
            </div> -->

            <transition name="el-zoom-in-left">
              <!-- 添加预设视角 -->
              <DetectionPresuppositionView
                v-if="viewShow && camera.monitorType == '智能球机'"
                :setUp="true"
                :array="viewArr"
                @closeViewShow="
                  () => {
                    viewShow = false;
                  }
                "
                :token="token"
                :cameraInfo="camera"
                type="2"
                @setViewArray="(val) => (viewArr = val)"
                @setiStreamType="setiStreamType"
              />
            </transition>

            <img
              @click="clickViewShow()"
              v-if="!viewShow && camera.monitorType == '智能球机'"
              class="rightArrow"
              src="@/assets/detection/eeee.png"
              alt=""
            />
            <!-- 云台 -->
            <Viewcamera
              v-if="camera.monitorType == '智能球机'"
              :token="token"
              :cameraInfo="camera"
            />
          </div>
        </transition>
        <!-- 录像拍照 -->
        <transition name="el-zoom-in-top">
          <div
            class="box_camera_content_camera bet"
            v-if="chooseBtn == '录像拍照'"
          >
            <div class="detectionLiveBox">
              <div class="live_box" v-if="recordUrl">
                <img
                  :src="recordUrl"
                  v-if="recordValue == 2"
                  style="width: 100%; height: 100%"
                  alt=""
                />
                <live-player
                  v-else
                  :video-url="recordUrl"
                  live="true"
                  autoplay="true"
                  fluent
                  stretch
                  aspect="fullscreen"
                ></live-player>
              </div>
            </div>

            <div class="cutLine"></div>

            <div class="cameraRecordList">
              <div class="column hid height100">
                <div class="divFlex">
                  <div
                    :style="recordValue == 1 ? '' : 'background:none;'"
                    class="camerabtns divFlex alignCenter flexCenter"
                    @click="
                      () => {
                        recordValue = 1;
                      }
                    "
                  >
                    <span>录像</span>
                  </div>
                  <div
                    :style="recordValue == 2 ? '' : 'background:none;'"
                    class="camerabtns divFlex alignCenter flexCenter"
                    @click="
                      () => {
                        recordValue = 2;
                      }
                    "
                  >
                    <span>抓拍</span>
                  </div>
                </div>
                <div class="div20"></div>

                <el-scrollbar class="grow column">
                  <template v-if="recordValue == 1">
                    <div
                      class="listItem divFlex"
                      @click="
                        () => {
                          recordUrl = item.value;
                        }
                      "
                      style="margin-bottom: 10px"
                      v-for="(item, i) in cameraRecordList"
                      :key="i + 'fgdgfhgf'"
                    >
                      <div class="videoImgBox">
                        <img
                          :src="item.value + '?vframe/png/offset/5'"
                          class="avatar"
                        />
                        <img
                          class="playBtn"
                          src="@/assets/dete/播放.png"
                          alt=""
                        />
                      </div>
                      <div class="column flexBet alignEnd">
                        <div class="itemName">
                          {{ item.label }}
                        </div>
                        <!-- <div class="timeText">{{ item.label }}</div> -->
                        <div class="divFlex">
                          <img
                            @click.stop="downFile(item.value)"
                            class="download"
                            src="@/assets/detection/d5.png"
                            alt=""
                          />
                          <!-- <img src="@/assets/detection/d6.png" alt=""> -->
                        </div>
                      </div>
                    </div>
                  </template>

                  <template v-if="recordValue == 2">
                    <div
                      class="listItem divFlex"
                      @click="
                        () => {
                          recordUrl = item.value;
                        }
                      "
                      style="margin-bottom: 10px"
                      v-for="(item, i) in imagesRecordList"
                      :key="i + 'fdsfgdfhgf'"
                    >
                      <div class="videoImgBox">
                        <img :src="item.value" class="avatar" />
                      </div>
                      <div class="column flexBet alignEnd">
                        <div class="itemName">
                          {{ item.label }}
                        </div>
                        <!-- <div class="timeText">{{ item.label }}</div> -->
                        <div class="divFlex">
                          <img
                            class="download"
                            @click.stop="downFile(item.value)"
                            src="@/assets/detection/d5.png"
                            alt=""
                          />
                          <!-- <img src="@/assets/detection/d6.png" alt=""> -->
                        </div>
                      </div>
                    </div>
                  </template>
                </el-scrollbar>
              </div>
            </div>
          </div>
        </transition>

        <!-- 云闪播 -->
        <transition name="el-zoom-in-top">
          <div
            class="box_camera_content_camera bet"
            v-if="chooseBtn == '云闪播'"
          >
            <div class="detectionLiveBox">
              <div class="live_box" v-if="videoUrl">
                <live-player
                  :video-url="videoUrl"
                  live="true"
                  autoplay="true"
                  fluent
                  stretch
                  aspect="fullscreen"
                ></live-player>
              </div>
            </div>

            <div class="cutLine"></div>

            <div class="cameraRecordList">
              <div class="column hid height100">
                <DetectionTilte
                  style="margin-left: -20px"
                  title="云闪播列表"
                  englishText="CLOUD FLASH SEEDING"
                />
                <div class="div15"></div>
                <div class="shanboCountBox divFlex alignEnd">
                  <span>云闪播总数</span>
                  <div class="shanboCount divFlex alignEnd">
                    <div class="num">{{ camera.cloudNumber }}</div>
                    <div class="unit">条</div>
                  </div>
                </div>
                <div class="div30"></div>
                <el-scrollbar class="grow">
                  <el-timeline>
                    <el-timeline-item
                      v-for="(video, i) in videoList"
                      :key="i + 'fdsgsdhkkl'"
                    >
                      <div class="listItem column">
                        <div class="timeLine">{{ i }}</div>
                        <div
                          class="divFlex listItemVideo"
                          :style="
                            videoUrl == item.filePath
                              ? ''
                              : 'background:none;border-bottom:none;'
                          "
                          @click="seeThisVideo(item)"
                          v-for="(item, idx) in video"
                          :key="idx + 'kjfhskjdhgkj'"
                        >
                          <div class="videoImgBox">
                            <img :src="item.previewImage" class="avatar" />
                            <img
                              class="playBtn"
                              src="@/assets/dete/播放.png"
                              alt=""
                            />
                          </div>
                          <div class="column">
                            <div class="itemName">
                              {{ item.address || "--" }}
                            </div>
                            <div class="timeText">{{ item.playPeriod }}</div>
                            <div class="divFlex">
                              <img
                                @click.stop="
                                  downloadVideo(item.filePath, item.address)
                                "
                                class="download"
                                src="@/assets/detection/d5.png"
                                alt=""
                              />
                              <img
                                @click.stop="delVideo(item)"
                                src="@/assets/detection/d6.png"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-timeline-item>
                  </el-timeline>
                </el-scrollbar>
              </div>
            </div>
          </div>
        </transition>

        <!-- 预警事件 -->

        <transition name="el-zoom-in-top">
          <div
            class="box_camera_content_camera bet"
            v-if="
              chooseBtn == '预警事件' &&
              detailType.indexOf('重点人员') == -1 &&
              detailType.indexOf('AI识别') == -1
            "
          >
            <transition name="el-fade-in-left">
              <div class="warningList column height100" v-if="leftListshow">
                <DetectionTilte
                  style="margin-left: -20px"
                  title="预警列表"
                  englishText="WARNING LIST"
                />
                <div class="div15"></div>
                <div class="bet">
                  <el-select
                    v-if="eventTypes.length > 0"
                    v-model="TypeValue"
                    placeholder="事件类型"
                    clearable
                    ref="selectUpResId1"
                    @clear="handleClear"
                    size="small"
                  >
                    <el-option
                      v-for="item in eventTypes[1].children"
                      :key="item.value"
                      :label="item.title"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                  <!-- <el-select
                    style="margin: 0 2px"
                    v-model="statusValue"
                    ref="selectUpResId2"
                    placeholder="事件状态"
                    size="small"
                    clearable
                    @clear="handleClear2"
                  >
                    <el-option
                      v-for="item in eventStatus"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select> -->
                  <el-select
                    v-model="directionValue"
                    placeholder="监控方位"
                    ref="selectUpResId3"
                    size="small"
                    clearable
                    @clear="handleClear3"
                  >
                    <el-option
                      v-for="item in eventDirection"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </div>
                <div class="div20"></div>
                <el-scrollbar class="grow">
                  <el-timeline
                    v-infinite-scroll="switchPage"
                    :infinite-scroll-immediate="false"
                  >
                    <el-timeline-item
                      @click="getDetail(warn)"
                      :class="warnId == warn.id ? 'backColor' : ''"
                      v-for="(warn, i) in warnList.slice(0, current * 10)"
                      :key="i"
                    >
                      <div class="listItem column">
                        <div class="divFlex bet">
                          <div class="divFlex alignCenter">
                            <div
                              :class="
                                'timeLine' +
                                '  ' +
                                globalData.all_Status_hjf[warn.status]
                              "
                            >
                              {{ warn.createTime }}
                            </div>
                            <div
                              :style="`color:${
                                [
                                  '',
                                  '',
                                  '',
                                  'rgba(235, 84, 56, 1)',
                                  '',
                                  '',
                                  'rgba(255, 205, 6, 1)',
                                  'rgba(2, 152, 251, 1)',
                                  'rgba(15, 243, 238, 1)',
                                ][Number(warn.status)]
                              }`"
                              class="statusText"
                            >
                              {{ globalData.all_Status_hjf[warn.status] }}
                            </div>
                          </div>
                          <div v-if="!warn.isRead" class="warnDot"></div>
                        </div>

                        <div class="eventName">
                          {{ warn.title }}
                        </div>
                        <div class="divFlex flexWrap">
                          <div class="eventDesc">
                            <span>站点：</span>
                            <span>{{ warn.monitorName }}</span>
                          </div>
                          <div class="eventDesc">
                            <span>方位：</span>
                            <span>{{ warn.azimuth }}</span>
                          </div>
                          <div class="eventDesc">
                            <span>类型：</span>
                            <span>{{ warn.typeName }}</span>
                          </div>
                          <div class="eventDesc">
                            <span>网格：</span>
                            <span>{{ warn.gridName }}</span>
                          </div>
                        </div>
                        <div class="div10"></div>
                        <div style="width: 100%; height: 120px">
                          <SwiperImage
                            type="视频图片"
                            perview="2.3"
                            :blow="true"
                            v-if="warn.showImages && warn.showImages.length > 0"
                            :images="warn.showImages"
                          />
                        </div>
                      </div>
                    </el-timeline-item>
                  </el-timeline>
                </el-scrollbar>
              </div>
              <div v-else class="gapDiv"></div>
            </transition>

            <div
              class="divFlex alignCenter leftArrow"
              :style="!leftListshow ? 'left:32px;' : ''"
              @click="
                () => {
                  leftListshow = !leftListshow;
                }
              "
            >
              <span class="openLeft" v-if="!leftListshow">展开列表</span>
              <img
                :style="!leftListshow ? 'rotate:180deg;' : ''"
                src="@/assets/detection/ee.png"
                alt=""
              />
            </div>

            <div class="warningCenter column flexBet grow">
              <div class="liveBox childVideoBox">
                <!-- v-if="centerDetailInfo.videoUrl" -->

                <img
                  style="width: 100%; height: 100%"
                  v-if="
                    video_Url &&
                    centerDetailInfo.photoUrl.indexOf(video_Url) != -1
                  "
                  :src="video_Url"
                  alt=""
                />
                <!-- <Hk /> -->
                <!-- <Hk
                  v-else
                  idStr="videContainer"
                  :allUrls="livePlayUrl"
                  splitNum="1"
                /> -->
                <live-player
                  v-else
                  :video-url="video_Url"
                  live="true"
                  autoplay="true"
                  fluent
                  stretch
                  id="play001"
                  aspect="fullscreen"
                ></live-player>

                <playback
                  v-if="RecordVideoShow"
                  :dateTime="recordVideoTime"
                  :cameraIndexCode="warnInfo.deviceNumber"
                  ref="childVideo"
                  classStr="childVideoBox"
                />

                <div
                  class="warnBtnPlayBack"
                  v-if="!RecordVideoShow"
                  @click="showRecordVideo()"
                >
                  预警视频
                </div>
                <img
                  v-else
                  @click="closeRecordVideo()"
                  class="warnBtnPlayBackClose"
                  src="@/assets/images/d6.png"
                  alt=""
                />
              </div>
              <div
                class="allImageList"
                v-if="
                  centerDetailInfo.showImages &&
                  centerDetailInfo.showImages.length > 0
                "
              >
                <SwiperImage
                  type="视频图片"
                  perview="6.8"
                  @setVideoUrl="
                    (url) => {
                      video_Url = url;
                    }
                  "
                  :images="centerDetailInfo.showImages"
                />
              </div>
            </div>

            <div v-if="warnInfo" class="warningRight column height100">
              <div class="eventName">
                {{ warnInfo.title }}
                <span
                  :style="`
                  background:${
                    [
                      '',
                      '',
                      '',
                      'rgba(255, 69, 55, 0.2)',
                      '',
                      '',
                      'rgba(253, 226, 37, 0.2)',
                      'rgba(2, 152, 251, 0.2)',
                      'rgba(15, 243, 238, 0.2)',
                    ][Number(warnInfo.status)]
                  };                                                                                                                                                                                    color:${
                    [
                      '',
                      '',
                      '',
                      'rgba(235, 84, 56, 1)',
                      '',
                      '',
                      'rgba(255, 205, 6, 1)',
                      'rgba(2, 152, 251, 1)',
                      'rgba(15, 243, 238, 1)',
                    ][Number(warnInfo.status)]
                  }`"
                  class="statusBtn"
                  >{{ globalData.all_Status_hjf[warnInfo.status] }}</span
                >
              </div>
              <div class="divFlex flexWrap">
                <div class="eventDesc">
                  <span>站点：</span>
                  <span>{{ warnInfo.monitorName }}</span>
                </div>
                <div class="eventDesc">
                  <span>方位：</span>
                  <span>{{ warnInfo.azimuth }}</span>
                </div>
                <div class="eventDesc">
                  <span>类型：</span>
                  <span>{{ warnInfo.typeName }}</span>
                </div>
                <div class="eventDesc">
                  <span>网格：</span>
                  <span>{{ warnInfo.gridName }}</span>
                </div>
                <div class="eventDesc">
                  <span>时间：</span>
                  <span>{{ warnInfo.createTime }}</span>
                </div>
              </div>
              <div class="div10"></div>
              <div class="div30"></div>
              <el-scrollbar class="grow">
                <el-timeline>
                  <el-timeline-item
                    @click="chooseThisStep(detail)"
                    :class="detail.id == centerDetailInfo.id ? 'backColor' : ''"
                    v-for="(detail, i) in detailList"
                    :key="i + 'hgfjhgjk'"
                  >
                    <div class="listItem column">
                      <div class="timeText bet" style="align-items: flex-start">
                        <span>{{ detail.content }}</span>
                        <span
                          style="flex-shrink: 0"
                          :class="
                            'status' +
                            '  ' +
                            globalData.all_Status_hjf[detail.status]
                          "
                          >{{ globalData.all_Status_hjf[detail.status] }}</span
                        >
                      </div>
                      <div class="divFlex flexWrap">
                        <div class="eventDesc">
                          <span>站点：</span>
                          <span>{{ detail.monitorName }}</span>
                        </div>
                        <div class="eventDesc">
                          <span>方位：</span>
                          <span>{{ detail.azimuth }}</span>
                        </div>
                        <div class="eventDesc">
                          <span>类型：</span>
                          <span>{{ detail.typeName }}</span>
                        </div>
                        <div class="eventDesc">
                          <span>网格：</span>
                          <span>{{ detail.gridName }}</span>
                        </div>
                      </div>
                      <div class="div10"></div>
                      <div style="width: 100%; height: 120px">
                        <SwiperImage
                          type="视频图片"
                          :blow="true"
                          perview="2.3"
                          v-if="
                            detail.showImages && detail.showImages.length > 0
                          "
                          :images="detail.showImages"
                        />
                      </div>
                    </div>
                  </el-timeline-item>
                </el-timeline>
              </el-scrollbar>
            </div>
          </div>
        </transition>

        <!-- 车辆通行 -->

        <transition name="el-zoom-in-top">
          <div
            class="box_camera_content_camera bet"
            v-if="chooseBtn == '车辆通行'"
          >
            <transition name="el-fade-in-left">
              <div class="warningList column height100" v-if="carleftListshow">
                <DetectionTilte
                  style="margin-left: -20px"
                  title="车辆通行列表"
                  englishText="VEHICLE TRAFFIC LIST"
                />
                <div class="div15"></div>
                <div class="bet selectRow">
                  <el-select
                    v-model="carTypeValue"
                    placeholder="车辆类型"
                    clearable
                    ref="selectUpResId11"
                    @clear="handleClearCar"
                    size="small"
                  >
                    <el-option
                      v-for="item in globalData.chooseBtns.工程车事件类型"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                    >
                    </el-option>
                  </el-select>

                  <el-select
                    style=""
                    clearable
                    v-model="statusValueCar"
                    ref="selectUpResIdCar"
                    placeholder="进出状态"
                    size="small"
                    @clear="handleClear2Car"
                  >
                    <el-option
                      v-for="item in globalData.chooseBtns.事件状态"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </div>
                <div class="div20"></div>
                <el-scrollbar class="grow">
                  <el-timeline
                    v-infinite-scroll="getPage"
                    :infinite-scroll-immediate="false"
                  >
                    <el-timeline-item
                      v-for="(warn, i) in carList"
                      :key="i + 'fdshggffkui'"
                      :class="warnIdCar == warn.licensePlate ? 'backColor' : ''"
                      @click="getCarDetailFun(warn)"
                    >
                      <div class="listItem column">
                        <div class="divFlex bet">
                          <div class="divFlex alignCenter">
                            <div :class="'timeLine' + '  ' + warn.direction">
                              {{ warn.direction }}
                            </div>
                            <div
                              :class="`statusText ${warn.direction + 'Text'}`"
                            >
                              {{ warn.azimuth }}
                            </div>
                          </div>
                          <div class="divFlex alignCenter">
                            <span class="carTime">{{ warn.createTime }}</span>
                            <div
                              v-if="!warn.isRead"
                              class="warnDot"
                              style="margin-left: 12px"
                            ></div>
                          </div>
                        </div>

                        <div class="carNumBox divFlex alignCenter">
                          <div
                            :class="
                              'carNum ' +
                              (warn.licensePlateColor
                                ? warn.licensePlateColor
                                : '灰')
                            "
                          >
                            <div class="carNumBefore divFlex alignCenter">
                              <span>{{ warn.licensePlate }}</span>
                              <i
                                style="font-size: 20px; margin-left: 10px"
                                class="iconfont"
                                v-html="warn.icon"
                              ></i>
                            </div>
                          </div>
                          <div class="enter_out divFlex alignEnd">
                            <span>经</span>
                            <span style="color: rgb(255, 205, 6)">{{
                              warn.unknownCount || "0"
                            }}</span>
                          </div>
                          <div class="enter_out divFlex alignEnd">
                            <span>进</span>
                            <span>{{ warn.intoCount }}</span>
                          </div>
                          <div class="enter_out divFlex alignEnd">
                            <span>出</span>
                            <span style="color: rgb(0, 191, 127)">{{
                              warn.outCount
                            }}</span>
                          </div>
                        </div>
                        <div class="div10"></div>
                        <div class="column flexWrap">
                          <div class="eventDesc">
                            <span>电话：</span>
                            <span>{{ warn.phone }}</span>
                          </div>
                          <div class="eventDesc">
                            <span>站点：</span>
                            <span>{{ warn.monitorName }}</span>
                          </div>
                          <div class="eventDesc">
                            <span>状态：</span>
                            <span :class="warn.status">{{ warn.status }}</span>
                          </div>
                        </div>
                      </div>
                    </el-timeline-item>
                  </el-timeline>
                </el-scrollbar>
              </div>
              <div v-else class="gapDiv"></div>
            </transition>

            <div
              class="divFlex alignCenter leftArrow"
              :style="!carleftListshow ? 'left:32px;' : 'left:327px;'"
              @click="
                () => {
                  carleftListshow = !carleftListshow;
                }
              "
            >
              <span class="openLeft" v-if="!carleftListshow">展开列表</span>
              <img
                :style="!carleftListshow ? 'rotate:180deg;' : ''"
                src="@/assets/detection/ee.png"
                alt=""
              />
            </div>

            <div class="warningCenter column flexBet grow">
              <div class="liveBox">
                <img
                  style="width: 100%; height: 100%"
                  v-if="
                    video_Url_car &&
                    centerDetailInfoCar.photoUrl.indexOf(video_Url_car) != -1
                  "
                  :src="video_Url_car"
                  alt=""
                />
                <live-player
                  v-else
                  :video-url="video_Url_car"
                  live="true"
                  autoplay="true"
                  fluent
                  stretch
                  id="play001"
                  aspect="fullscreen"
                ></live-player>
              </div>
              <div
                class="allImageList"
                v-if="
                  centerDetailInfoCar.showImages &&
                  centerDetailInfoCar.showImages.length > 0
                "
              >
                <SwiperImage
                  type="视频图片"
                  perview="6.8"
                  @setVideoUrl="
                    (url) => {
                      video_Url_car = url;
                    }
                  "
                  :images="centerDetailInfoCar.showImages"
                />
              </div>
            </div>

            <div v-if="warnInfoCar" class="warningRight column height100">
              <div class="carNumBox column">
                <div class="divFlex alignCenter">
                  <div :class="'carNum alignCenter ' + warnInfoCar.车牌颜色">
                    <div class="carNumBefore divFlex alignCenter">
                      <span>{{ warnInfoCar.车牌 }}</span>
                      <i
                        style="font-size: 20px; margin-left: 10px"
                        class="iconfont"
                        v-html="warnInfoCar.图标"
                      ></i>
                    </div>
                  </div>
                  <span class="phone">{{ warnInfoCar.手机号 }}</span>
                </div>

                <div class="div20"></div>
                <!-- <WarnMapBtns
                  @chooseThis="chooseThis"
                  :btn="hourIdx"
                  :arr="globalData.chooseBtns.小时筛选"
                /> -->
                <div class="divFlex alignEnd">
                  <div class="enter_out divFlex alignEnd">
                    <span>经</span>
                    <span style="color: rgb(255, 205, 6)">{{
                      warnInfoCar.途经数 || 0
                    }}</span>
                  </div>
                  <div class="enter_out divFlex alignEnd">
                    <span>进</span>
                    <span>{{ warnInfoCar.进入数 }}</span>
                  </div>
                  <div class="enter_out divFlex alignEnd">
                    <span>出</span>
                    <span style="color: rgb(0, 191, 127)">{{
                      warnInfoCar.离开数
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="div30"></div>
              <div class="recordText">通行记录</div>
              <div class="div10"></div>

              <el-scrollbar class="carList grow">
                <el-timeline>
                  <template
                    v-for="(detail, i) in detailListCar"
                    :key="i + 'hgfjghfhhgjk'"
                  >
                    <el-timeline-item
                      v-if="detail.id != 0"
                      :class="i == trafficRecordIndex ? 'backColor' : ''"
                      @click="chooseCarRecord(detail, i)"
                    >
                      <div class="listItem column">
                        <div class="divFlex">
                          <div class="divFlex alignCenter">
                            <div :class="'timeLine' + '  ' + detail.direction">
                              {{ detail.direction }}
                            </div>
                            <div
                              :style="`color:${
                                detail.direction == '进入'
                                  ? 'rgba(255, 138, 74, 1)'
                                  : 'rgba(2, 152, 251, 1)'
                              }`"
                              class="statusText"
                            >
                              <span>{{ detail.azimuth }}</span>
                            </div>
                          </div>
                          <span class="carTime">{{ detail.createTime }}</span>
                        </div>
                        <div class="divFlex relative" style="margin-top: 6px">
                          <img class="carImg" :src="detail.cover" alt="" />
                          <div class="column flexWrap">
                            <div class="eventDesc">
                              <span>时间：</span>
                              <span>{{ detail.createTime }}</span>
                            </div>
                            <div class="eventDesc">
                              <span>站点：</span>
                              <span>{{ detail.monitorName }}</span>
                            </div>
                            <div class="eventDesc">
                              <span>状态：</span>
                              <span :class="detail.status">{{
                                detail.status
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-timeline-item>
                  </template>
                </el-timeline>
              </el-scrollbar>
            </div>
          </div>
        </transition>
        <!-- 重点人员 -->
        <transition name="el-zoom-in-top">
          <div
            class="box_camera_content_camera bet"
            v-if="
              chooseBtn == '预警事件' && detailType.indexOf('重点人员') != -1
            "
          >
            <transition name="el-fade-in-left">
              <div class="warningList column height100" v-if="leftListshow">
                <DetectionTilte
                  style="margin-left: -20px"
                  title="重点人员预警列表"
                  englishText="WARNING LIST"
                />
                <!-- <div class="div15"></div>
                <div class="bet">
                  <el-select
                    v-model="warnTypeValue"
                    placeholder="预警类型"
                    clearable
                    ref="selectUpResIdWarn"
                    @clear="selectUpResIdWarnClear"
                    size="small"
                  >
                    <el-option
                      v-for="item in types"
                      :key="item.id"
                      :value="item.id"
                      :label="item.name"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                    clearable
                    @clear="clearPoint"
                    ref="selectPoint"
                    v-model="pointValue"
                    placeholder="上报站点"
                    size="small"
                  >
                    <el-option
                      v-for="item in globalData.chooseBtns.工程车上报站点"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </div> -->
                <div class="div20"></div>
                <el-scrollbar class="grow">
                  <el-timeline
                    v-infinite-scroll="getPeopleList"
                    :infinite-scroll-immediate="false"
                  >
                    <el-timeline-item
                      @click="getPeopleDetail(warn)"
                      :class="peopleId == warn.id ? 'backColor' : ''"
                      v-for="(warn, i) in peopleList"
                      :key="i"
                    >
                      <div class="listItem column">
                        <div class="divFlex bet">
                          <div class="divFlex alignCenter">
                            <div class="timeLine">
                              {{ warn.typeName }}
                            </div>
                            <div class="statusText">
                              {{ warn.createTime }}
                            </div>
                          </div>
                        </div>

                        <div class="div10"></div>
                        <div class="divFlex flexWrap">
                          <div class="eventDesc">
                            <span>性别：</span>
                            <span>{{ warn.personGender || "--" }}</span>
                          </div>
                          <div class="eventDesc">
                            <span>年龄段：</span>
                            <span>{{ warn.personAge || "--" }}</span>
                          </div>
                          <div class="eventDesc">
                            <span>是否戴眼镜：</span>
                            <span>{{ warn.personGlass || "--" }}</span>
                          </div>
                          <div class="eventDesc">
                            <span>站点：</span>
                            <span>{{ warn.monitorName || "--" }}</span>
                          </div>
                        </div>
                        <div class="div10"></div>
                        <div
                          style="width: 100%; height: 80px"
                          v-if="warn.snapUrl"
                        >
                          <SwiperImage
                            type="视频图片"
                            perview="1.3"
                            :blow="true"
                            :images="warn.snapUrl.split(',')"
                          />
                        </div>
                      </div>
                    </el-timeline-item>
                  </el-timeline>
                </el-scrollbar>
              </div>
              <div v-else class="gapDiv"></div>
            </transition>

            <div
              class="divFlex alignCenter leftArrow"
              :style="!leftListshow ? 'left:32px;' : ''"
              @click="
                () => {
                  leftListshow = !leftListshow;
                }
              "
            >
              <span class="openLeft" v-if="!leftListshow">展开列表</span>
              <img
                :style="!leftListshow ? 'rotate:180deg;' : ''"
                src="@/assets/detection/ee.png"
                alt=""
              />
            </div>

            <div class="warningCenter column flexBet grow">
              <div class="liveBox childVideoBox">
                <img
                  style="width: 100%; height: 100%"
                  v-if="peopleDetailCenterInfo.photoUrl"
                  :src="peopleDetailCenterInfo.photoUrl.split(',')[0]"
                  alt=""
                />
              </div>
              <div class="allImageList" v-if="peopleDetailCenterInfo.photoUrl">
                <SwiperImage
                  type="视频图片"
                  perview="6.8"
                  :images="peopleDetailCenterInfo.photoUrl.split(',')"
                />
              </div>
            </div>

            <div v-if="nowPeopleInfo" class="warningRight column height100">
              <div
                style="width: 100%; height: 160px"
                v-if="nowPeopleInfo.photoUrl"
              >
                <SwiperImage
                  type="视频图片"
                  perview="1"
                  :blow="true"
                  :images="nowPeopleInfo.photoUrl.split(',')"
                />
              </div>
              <div class="div10"></div>

              <div class="topInfo">
                <div class="divFlex alignCenter">
                  <div class="timeLine">
                    {{ nowPeopleInfo.typeName || "--" }}
                  </div>
                  <div class="statusText">
                    {{ nowPeopleInfo.createTime || "--" }}
                  </div>
                </div>
                <div class="div10"></div>
                <div class="divFlex gap10 flexWrap">
                  <div class="eventDesc">
                    <span>性别：</span>
                    <span>{{ nowPeopleInfo.personGender || "--" }}</span>
                  </div>
                  <div class="eventDesc">
                    <span>年龄段：</span>
                    <span>{{ nowPeopleInfo.personAge || "--" }}</span>
                  </div>
                  <div class="eventDesc">
                    <span>是否戴眼镜：</span>
                    <span>{{ nowPeopleInfo.personGlass || "--" }}</span>
                  </div>
                  <div class="eventDesc">
                    <span>站点：</span>
                    <span>{{ nowPeopleInfo.monitorName || "--" }}</span>
                  </div>
                </div>
              </div>

              <div class="div10"></div>
              <div class="recordText">识别关联记录</div>
              <div class="div30"></div>
              <el-scrollbar class="grow">
                <el-timeline>
                  <el-timeline-item
                    @click="
                      (detail) => {
                        peopleDetailCenterInfo = val;
                      }
                    "
                    :class="
                      peopleDetailId == peopleDetailCenterInfo.id
                        ? 'backColor'
                        : ''
                    "
                    v-for="(detail, i) in peopleDetailLog"
                    :key="i + 'hgfffjhgjk'"
                  >
                    <div class="listItem column">
                      <div class="divFlex alignCenter">
                        <div class="timeLine">
                          {{ detail.typeName || "--" }}
                        </div>
                        <div class="statusText">
                          {{ detail.createTime || "--" }}
                        </div>
                      </div>
                      <div class="div10"></div>

                      <div class="divFlex flexWrap">
                        <div class="eventDesc">
                          <span>性别：</span>
                          <span>{{ detail.personGender || "--" }}</span>
                        </div>
                        <div class="eventDesc">
                          <span>年龄段：</span>
                          <span>{{ detail.personAge || "--" }}</span>
                        </div>
                        <div class="eventDesc">
                          <span>是否戴眼镜：</span>
                          <span>{{ detail.personGlass || "--" }}</span>
                        </div>
                        <div class="eventDesc">
                          <span>站点：</span>
                          <span>{{ detail.monitorName || "--" }}</span>
                        </div>
                      </div>
                      <div class="div10"></div>
                      <div
                        style="width: 100%; height: 120px"
                        v-if="detail.photoUrl"
                      >
                        <SwiperImage
                          type="视频图片"
                          perview="1.3"
                          :blow="true"
                          :images="detail.photoUrl.split(',')"
                        />
                      </div>
                    </div>
                  </el-timeline-item>
                </el-timeline>
              </el-scrollbar>
            </div>
          </div>
        </transition>
        <!-- AI识别 -->
        <transition name="el-zoom-in-top">
          <div
            class="box_camera_content_camera bet"
            v-if="chooseBtn == '预警事件' && detailType.indexOf('AI识别') != -1"
          >
            <transition name="el-fade-in-left">
              <div class="warningList column height100" v-if="leftListshow">
                <DetectionTilte
                  style="margin-left: -20px"
                  title="识别列表"
                  englishText="WARNING LIST"
                />

                <div class="div20"></div>
                <el-scrollbar class="grow">
                  <el-timeline
                    v-infinite-scroll="getAIList"
                    :infinite-scroll-immediate="false"
                  >
                    <el-timeline-item
                      @click="getAIDetail(warn)"
                      :class="AIId == warn.id ? 'backColor' : ''"
                      v-for="(warn, i) in AIList"
                      :key="i"
                    >
                      <div class="listItem column">
                        <div class="divFlex bet">
                          <div class="divFlex alignCenter">
                            <div :class="`timeLine  c${warn?.rcType}`">
                              {{ warn.typeName }}
                            </div>
                            <div class="statusText">
                              {{ warn.createTime }}
                            </div>
                          </div>
                        </div>

                        <div class="div10"></div>
                        <div class="divFlex flexWrap" v-if="warn?.rcType == 1">
                          <div class="eventDesc">
                            <span>性别：</span>
                            <span>{{ warn.personGender || "--" }}</span>
                          </div>
                          <div class="eventDesc">
                            <span>年龄段：</span>
                            <span>{{ warn.personAge || "--" }}</span>
                          </div>
                          <div class="eventDesc">
                            <span>是否戴眼镜：</span>
                            <span>{{ warn.personGlass || "--" }}</span>
                          </div>
                          <div class="eventDesc">
                            <span>站点：</span>
                            <span>{{ warn.monitorName || "--" }}</span>
                          </div>
                        </div>
                        <div
                          class="divFlex gap10 flexWrap"
                          v-if="warn?.rcType == 2"
                        >
                          <div class="eventDesc">
                            <span>车牌：</span>
                            <span>{{ warn.licensePlate || "--" }}</span>
                          </div>
                          <div class="eventDesc">
                            <span>品牌：</span>
                            <span>{{ warn.brand || "--" }}</span>
                          </div>
                          <div class="eventDesc">
                            <span>站点：</span>
                            <span>{{ warn.monitorName || "--" }}</span>
                          </div>
                        </div>
                        <div class="div10"></div>
                        <div
                          style="width: 100%; height: 80px"
                          v-if="warn.photoUrl && warn?.rcType == 2"
                        >
                          <SwiperImage
                            type="视频图片"
                            perview="1.3"
                            :blow="true"
                            :images="warn.photoUrl.split(',')"
                          />
                        </div>
                        <div
                          style="width: 100%; height: 80px"
                          v-if="warn.snapUrl && warn?.rcType == 1"
                        >
                          <SwiperImage
                            type="视频图片"
                            perview="1.3"
                            :blow="true"
                            :images="warn.snapUrl.split(',')"
                          />
                        </div>
                      </div>
                    </el-timeline-item>
                  </el-timeline>
                </el-scrollbar>
              </div>
              <div v-else class="gapDiv"></div>
            </transition>

            <div
              class="divFlex alignCenter leftArrow"
              :style="!leftListshow ? 'left:32px;' : ''"
              @click="
                () => {
                  leftListshow = !leftListshow;
                }
              "
            >
              <span class="openLeft" v-if="!leftListshow">展开列表</span>
              <img
                :style="!leftListshow ? 'rotate:180deg;' : ''"
                src="@/assets/detection/ee.png"
                alt=""
              />
            </div>

            <div
              class="warningCenter column flexBet grow"
              v-if="AIDetailCenterInfo.rcType == 1"
            >
              <div class="liveBox childVideoBox">
                <img
                  style="width: 100%; height: 100%"
                  v-if="AIDetailCenterInfo.photoUrl"
                  :src="AIDetailCenterInfo.photoUrl.split(',')[0]"
                  alt=""
                />
              </div>
              <div class="allImageList" v-if="AIDetailCenterInfo.photoUrl">
                <SwiperImage
                  type="视频图片"
                  perview="6.8"
                  :images="AIDetailCenterInfo.photoUrl.split(',')"
                />
              </div>
            </div>

            <div
              class="warningCenter column flexBet grow"
              v-if="AIDetailCenterInfo.rcType == 2"
            >
              <div class="liveBox childVideoBox">
                <img
                  style="width: 100%; height: 100%"
                  v-if="AIDetailCenterInfo.carPicUri"
                  :src="AIDetailCenterInfo.carPicUri.split(',')[0]"
                  alt=""
                />
              </div>
              <div class="allImageList" v-if="AIDetailCenterInfo.carPicUri">
                <SwiperImage
                  type="视频图片"
                  perview="6.8"
                  :images="AIDetailCenterInfo.carPicUri.split(',')"
                />
              </div>
            </div>

            <div v-if="nowAIInfo" class="warningRight column height100">
              <div
                style="width: 100%; height: 160px"
                v-if="nowAIInfo.snapUrl && nowAIInfo.rcType == 1"
              >
                <SwiperImage
                  type="视频图片"
                  perview="1"
                  :blow="true"
                  :images="nowAIInfo.snapUrl.split(',')"
                />
              </div>
              <div
                style="width: 100%; height: 160px"
                v-if="nowAIInfo.photoUrl && nowAIInfo.rcType == 2"
              >
                <SwiperImage
                  type="视频图片"
                  perview="1"
                  :blow="true"
                  :images="nowAIInfo.photoUrl.split(',')"
                />
              </div>
              <div class="div10"></div>

              <div class="topInfo" v-if="nowAIInfo && nowAIInfo.rcType == 2">
                <div class="divFlex alignCenter">
                  <div :class="`timeLine c${nowAIInfo.rcType}`">
                    {{ nowAIInfo.typeName || "--" }}
                  </div>
                  <div class="statusText">
                    {{ nowAIInfo.createTime || "--" }}
                  </div>
                </div>
                <div class="div10"></div>
                <div class="divFlex gap10 flexWrap">
                  <div class="eventDesc">
                    <span>车牌：</span>
                    <span>{{ nowAIInfo.licensePlate || "--" }}</span>
                  </div>
                  <div class="eventDesc">
                    <span>品牌：</span>
                    <span>{{ nowAIInfo.brand || "--" }}</span>
                  </div>
                  <div class="eventDesc">
                    <span>站点：</span>
                    <span>{{ nowAIInfo.monitorName || "--" }}</span>
                  </div>
                </div>
              </div>
              <div class="topInfo" v-if="nowAIInfo && nowAIInfo.rcType == 1">
                <div class="divFlex alignCenter">
                  <div :class="`timeLine c${nowAIInfo.rcType}`">
                    {{ nowAIInfo.typeName || "--" }}
                  </div>
                  <div class="statusText">
                    {{ nowAIInfo.createTime || "--" }}
                  </div>
                </div>
                <div class="div10"></div>
                <div class="divFlex gap10 flexWrap">
                  <div class="eventDesc">
                    <span>性别：</span>
                    <span>{{ nowAIInfo.personGender || "--" }}</span>
                  </div>
                  <div class="eventDesc">
                    <span>年龄段：</span>
                    <span>{{ nowAIInfo.personAge || "--" }}</span>
                  </div>
                  <div class="eventDesc">
                    <span>是否戴眼镜：</span>
                    <span>{{ nowAIInfo.personGlass || "--" }}</span>
                  </div>
                  <div class="eventDesc">
                    <span>站点：</span>
                    <span>{{ nowAIInfo.monitorName || "--" }}</span>
                  </div>
                </div>
              </div>

              <div class="div10"></div>
              <div class="recordText">识别关联记录</div>
              <div class="div30"></div>
              <el-scrollbar class="grow">
                <el-timeline
                  v-infinite-scroll="getAIDetailList"
                  :infinite-scroll-immediate="false"
                >
                  <el-timeline-item
                    @click="
                      (detail) => {
                        AIDetailCenterInfo = val;
                      }
                    "
                    :class="AIDetailId == detail.id ? 'backColor' : ''"
                    v-for="(detail, i) in AIDetailLog"
                    :key="i + 'hgfffjhgjk'"
                  >
                    <div class="listItem column" v-if="detail.rcType == 1">
                      <div class="divFlex alignCenter">
                        <div :class="`timeLine c${detail.rcType}`">
                          {{ detail.typeName || "--" }}
                        </div>
                        <div class="statusText">
                          {{ detail.createTime || "--" }}
                        </div>
                      </div>
                      <div class="div10"></div>

                      <div class="divFlex flexWrap">
                        <div class="eventDesc">
                          <span>性别：</span>
                          <span>{{ detail.personGender || "--" }}</span>
                        </div>
                        <div class="eventDesc">
                          <span>年龄段：</span>
                          <span>{{ detail.personAge || "--" }}</span>
                        </div>
                        <div class="eventDesc">
                          <span>是否戴眼镜：</span>
                          <span>{{ detail.personGlass || "--" }}</span>
                        </div>
                        <div class="eventDesc">
                          <span>站点：</span>
                          <span>{{ detail.monitorName || "--" }}</span>
                        </div>
                      </div>
                      <div class="div10"></div>
                      <div
                        style="width: 100%; height: 120px"
                        v-if="detail.photoUrl"
                      >
                        <SwiperImage
                          type="视频图片"
                          perview="1.3"
                          :blow="true"
                          :images="detail.photoUrl.split(',')"
                        />
                      </div>
                    </div>
                    <div class="listItem column" v-else-if="detail.rcType == 2">
                      <div class="divFlex alignCenter">
                        <div :class="`timeLine c${detail.rcType}`">
                          {{ detail.typeName || "--" }}
                        </div>
                        <div class="statusText">
                          {{ detail.createTime || "--" }}
                        </div>
                      </div>
                      <div class="div10"></div>

                      <div class="divFlex flexWrap">
                        <div class="eventDesc">
                          <span>车牌：</span>
                          <span>{{ detail.licensePlate || "--" }}</span>
                        </div>
                        <div class="eventDesc">
                          <span>品牌：</span>
                          <span>{{ detail.brand || "--" }}</span>
                        </div>
                        <div class="eventDesc">
                          <span>站点：</span>
                          <span>{{ detail.monitorName || "--" }}</span>
                        </div>
                      </div>
                      <div class="div10"></div>
                      <div
                        style="width: 100%; height: 180px"
                        v-if="detail.photoUrl && detail?.rcType == 2"
                      >
                        <SwiperImage
                          type="视频图片"
                          perview="1.3"
                          :blow="true"
                          :images="detail.photoUrl.split(',')"
                        />
                      </div>
                      <div
                        style="width: 100%; height: 80px"
                        v-if="detail.snapUrl && detail?.rcType == 1"
                      >
                        <SwiperImage
                          type="视频图片"
                          perview="1.3"
                          :blow="true"
                          :images="detail.snapUrl.split(',')"
                        />
                      </div>
                    </div>
                  </el-timeline-item>
                </el-timeline>
              </el-scrollbar>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import EZUIKit from "ezuikit-js";
import http from "@/api/server";
import { ref, reactive, onMounted } from "vue";
import { getSimpleListOther } from "@/api";
import { Viewcamera } from "@/components";
import Playback from "./Playback.vue";
import DetectionPresuppositionView from "./DetectionPresuppositionView.vue";
import SwiperImage from "./SwiperImage.vue";
import DetectionTilte from "./DetectionTilte.vue";
import Hk from "./Hk.vue";

var player = null;
export default {
  components: {
    Playback,
    DetectionPresuppositionView,
    SwiperImage,
    DetectionTilte,
    Viewcamera,
    Hk,
  },
  name: "YsyDetection",
  inject: ["globalData"],
  props: ["camera", "detailType", "aboutEvent", "moreCameraData"],
  data() {
    return {
      // AI识别
      allMonitorArr: [],
      AICurrent: 1,
      AIList: [],
      AIId: "",
      AIDetailLog: [],
      AIDetailCenterInfo: {},
      AIDetailId: "",
      nowAIInfo: {},
      AITotal: 0,
      AIDetailCurrent: 1,
      // -----------重点人员----------------------------
      warnTypeValue: "",
      pointValue: "",
      types: "",
      peopleCurrent: 1,
      peopleList: [],
      peopleId: "",
      peopleDetailLog: [],
      peopleDetailCenterInfo: "",
      peopleDetailId: "",
      nowPeopleInfo: {},
      peopleTotal: 0,
      // -----------------------------------------
      switchVideoShow: true,
      // ---------------------
      livePlayUrlOther: [],
      // 框选相关
      start: null,
      end: null,
      selection: {
        startX: 0,
        startY: 0,
        endX: 0,
        endY: 0,
      },

      selectionStyle: false,
      dragging: false,
      boxPosition: { x: 0, y: 0 },
      boxSize: { width: 100, height: 100 },

      // -------------------
      hourIdx: false,
      livePlayUrl: "",
      // 历史记录
      recordTime: this.currentTime(),
      //----车辆通行-----
      carTypeValue: "",
      statusValueCar: "",
      carList: [],
      video_Url_car: false,
      // -----------
      recordUrl: false,
      recordValue: 1,
      show: true,
      leftListshow: true,
      carleftListshow: true,
      isHui: false,
      token: false,
      cameraType: false,
      viewArr: [],
      warnList: [],
      video_Url: false,
      // ------------云闪播-------
      shanboUrl: false,
      shanboShow: false,
      datetime: "",
      startTime: "",
      endTime: "",
      videoList: [],
      topCount: false,
      viewShow: false,
      chooseBtn: "实时直播",
      videoUrl: false,
      TypeValue: "",
      eventTypes: [],
      statusValue: "",
      warnId: false,
      warnInfo: false,

      warnIdCar: false,
      warnInfoCar: false,
      menuConfigArr: [],
      eventStatus: [
        {
          value: "3",
          label: "待处理",
        },
        {
          value: "6",
          label: "已转报",
        },
        {
          value: "7",
          label: "处理中",
        },
        {
          value: "8",
          label: "已办结",
        },
      ],
      eventDirection: [],
      directionValue: "",
      detailList: [],
      centerDetailInfo: [],

      detailListCar: [],
      centerDetailInfoCar: [],

      cameraRecordList: [],
      imagesRecordList: [],
      current: 1,
      carCurrent: 1,

      recordVideoTime: false,
      RecordVideoShow: false,
      bool: false,
      boxStyle: {},

      // ----------------框选---
      isDrawing: false,
      startX: 0,
      startY: 0,
      endX: 0,
      endY: 0,
      divStyle: {
        position: "fixed",
        backgroundColor: "red",
        border: "1px solid black",
      },
    };
  },
  computed: {
    mode: function () {
      return this.tabActive === "mse" ? 0 : 1;
    },
  },
  watch: {
    aboutEvent: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.getDetail(newVal);
        }
      },
      deep: true,
      immediate: true,
    },

    detailType: {
      handler(newVal, oldVal) {
        this.chooseBtn = newVal.indexOf("预警事件") != -1 ? "预警事件" : newVal;
      },
      deep: true,
      immediate: true,
    },
    chooseBtn: {
      handler(newVal, oldVal) {
        this.closeRecordVideo();
      },
      deep: true,
    },
    datetime(val) {
      if (!val) {
        this.startTime = "";
        this.endTime = "";
      } else {
        this.startTime = val[0];
        this.endTime = val[1];
      }

      this.videoList = [];
      this.getList();
    },

    TypeValue() {
      // console.log(2111231231);
      this.getWarnList();
    },
    statusValue() {
      this.getWarnList();
    },
    directionValue() {
      this.getWarnList();
    },

    carTypeValue() {
      this.carCurrent = 1;
      this.carList = [];
      this.getPage(true);
    },
    statusValueCar() {
      this.carCurrent = 1;
      this.carList = [];
      this.getPage(true);
    },
    // -------------------重点人员---------------
    pointValue() {
      this.peopleList = [];
      this.peopleCurrent = 1;
      this.getPeopleList();
    },
    warnTypeValue() {
      this.peopleList = [];
      this.peopleCurrent = 1;
      this.getPeopleList();
    },
  },
  async mounted() {
    this.hourIdx = this.globalData.chooseBtns.小时筛选[0].value;
    // http.getRCDict({ isBlack: 2 }).then((res) => {
    //   res.data.unshift({ name: "全部类型", id: "" });
    //   this.types = res.data;
    //   this.warnTypeValue = res.data[0]?.id;
    // });

    // this.pointValue = this.globalData.chooseBtns.工程车上报站点[0]?.value;
    // AI识别-------------
    await getSimpleListOther().then((res) => {
      this.allMonitorArr = res.data;
    });
    await http
      .getMonitorConfig({ id: this.camera.monitorId || this.camera.id })
      .then((res) => {
        // console.log(res)
        this.menuConfigArr = res.data;
      });

    this.getMoreUrl();

    //  获取播放记录
    http
      .eventFilterProject({
        id: this.camera.monitorId || this.camera.id,
        type: 1,
      })
      .then((res) => {
        // console.log(res.data)
        this.cameraRecordList = res.data;
      });
    //  获取播放图片
    http
      .eventFilterProject({
        id: this.camera.monitorId || this.camera.id,
        type: 2,
      })
      .then((res) => {
        this.imagesRecordList = res.data;
      });

    http
      .presetList({ id: this.camera.monitorId || this.camera.id })
      .then((res) => {
        this.viewArr = res.data;
      });

    // ---------分割线----------------------
    // 预警事件
    this.getList();
    http.getEventTypeOther().then((res) => {
      this.eventTypes = res.data;
    });
    http.getByCode({ code: "monitor_azimuth" }).then((res) => {
      this.eventDirection = res.data;
    });
    if (this.menuConfigArr.includes("预警事件")) {
      if (this.detailType.indexOf("重点人员") != -1) {
        this.getPeopleList();
      } else if (this.detailType.indexOf("AI识别") != -1) {
        this.getAIList();
      } else {
        this.getWarnList();
      }
    }
    if (this.menuConfigArr.includes("车辆通行")) {
      this.getPage();
    }
  },
  setup(props, ctx) {
    let state = reactive({});
    const clearClick = (data) => {
      ctx.emit("clearClick", data);
    };
    const mouseoutFun = (item) => {
      state["videContainerss" + item.id] = false;
    };
    const mouseenterFun = (item) => {
      state["videContainerss" + item.id] = true;
    };
    const hideItem = (item) => {
      state["videContainerssShow" + item.id] = false;
    };
    const showItem = (item) => {
      state["videContainerssShow" + item.id] = true;
    };

    onMounted(() => {
      if (props.moreCameraData && props.moreCameraData.length > 0) {
        props.moreCameraData.forEach((e) => {
          state["videContainerssShow" + e.id] = true;
        });
      }
    });

    return {
      state,
      clearClick,
      mouseoutFun,
      mouseenterFun,
      hideItem,
      showItem,
    };
  },
  methods: {
    // ----------重点人员-----------------------------
    clearPoint() {
      this.pointValue = this.globalData.chooseBtns.工程车上报站点[0].value;
      this.$refs.selectPoint.blur();
    },
    selectUpResIdWarnClear() {
      this.warnTypeValue = this.types[0].id;
      this.$refs.selectUpResIdWarn.blur();
    },
    getPeopleList() {
      let obj = {
        monitorId: this.camera.monitorId,
        current: this.peopleCurrent++,
        size: 20,
        isBlack: 2,
      };
      http.getRCsbList(obj).then((res) => {
        let { records, total } = res.data;
        this.peopleList = [...this.peopleList, ...records];
        this.peopleTotal = total;
        if (obj.current == 1) {
          this.getPeopleDetail(records[0]);
        }
      });
    },
    getPeopleDetail(val) {
      if (this.peopleId == val.id || !val) return;
      this.peopleDetailLog = [];
      this.peopleId = val.id;
      let obj = {
        monitorId: this.camera.monitorId,
        current: 1,
        size: -1,
        isBlack: 2,
        personId: val.personId,
      };
      http.getRCsbList(obj).then((res) => {
        let { records } = res.data;
        this.peopleDetailLog = [...this.peopleDetailLog, ...records];
        this.peopleDetailCenterInfo = records[0];
        this.peopleDetailId = records[0].id;
      });
      http
        .getRCsbDetail({
          id: val.id,
        })
        .then(async (res) => {
          this.nowPeopleInfo = res.data;
        });
    },

    // ----------AI识别----------------------------------
    getAIList() {
      let obj = {
        sbType: "",
        deviceNumber: this.camera.deviceNumber,
        current: this.AICurrent++,
        size: 20,
        locationType: "location_wharf",
        url: this.globalData.IP,
      };

      http.getRCsbListOther(obj).then((res) => {
        let { records, total } = res.data;
        this.AITotal = total;
        if (obj.current == 1) {
          this.getAIDetail(records[0]);
        }
        records.map((e) => {
          let obj = this.globalData.vehicleTypes.find((el) => {
            return e.typeName == el.id;
          });
          if (e.rcType == 2) {
            obj ? (e.typeName = obj.name) : (e.typeName = "未知");
          }

          let obj1 = this.globalData.plateTypes.find((el) => {
            return e.plateType == el.id;
          });
          if (e.rcType == 2) {
            obj1 ? (e.plateType = obj1.name) : (e.plateType = "未知");
          }

          let obj2 = this.allMonitorArr.find((el) => {
            return e.deviceNumber == el.deviceNumber;
          });
          if (obj2) {
            e.monitorId = obj2.id;
            e.channelNumber = obj2.channelNumber;
            e.position = obj2.position;
          }
        });

        this.AIList = [...this.AIList, ...records];
        console.log(
          this.AIList,
          "this.AIListthis.AIListthis.AIListthis.AIList"
        );
      });
    },

    getAIDetailList() {
      let obj = {
        sbType: this.nowAIInfo.sbType,
        deviceNumber: this.camera.deviceNumber,
        current: this.AIDetailCurrent++,
        size: 20,
        locationType: this.detailType.indexOf("location_wharf")
          ? "location_wharf"
          : '"location_jd"',
        url: this.globalData.IP,
      };
      this.nowAIInfo.rcType == 2
        ? (obj.licensePlate = this.nowAIInfo.licensePlate)
        : (obj.personId = this.nowAIInfo.personId);
      http.getRCsbListOther(obj).then((res) => {
        let { records } = res.data;
        this.AIDetailLog = [...this.AIDetailLog, ...records];
        if (obj.current == 1) {
          this.AIDetailCenterInfo = records[0];
          this.AIDetailId = records[0].id;
        }
      });
    },
    getAIDetail(val) {
      if (this.AIId == val.id || !val) return;
      this.AIDetailLog = [];
      this.AIDetailCurrent = 1;
      this.nowAIInfo = val;
      this.AIId = val.id;
      this.getAIDetailList();
    },

    // ---------------------
    // 框选相关函数
    startSelection(event) {
      // console.log(1111)

      // this.start = { x: event.clientX, y: event.clientY };

      this.isDrawing = true;
      this.startX = event.clientX;
      this.startY = event.clientY;
    },
    endSelection() {
      // console.log(2111111)
      // this.start = null;
      this.isDrawing = false;
    },
    updateSelection(event) {
      if (this.isDrawing) {
        this.endX = event.clientX;
        this.endY = event.clientY;
        this.divStyle.width = Math.abs(this.endX - this.startX) + "px";
        this.divStyle.height = Math.abs(this.endY - this.startY) + "px";
        this.divStyle.left = Math.min(this.startX, this.endX) + "px";
        this.divStyle.top = Math.min(this.startY, this.endY) + "px";
      }

      return;
    },
    calculateSelection(start, end) {
      let wid = $(".box_camera_content").eq(0).width();
      let hgt = $(".box_camera_content").eq(0).height();
      let startX = Math.ceil(((start.x - 40) / wid) * 255);
      let startY = Math.ceil(((start.y - 160) / hgt) * 255);
      let endX = Math.ceil(((end.x - 40) / wid) * 255);
      let endY = Math.ceil(((end.y - 160) / hgt) * 255);
      return { startX, startY, endX, endY };
    },
    // --------------------------------------------
    currentTime() {
      let time = moment().format("YYYY-MM-DD HH:mm:ss");
      let time1 = moment().subtract(3, "hours").format("YYYY-MM-DD HH:mm:ss");
      return [time1, time];
    },
    callChildMethod() {
      if (this.$refs.childRef) {
        this.$refs.childRef.closeAll();
      }
      if (this.$refs.childVideo) {
        this.$refs.childVideo.closeAll();
      }
    },
    chooseCarRecord(detail, i) {
      this.trafficRecordIndex = i;
      this.centerDetailInfoCar = detail;
      if (detail.videoUrl) {
        this.video_Url_car = detail.videoUrl.split(",")[0];
      } else {
        this.video_Url_car = detail.photoUrl.split(",")[0];
      }
    },
    getCarDetailFun(item) {
      if (!item) return;
      this.warnIdCar = item.licensePlate;

      http
        .getCarDetail({
          name: item.licensePlate,
          monitorId: this.camera.monitorId,
          // time: this.hourIdx,
        })
        .then((res) => {
          this.warnInfoCar = res.data;
          item.isRead = 1;
          let list = res.data.通行记录;
          this.trafficRecordIndex = 0;
          if (list && list.length > 0) {
            list.forEach((e, idx) => {
              let videoArr = [],
                photoArr = [];

              if (idx == 0) {
                if (e.videoUrl) {
                  this.video_Url_car = e.videoUrl.split(",")[0];
                } else {
                  this.video_Url_car = e.photoUrl.split(",")[0];
                }
              }

              if (e.videoUrl) {
                videoArr = e.videoUrl.split(",");
                videoArr.forEach((el, idx) => {
                  videoArr[idx] = el + "?vframe/jpg/offset/1";
                });
              } else if (e.photoUrl) {
                let arr = e.photoUrl.split(",");
              }

              if (e.photoUrl) {
                photoArr = e.photoUrl.split(",");
              }
              e.showImages = [...videoArr, ...photoArr];
            });
            this.detailListCar = list;
            this.centerDetailInfoCar = list[this.trafficRecordIndex];
          }
        });
    },
    getPage(bool) {
      let obj = {
        current: this.carCurrent++,
        size: 20,
        typeId: this.carTypeValue,
        status: this.statusValueCar,
        monitorId: this.camera.monitorId,
      };
      http.getCarPage(obj).then((res) => {
        let { records } = res.data;
        this.carList = [...this.carList, ...records];

        if (obj.current == 1) {
          let licensePlate = this.camera.parent.licensePlate;
          let item = this.carList.find((e) => {
            return e.licensePlate == licensePlate;
          });
          // console.log(item)
          this.getCarDetailFun(bool === true || !item ? this.carList[0] : item);
        }
      });
    },
    switchPage() {
      this.current++;
    },
    handleDownload(link) {
      let x = new XMLHttpRequest();
      x.open("GET", link, true);
      x.responseType = "blob";
      x.onload = (e) => {
        let url = window.URL.createObjectURL(x.response);
        let a = document.createElement("a");
        a.href = url;
        a.download = link;
        a.click();
      };
      x.send();
    },
    downFile(file) {
      this.handleDownload(file);
    },
    chooseThis(val) {
      this.hourIdx = val;
    },
    chooseThisStep(val) {
      if (this.centerDetailInfo.id == val.id) return;
      this.centerDetailInfo = val;
      this.video_Url = false;
      let str = val.showImages[0];
      if (str && str.indexOf("offset") != -1) {
        this.video_Url = str.split("?vframe/jpg/offset/1")[0];
      }
      // console.log(this.video_Url);
    },
    calendarChange(val) {
      console.log(val);
    },
    showRecordVideo() {
      this.RecordVideoShow = true;
    },
    closeRecordVideo() {
      this.callChildMethod();
      this.RecordVideoShow = false;
    },
    getDetail(item) {
      if (item?.id == this.warnId) return;
      this.warnId = item.id;
      this.warnInfo = item;
      this.recordVideoTime = [
        this.subtractSeconds(item.createTime2, -5),
        this.subtractSeconds(item.createTime2, 5),
      ];

      // console.log(this.recordVideoTime);

      item.isRead = 1;
      http.getEventDetail({ id: item.id }).then((res) => {
        if (res.data && res.data.length > 0) {
          res.data.forEach((e, idx) => {
            let videoArr = [],
              photoArr = [];
            // if (idx == 0 && e.videoUrl) {
            //   this.video_Url = e.videoUrl.split(",")[0];
            // }

            if (idx == 0) {
              if (e.videoUrl) {
                this.video_Url = e.videoUrl.split(",")[0];
              } else {
                this.video_Url = e.photoUrl.split(",")[0];
              }
            }

            if (e.videoUrl) {
              videoArr = e.videoUrl.split(",");
              videoArr.forEach((el, idx) => {
                videoArr[idx] = el + "?vframe/jpg/offset/1";
              });
            } else if (e.photoUrl) {
              let arr = e.photoUrl.split(",");
              // this.video_Url = arr[0];
            }

            if (e.photoUrl) {
              photoArr = e.photoUrl.split(",");
            }
            e.showImages = [...videoArr, ...photoArr];
          });
          this.detailList = res.data;
          this.centerDetailInfo = res.data[0];
        }

        // console.log(res.data[0])
      });
    },
    // 预警列表
    getWarnList() {
      http
        .getMonitorEvent({
          id: this.camera.monitorId || this.camera.id,
          // typeId: this.typeInfo?.id,
          typeId: this.TypeValue,
          status: this.statusValue,
          code: this.directionValue,
        })
        .then((res) => {
          res.data.forEach((e, idx) => {
            let videoArr = [],
              photoArr = [];
            if (idx == 0 && e.videoUrl) {
              this.video_Url = e.videoUrl.split(",")[0];
            }
            if (e.videoUrl) {
              videoArr = e.videoUrl.split(",");
              videoArr.forEach((el, idx) => {
                videoArr[idx] = el + "?vframe/jpg/offset/1";
              });
            }
            if (e.photoUrl) {
              photoArr = e.photoUrl.split(",");
              photoArr.forEach((e, idx) => {
                photoArr[idx] = e + "?imageView2/2/h/160";
              });
            }
            e.showImages = [...videoArr, ...photoArr];
          });
          this.warnList = res.data;
          if (!this.warnInfo || !this.aboutEvent) {
            // alert('执行初始化')
            if (this.warnList && this.warnList.length > 0) {
              this.getDetail(this.warnList[0]);
            }
          }
        });
    },
    handleClear2() {
      this.statusValue = "";
      this.getWarnList();
      this.$refs.selectUpResId2.blur();
    },
    handleClear3() {
      this.directionValue = "";
      this.getWarnList();
      this.$refs.selectUpResI3.blur();
    },
    handleClear() {
      this.typeInfo = "";
      this.TypeValue = "";
      this.getWarnList();
      this.$refs.selectUpResId1.blur();
    },
    handleNodeClick(data) {
      this.typeInfo = data;
      this.TypeValue = data.title;
      // 选择器执行完成后，使其失去焦点隐藏下拉框的效果
      this.getWarnList();
      this.$refs.selectUpResId1.blur();
    },

    // 车辆通行------
    handleClearCar() {
      this.carTypeValue = "";
      this.getPage(true);
      this.$refs.selectUpResId11.blur();
    },

    handleClear2Car() {
      this.statusValueCar = "";
      this.getPage(true);
      this.$refs.selectUpResIdCar.blur();
    },
    // ---------------------------
    downloadVideo(url) {
      const a = document.createElement("a");
      a.href = url;
      a.download = name;
      a.click();
      a.remove();
    },
    delVideo(item) {
      http.removeVideo({ ids: [item.id] }).then((res) => {
        console.log(res);
      });
    },
    setCollect(bool) {
      if (bool) {
        http.collect({ id: this.camera.monitorId || this.camera.id });
      } else {
        http.delCollect({ id: this.camera.monitorId || this.camera.id });
      }
      this.$emit("setCollect", bool ? 1 : 0);
    },
    seeThisVideo(val) {
      // console.log(val)
      this.videoUrl = val.filePath;
    },
    clickViewShow() {
      this.viewShow = !this.viewShow;
    },
    // 云闪播列表
    getList() {
      return; //云闪播 暂无  注释
      let projectName = JSON.parse(
        decodeURIComponent(
          this.getCookie("userInfo").replace(/%22/g, '"').replace(/%2C/g, ",")
        )
      );
      projectName = projectName.title;
      http
        .getvideos({
          project: projectName,
          serialNumber: this.camera.serialNumber || this.camera.deviceNumber,
          channel: this.camera.channelNumber,
        })
        .then((res) => {
          this.videoList = res.data;
          for (const key in res.data) {
            this.videoUrl = res.data[key][0].filePath;
            return;
          }
        });
      http
        .getVideoSize({
          projectName,
          serialNumber: this.camera.serialNumber || this.camera.deviceNumber,
          channel: this.camera.channelNumber,
        })
        .then((res) => {
          this.topCount = res.data;
        });
    },

    async closeSB() {
      this.shanboUrl = false;
      this.ec();
    },
    showShanbo() {
      this.shanboUrl = false;
      this.shanboShow = !this.shanboShow;
    },
    seeThis(val) {
      if (player) {
        console.log(player);
        player.stop().then(() => {
          // player.destroy().then(() => {
          this.shanboUrl = val.filePath;
          // });
        });
      }
    },

    getMoreUrl() {
      // this.livePlayUrlOther = [];
      // this.livePlayUrl = "";

      if (this.moreCameraData && this.moreCameraData.length > 0) {
        http
          .iscBatchPlayAddressNew({
            cameraIndexCodes: this.moreCameraData
              .map((e) => {
                return e.deviceNumber;
              })
              .join(","),
            protocol: "ws",
            streamType: 0,
            url: this.globalData.IP,
          })
          .then((res) => {
            this.livePlayUrlOther = res.data.map((e) => {
              return e.url;
            });
          });
      }
      //  获取播放地址
      http
        .iscBatchPlayAddressNew({
          // ids: [this.camera.monitorId || this.camera.id],
          cameraIndexCodes: this.camera.deviceNumber,
          protocol: "ws",
          streamType: 0,
          url: this.globalData.IP,
        })
        .then((res) => {
          this.livePlayUrl = res.data.map((e) => {
            return e.url;
          });
        });
    },

    switchVideo(val, idx) {
      this.showItem(val);
      this.showItem(this.camera);
      let obj = this.deepClone(val);
      this.switchVideoShow = false;

      let arr = this.deepClone(this.moreCameraData);
      // console.log()
      arr.splice(idx, 1, this.camera);

      this.$emit("setMoreCameraData", arr);
      this.$emit("setCamera", obj);
      setTimeout(() => {
        this.switchVideoShow = true;
        this.getMoreUrl();
      }, 300);
    },

    // ----------------------萤石云----------
    clearYSY(fun = false) {
      this.callChildMethod();
      if (player) {
        player.stop().then(() => {
          // player.destroy().then(() => {
          if (fun === false) {
            this.show = false;
            setTimeout(() => {
              this.clearClick();
            }, 200);
          } else {
            fun();
          }
          // });
        });
      } else {
        if (fun === false) {
          this.show = false;
          setTimeout(() => {
            this.clearClick();
          }, 200);
        } else {
          fun();
        }
      }
    },

    lishi() {
      this.clearYSY(() => {
        if (this.isHui) {
          const yy = new Date().getFullYear();
          const MM =
            new Date().getMonth() + 1 < 10
              ? "0" + (new Date().getMonth() + 1)
              : new Date().getMonth() + 1;
          const dd =
            new Date().getDate() < 10
              ? "0" + new Date().getDate()
              : new Date().getDate();
          const HH =
            new Date().getHours() < 10
              ? "0" + new Date().getHours()
              : new Date().getHours();
          const mm =
            new Date().getMinutes() < 10
              ? "0" + new Date().getMinutes()
              : new Date().getMinutes();
          const ss =
            new Date().getSeconds() < 10
              ? "0" + new Date().getSeconds()
              : new Date().getSeconds();
          http
            .addressGet({
              accessToken: this.token,
              deviceSerial:
                this.camera.serialNumber || this.camera.deviceNumber,
              channelNo: Number(this.camera.channelNumber),
              protocol: 1,
              quality: 1,
              startTime: yy + "-" + MM + "-" + dd + " 00:00:00",
              stopTime:
                yy + "-" + MM + "-" + dd + " " + HH + ":" + mm + ":" + ss,
              type: "2",
            })
            .then((res) => {
              // let dom = document.getElementsByClassName("box_camera_content")[0];
              // let width = dom.offsetWidth;
              // let height = dom.offsetHeight + 48;
              // console.log(dom,width,height)
              player = new EZUIKit.EZUIKitPlayer({
                id: "videContainer", // 视频容器ID
                accessToken: this.token,
                url: res.data.url,
                // simple - 极简版; pcLive-pc直播；pcRec-pc回放；mobileLive-移动端直播；mobileRec-移动端回放;security - 安防版;voice-语音版;
                template: "pcRec",
                // plugin: ['talk'], // 加载插件，talk-对讲
                width: 1798,
                height: 888,
              });
            });
        } else {
          this.ec();
        }

        this.isHui = !this.isHui;
      });
    },

    ec() {
      // setTimeout(() => {
      //   this.init();
      //   this.createPlayer();
      //   this.realplay();
      // }, 1000);

      // return;
      http
        .getVideoAddress({
          serialNumber: this.camera.serialNumber || this.camera.deviceNumber,
          channelNo: Number(this.camera.channelNumber),
          protocol: 1,
          quality: 1,
          code: this.camera?.code,
        })
        .then((rest) => {
          // if (!rest.data.data.url) return false;
          if (!rest.data?.data?.url) {
            return this.$message({
              message: rest.data.msg,
              type: "warning",
            });
          }
          // let dom = document.getElementsByClassName("box_camera_content")[0];

          // let width = dom.offsetWidth;
          // let height = dom.offsetHeight + 48;
          // console.log(dom, width, height)
          player = new EZUIKit.EZUIKitPlayer({
            id: "videContainer", // 视频容器ID
            accessToken: this.token,
            url: rest.data.data.url,
            // simple - 极简版; pcLive-pc直播；pcRec-pc回放；mobileLive-移动端直播；mobileRec-移动端回放;security - 安防版;voice-语音版;
            template: "pcLive",
            // plugin: ['talk'], // 加载插件，talk-对讲
            width: 1798,
            height: 888,
          });
        });
    },
  },
  beforeUnmount() {
    this.callChildMethod();
    this.globalData.monitorType = false;
  },
};
</script>

<style lang="scss" scoped>
.leftArrow {
  position: absolute;
  object-fit: contain;
  left: 332px;
  z-index: 3;
  cursor: pointer;
  transition: all 0.3s;
}

.icon3 {
  width: 24px;
  height: 30px;
  color: var(--oneItem);
  margin-right: 10px;
}

.allContent {
  width: 100%;
  height: calc(100vh - 100px);
  padding: 0 20px;
  box-sizing: border-box;
  position: fixed;
  top: 80px;
}

.liveVideo {
  width: 1520px;
  height: 960px;
  background: rgba(7, 28, 49, 1);
  border-radius: 20px;
  transition: all 0.3s;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
  margin-right: 10px;
  box-sizing: border-box;

  .closeBtn {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
    width: 20px;
    object-fit: contain;
  }
}

.box_camera {
  // width: 1840px;
  // height: 940px;
  width: calc(100vw - 40px);
  height: calc(100vh - 100px);
  background: rgba(2, 11, 18, 0.8);
  border: 1px solid rgba(2, 152, 251, 0.64);
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  padding: 20px;

  .topLine {
    width: 100%;
    height: 1px;
    background: #0298fb;
    margin-bottom: 18px;
    position: absolute;
    left: 0;
    top: 10px;
  }

  .cameraInfo {
    background: rgba(2, 11, 18, 1);
    z-index: 2;
    width: calc(100% - 40px);
    height: 50px;
    position: absolute;
    border-bottom: 1px solid rgba(2, 152, 251, 0.64);

    .imgNameCollect {
      // margin-right: 643px;

      img {
        object-fit: contain;
      }

      .cameraName {
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #a6ffef;
        background: linear-gradient(0deg, #a6ffef 0%, #22e2ff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        line-height: 24px;
        margin: 0 14px 0 6px;
        white-space: nowrap;
      }

      .focusPoint {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #738ea2;
        cursor: pointer;
        white-space: nowrap;
      }
    }
    .riBtns {
      margin-right: 130px;
    }
  }

  .eventBtn {
    width: 160px;
    height: 50px;
    background: linear-gradient(to Bottom, rgba(9, 45, 92, 0) 0%, #092d5c 100%);
    cursor: pointer;

    img {
      margin-right: 8px;
    }

    span {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(0deg, #8ac7ff 0%, #ffffff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .box_camera_content {
    width: 100%;
    height: calc(100% - 60px);
    background: rgba(2, 11, 18, 0.8);
    border-radius: 3px;
    // overflow: hidden;
    position: relative;
    cursor: pointer;
    margin-top: 50px;

    .rightArrow {
      position: absolute;
      left: 130px;
      z-index: 2;
      bottom: 58px;
      cursor: pointer;
    }
  }

  .box_camera_content_record {
    width: 100%;
    height: 800px;
    background: rgba(2, 11, 18, 0.8);
    border-radius: 3px;
    // overflow: hidden;
    position: relative;
    cursor: pointer;
    margin-top: 5px;

    .rightArrow {
      position: absolute;
      left: 130px;
      z-index: 2;
      bottom: 58px;
      cursor: pointer;
    }
  }
}

.rox_camera {
  width: 100%;
  height: 640px;
  transition: all 0.3s;
  background: rgba(7, 28, 49, 1);
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  // position: fixed;
  // left: 0;
  // bottom: 200px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 10px;

  .box_camera_content {
    width: 100%;
    height: 520px;
    background: #000;
    border-radius: 14px;
    // overflow: hidden;
    position: relative;
  }
}

.box_camera_datas {
  margin: 42px auto 0 auto;
  max-width: 1500px;
  height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  // padding-right: 100px;
  .box_camera_name {
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #a6ffef;
    background: linear-gradient(0deg, #a6ffef 0%, #22e2ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    img {
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }
  }

  .box_camera_data {
    font-size: 18px;
    font-weight: 400;
    color: rgba(91, 119, 147, 1);
    line-height: 26px;

    span {
      color: rgba(193, 222, 224, 1);
    }
  }
}

.box_camera_clear {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 9;
  color: rgba(236, 87, 78, 1);
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 5px 10px 0px rgba(235, 84, 56, 0.5);
  transition: all 0.3s;
}

.box_camera_button {
  width: 120px;
  height: 40px;
  background: linear-gradient(
    to right,
    rgba(34, 226, 255, 1) 0%,
    rgba(166, 255, 239, 1) 100%
  );
  // box-shadow: 0px 5px 15px 0px rgba(1, 180, 255, 0.25),
  //   0px 10px 50px 0px rgba(1, 180, 255, 0.25);
  border-radius: 20px;
  margin-bottom: -5px;
  -webkit-text-stroke: 0.5px rgba(1, 180, 255, 0.25);
  font-size: 24px;
  font-family: YouSheBiaoTiHei;
  font-weight: 400;
  color: #071c31;

  cursor: pointer;
}

#videContainer {
  width: 100% !important;
  height: 100% !important;
}

.recordBox {
  width: 1798px !important;
  height: 840px !important;
}
// -------------------------云闪播

.closeBtn {
  z-index: 2;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  top: 5px;
  z-index: 5;
  cursor: pointer;
}

.towBox {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  overflow: hidden;

  .oneItem {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}

.addressInfo {
  margin-top: 20px;
  margin-left: 4px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;

  .addressName {
    color: var(--ssaddress);
  }

  .address {
    color: var(--ssprogress2);
  }
}

.infoDetailBox {
  width: 100%;
  height: auto;
  // background: var(--specialBack);
  border-radius: 5px;
  padding: 0 10px 20px 10px;
  box-sizing: border-box;
}

.intr {
  width: fit-content;
  padding: 0 10px;
  display: flex;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 30px;

  div:nth-child(1) {
    color: var(--ssintr);
    flex-shrink: 0;
  }

  div:nth-child(2) {
    color: var(--ssallInfo);
  }
}

.familyDetail {
  border: 1px solid;
  // border-image: linear-gradient(
  //     0deg,
  //     var(--servicesPin-button3),
  //     var(--infolist2)
  //   )
  //   10 10;
  background: linear-gradient(
    90deg,
    var(--servicesPin-button3) 0%,
    var(--infolist2) 100%
  );
  border-radius: 5px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: var(--oneItem);
  position: absolute;
  right: 18px;
  top: 108px;
  z-index: 38;
  padding: 0 4px;
  box-sizing: border-box;
  cursor: pointer;
}

.timeBox {
  width: fit-content;
  display: flex;
  align-items: center;
  height: 20px;
  background: var(--servicesPin2);
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: var(--servicesPin3);
  line-height: 20px;
  margin-bottom: 15px;

  span {
    margin-left: 10px;
  }
}

.timeBox::before {
  content: "";
  display: block;
  position: absolute;
  width: 5px;
  height: 20px;
  background: var(--servicesPin3);
  border-radius: 0px 4px 4px 0px;
}

.overview_card {
  width: 146px;
  height: 130px;
  background: rgba(114, 113, 102, 0.1);
  border: 1px solid rgba(89, 255, 162, 0.2);
  border-radius: 5px;
  overflow: hidden;
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 6px;
  margin-right: 3px;
  cursor: pointer;

  .overview_card_images {
    height: 90px;
    overflow: hidden;
    position: relative;

    > img {
      object-fit: cover;
      height: 90px;
      width: 100%;
    }

    .video_button {
      position: absolute;
      left: calc(50% - 25px);
      top: calc(50% - 25px);
      width: 50px;
      height: 50px;
      z-index: 3;
    }

    .divRadius {
      border: 10px solid #555555;
      border-left: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-top-right-radius: 4px;
      content: "";
      position: relative;
      width: 0;
    }

    .timeBox1 {
      height: 18px;
      background: rgba(0, 0, 0, 0.75);

      border-radius: 3px;
      padding: 2px 7px;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: var(--servicesPin-typeBtn1);
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      left: 6px;
      bottom: 6px;

      .text {
        margin-left: 4px;
      }
    }
  }

  .overview_card_text {
    height: 20px;
    line-height: 25px;
    box-sizing: border-box;
    padding-left: 7px;
    position: relative;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: var(--ssallInfo);
  }

  .overview_card_text1 {
    height: 20px;
    line-height: 20px;
    box-sizing: border-box;
    padding-left: 7px;
    position: relative;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: var(--ssintr);
  }
}

.overview_card:nth-child(even) {
  margin-right: 0;
}

.allDesc {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .dataDesc {
    display: flex;
    align-items: flex-end;
    width: 50%;

    .nameTitle {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #72c583;
    }

    .numBox {
      width: 80px;
      height: 24px;
      margin-left: 10px;
      background: linear-gradient(
        90deg,
        rgba(114, 113, 102, 0.3) 0%,
        rgba(114, 113, 102, 0) 100%
      );
      display: flex;
      align-items: flex-end;

      .numSpan {
        font-size: 30px;
        font-family: Square722;
        font-weight: bold;
        font-style: italic;
        color: var(--servicesPin3);
        line-height: 26px;
      }

      .unitSpan {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #aa947c;
        line-height: 12px;
        margin: 0 0 2px 4px;
      }
    }
  }
}

.allView {
  // width: 34px;
  padding: 2px 5px;
  box-sizing: border-box;
  // height: 18px;
  background: var(--servicesPin3);
  border-radius: 0px 3px 0px 3px;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #1c2025;
  z-index: 2;
}

.yunText {
  position: relative;
}

.yunText:nth-child(even) {
  margin-right: 4px;
}

.date-picker {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  height: 26px;
  background: var(--date-picker);
  border: 1px solid var(--date-picker1);
  border-radius: 3px;
  flex-shrink: 0;

  .date-picker-label {
    margin-left: 5px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: var(--date-picker-label);
    line-height: 24px;
    white-space: nowrap;
  }
}

.count {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .allCount {
    display: flex;
    align-items: flex-end;

    > span {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: var(--date-picker-label);
      white-space: nowrap;
    }

    .numberBox {
      width: 80px;
      height: 18px;
      margin-left: 5px;
      display: flex;
      align-items: flex-end;
      background: linear-gradient(
        to right,
        var(--videoList) 0%,
        var(--levelNames) 100%
      );

      .num {
        font-size: 24px;
        font-family: Square722;
        font-weight: bold;
        font-style: italic;
        color: var(--servicesPin3);
        margin-bottom: -4px;
      }

      .unit {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: var(--ssallInfo);
        margin-left: 2px;
      }
    }
  }
}

.shanbo_right {
  width: 380px;
  height: 940px;
  // border: 1px solid rgba(0, 198, 255, 0.3);
  // background: rgba(7, 28, 49, 0.6);
  // box-shadow: 0px 10px 40px 0px rgba(51, 102, 153, 0.5);
  padding: 10px 15px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  position: relative;
  flex-direction: column;

  .shanbo {
    height: 200px;
    width: 100%;
    background: linear-gradient(to top, black 0%, transparent 100%);
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 5;

    .box_camera_button {
      width: 120px;
      height: 40px;
      background: linear-gradient(
        to right,
        rgba(34, 226, 255, 1) 0%,
        rgba(166, 255, 239, 1) 100%
      );
      border-radius: 20px;
      margin-bottom: -5px;
      -webkit-text-stroke: 0.5px rgba(1, 180, 255, 0.25);
      font-size: 24px;
      font-family: YouSheBiaoTiHei;
      font-weight: 400;
      color: #071c31;
      cursor: pointer;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0px);
      bottom: 3px;
    }
  }
}

.box_camera_content_camera {
  width: 100%;
  height: 810px;
  background: rgba(2, 11, 18, 0.8);
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  margin-top: 80px;

  .detectionLiveBox {
    width: 1400px;
    height: 810px;
    background: #020b12;
    border: 1px solid #092d5c;
    padding: 21px;
    box-sizing: border-box;

    .live_box {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }

  .cutLine {
    width: 1px;
    height: 100%;
    background: rgba(2, 152, 251, 0.8);
  }

  .cameraRecordList {
    height: 810px;

    .camerabtns {
      width: 151px;
      height: 40px;
      border: 1px solid #092d5c;
      background: linear-gradient(
        to bottom,
        rgba(9, 45, 92, 0) 0%,
        #092d5c 100%
      );

      span {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        line-height: 48px;

        background: linear-gradient(0deg, #8ac7ff 0%, #ffffff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .shanboCountBox {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #7d98b3;
      line-height: 14px;

      .shanboCount {
        width: 80px;
        height: 24px;
        background: linear-gradient(
          to right,
          rgba(1, 198, 255, 0.3) 100%,
          rgba(1, 198, 255, 0) 0%
        );
        margin-left: 5px;

        .num {
          font-size: 30px;
          font-family: Square722;
          font-weight: bold;
          // font-style: italic;
          color: #01c6ff;
          line-height: 24px;
          background: linear-gradient(0deg, #a6ffef 0%, #22e2ff 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .unit {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #e1f2e0;
          margin-left: 5px;
        }
      }
    }

    .listItem {
      width: 300px;
      // background: linear-gradient(to bottom, rgba(9, 45, 92, 0) 0%, rgba(9, 45, 92, 0.5) 100%);

      // border-bottom: 1px solid rgba(2, 152, 251, 0.8);
      .listItemVideo {
        background: linear-gradient(
          to bottom,
          rgba(9, 45, 92, 0) 0%,
          rgba(9, 45, 92, 0.5) 100%
        );
        border-bottom: 1px solid rgba(2, 152, 251, 0.8);
        margin-bottom: 10px;
        padding: 10px;
        box-sizing: border-box;
      }

      .timeLine {
        width: fit-content;
        padding: 0 7px;
        line-height: 16px;
        height: 16px;
        // background: linear-gradient(90deg, #0298fb 0%, #12162a 100%);
        background: linear-gradient(
          to right,
          rgba(235, 84, 56, 1) 0%,
          rgba(18, 22, 42, 1) 100%
        );
        border-radius: 8px;
        font-size: 12px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #ffffff;
        margin: 3px 0 10px 0;
      }

      .videoImgBox {
        width: 120px;
        height: 90px;
        position: relative;
        border-radius: 5px;
        margin-right: 20px;

        .avatar {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .playBtn {
          width: 36px;
          height: 36px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
        }
      }

      .itemName {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #cde4f8;
      }

      .timeText {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #738ea2;
        margin: 12px 0;
      }

      .download {
        margin-right: 16px;
      }
    }
  }

  .warningList {
    width: 347px;
    height: 100%;
    border-right: 1px solid rgba(2, 152, 251, 0.8);
    padding-right: 20px;
    box-sizing: border-box;

    .backColor {
      background: linear-gradient(
        to bottom,
        rgba(9, 45, 92, 0) 0%,
        rgba(9, 45, 92, 0.5) 100%
      );
      border-bottom: 1px solid rgba(2, 152, 251, 0.8);
    }

    .selectRow {
      padding: 0 10px;
      box-sizing: border-box;
    }

    .listItem {
      // width: 275px;
      padding: 10px 10px 0 10px;
      box-sizing: border-box;
      // margin-bottom: 10px;

      .timeLine {
        width: fit-content;
        padding: 0 7px;
        line-height: 16px;
        height: 16px;
        // background: linear-gradient(90deg, #0298fb 0%, #12162a 100%);
        background: linear-gradient(
          to right,
          rgba(235, 84, 56, 1) 0%,
          rgba(18, 22, 42, 1) 100%
        );
        border-radius: 8px;
        font-size: 12px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #ffffff;
      }

      .statusText {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #eb5438;
        margin-left: 13px;
      }

      .处理中 {
        background: linear-gradient(90deg, #0298fb 0%, #12162a 100%);
      }

      .待处理 {
        background: linear-gradient(
          90deg,
          rgba(235, 84, 56, 1) 0%,
          #12162a 100%
        );
      }

      .已转报 {
        background: linear-gradient(
          90deg,
          rgba(255, 205, 6, 1) 0%,
          #12162a 100%
        );
      }

      .已办结 {
        background: linear-gradient(
          90deg,
          rgba(15, 243, 238, 1) 0%,
          #12162a 100%
        );
      }

      .进入 {
        background: linear-gradient(
          90deg,
          rgba(255, 138, 74, 1) 0%,
          rgba(18, 22, 42, 1) 100%
        );
      }
      .途经 {
        background: linear-gradient(
          90deg,
          rgb(255, 205, 6) 0%,
          rgb(235, 213, 128) 100%
        ) !important;
        color: #000000 !important;
      }

      .离开 {
        background: linear-gradient(
          90deg,
          rgb(0, 191, 127) 0%,
          rgba(18, 22, 42, 1) 100%
        ) !important;
      }

      .进入Text {
        color: rgba(255, 138, 74, 1) !important;
      }
      .离开Text {
        color: rgb(0, 191, 127) !important;
      }

      .途经Text {
        color: rgb(255, 205, 6) !important;
      }

      .carTime {
        font-size: 12px;
        font-family: DINPro;
        font-weight: 500;
        color: #ffffff;
        white-space: nowrap;
      }

      .eventName {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #cde4f8;
        margin: 14px 0 10px 0;
      }

      .eventDesc {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #738ea2;
        line-height: 18px;
        margin-right: 30px;

        span:nth-child(1) {
          color: #738ea2;
        }

        span:nth-child(2) {
          color: rgba(161, 184, 205, 1);
        }
        .未确定 {
          color: rgba(255, 138, 74, 1) !important;
        }

        .无效预警 {
          color: rgba(235, 84, 56, 1) !important;
        }

        .有效预警 {
          color: rgba(0, 191, 127, 1) !important;
        }

        .无车牌 {
          color: rgba(169, 180, 200, 1) !important;
        }
      }
    }
  }

  .carList {
    .listItem {
      // width: 275px;
      padding: 10px 10px 0 10px;
      box-sizing: border-box;
      // margin-bottom: 10px;

      .timeLine {
        width: fit-content;
        padding: 0 7px;
        line-height: 16px;
        height: 16px;
        // background: linear-gradient(90deg, #0298fb 0%, #12162a 100%);
        background: linear-gradient(
          to right,
          rgba(235, 84, 56, 1) 0%,
          rgba(18, 22, 42, 1) 100%
        );
        border-radius: 8px;
        font-size: 12px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #ffffff;
      }

      .statusText {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #eb5438;
        margin-left: 13px;
      }

      .处理中 {
        background: linear-gradient(90deg, #0298fb 0%, #12162a 100%);
      }

      .待处理 {
        background: linear-gradient(
          90deg,
          rgba(235, 84, 56, 1) 0%,
          #12162a 100%
        );
      }

      .已转报 {
        background: linear-gradient(
          90deg,
          rgba(255, 205, 6, 1) 0%,
          #12162a 100%
        );
      }

      .已办结 {
        background: linear-gradient(
          90deg,
          rgba(15, 243, 238, 1) 0%,
          #12162a 100%
        );
      }

      .进入 {
        background: linear-gradient(
          90deg,
          rgba(255, 138, 74, 1) 0%,
          rgba(18, 22, 42, 1) 100%
        );
      }
      .途经 {
        background: linear-gradient(
          90deg,
          rgb(255, 205, 6) 0%,
          rgb(235, 213, 128) 100%
        ) !important;
        color: #000000 !important;
      }
      .离开 {
        background: linear-gradient(
          90deg,
          rgb(0, 191, 127) 0%,
          rgba(18, 22, 42, 1) 100%
        ) !important;
      }

      .进入Text {
        color: rgba(255, 138, 74, 1) !important;
      }
      .离开Text {
        color: rgb(0, 191, 127) !important;
      }

      .途经Text {
        color: rgb(255, 205, 6) !important;
      }
      .carTime {
        font-size: 12px;
        font-family: DINPro;
        font-weight: 500;
        color: #ffffff;
        margin-left: 10px;
        white-space: nowrap;
      }

      .eventName {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #cde4f8;
        margin: 14px 0 10px 0;
      }

      .toSvg {
        width: 12px;
        height: 9px;
        color: rgba(255, 255, 255, 0.5);
        position: absolute;
        right: 16px;
        top: 50%;
        z-index: 1;
        transform: translate(0, -50%);
      }

      .carImg {
        width: 60px;
        height: 60px;
        margin-right: 8px;
      }

      .eventDesc {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #738ea2;
        line-height: 18px;
        margin-right: 30px;

        span:nth-child(1) {
          color: #738ea2;
        }

        span:nth-child(2) {
          color: rgba(161, 184, 205, 1);
        }
      }
    }
  }

  .carNumBox {
    position: relative;
    margin-top: 12px;
    .carNum {
      padding: 2px;
      height: 30px;
      background: #ffcd06;
      border-radius: 3px;
      box-sizing: border-box;
      width: fit-content;
      .carNumBefore {
        height: 26px;
        border: 1px solid #020b12;
        border-radius: 2px;
        font-size: 16px;
        padding: 6px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        box-sizing: border-box;
        color: #020b12;
        white-space: nowrap;
        img {
          width: 20px;
          height: 16px;
          margin-left: 15px;
        }
      }
    }

    .phone {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #a1b8cd;
      margin-left: 9px;
    }

    .未确定 {
      background: #ffcd06 !important;
    }

    .无效预警 {
      background: rgba(235, 84, 56, 1) !important;
    }

    .有效预警 {
      background: rgba(0, 191, 127, 1) !important;
    }

    .无车牌 {
      background: rgba(169, 180, 200, 1) !important;
    }

    .enter_out {
      margin-left: 10px;
      span:nth-child(1) {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #738ea2;
        line-height: 20px;
      }

      span:nth-child(2) {
        font-size: 24px;
        font-family: Square722;
        font-weight: 400;
        color: rgba(255, 138, 74, 1);
        line-height: 24px;
        font-style: italic;
      }
    }
  }

  .recordText {
    // height: 13px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #cde4f8;
    width: 100%;
    padding-left: 8px;
    box-sizing: border-box;
    position: relative;
  }

  .recordText::before {
    content: "";
    width: 4px;
    height: 14px;
    background: #0298fb;
    border-radius: 2px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0px, -50%);
  }

  .warningCenter {
    width: 1140px;
    height: 100%;
    margin: 0 20px;

    .liveBox {
      width: 100%;
      height: 680px;
      background: #020b12;
      border: 1px solid #092d5c;
      position: relative;
    }

    .allImageList {
      width: 100%;
      height: 120px;
    }
  }

  .warningRight {
    width: 310px;
    height: 100%;

    .backColor {
      background: linear-gradient(
        to bottom,
        rgba(9, 45, 92, 0) 0%,
        rgba(9, 45, 92, 0.5) 100%
      );
      border-bottom: 1px solid rgba(2, 152, 251, 0.8);
    }

    .eventName {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      line-height: 24px;
      margin-bottom: 20px;

      .statusBtn {
        width: 46px;
        height: 18px;
        background: #0ff3ee;
        border-radius: 3px;
        line-height: 18px;
        text-align: center;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #020b12;
        margin-bottom: 2px;
        margin-left: 4px;
      }
    }

    .timeText {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #0ff3ee;

      .status {
        width: 44px;
        height: 16px;
        background: rgba(255, 69, 55, 0.2);
        border-radius: 3px;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #eb5438;
        line-height: 16px;
        text-align: center;
        margin-left: 10px;
      }

      .待处理 {
        background: rgba(255, 69, 55, 0.2);
        color: #eb5438;
      }

      .已转报 {
        background: rgba(253, 226, 37, 0.2);
        color: rgba(253, 226, 37, 1);
      }

      .处理中 {
        background: rgba(2, 152, 251, 0.2);
        color: rgba(2, 152, 251, 1);
      }

      .已办结 {
        background: rgba(15, 243, 238, 0.2);
        color: rgba(15, 243, 238, 1);
      }
    }

    .eventDesc {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #738ea2;
      line-height: 18px;
      margin-right: 30px;

      span:nth-child(1) {
        color: #738ea2;
      }

      span:nth-child(2) {
        color: rgba(161, 184, 205, 1);
      }

      .未确定 {
        color: rgba(255, 138, 74, 1) !important;
      }

      .无效预警 {
        color: rgba(235, 84, 56, 1) !important;
      }

      .有效预警 {
        color: rgba(0, 191, 127, 1) !important;
      }

      .无车牌 {
        color: rgba(169, 180, 200, 1) !important;
      }
    }
  }
}

.gapDiv {
  width: 110px;
  height: 100%;
  border-right: 1px solid rgba(2, 152, 251, 0.8);
}

.openLeft {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #2062a5;
  line-height: 46px;

  background: linear-gradient(to right, #0bceff 0%, #9be4ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 10px;
}

.openLeft:hover {
  opacity: 0.8;
}

.warnBtnPlayBack {
  width: 90px;
  height: 30px;
  background: #020a11;
  border-radius: 15px;
  border: 1px solid #ffcd06;
  font-family: Source Han Sans SC;
  font-weight: bold;
  font-size: 16px;
  color: #ffcd06;
  line-height: 30px;
  text-align: center;
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 3;
}

.warnBtnPlayBackClose {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  top: -30px;
  z-index: 9999;
}

.selection {
  position: fixed;
  box-sizing: border-box;
  border: 2px solid red;
  transition: all 0.1s;
  z-index: 999;
}

.allOtherView {
  width: auto;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 5;

  .viewItem {
    width: 360px !important;
    height: 240px !important;
    border-radius: 5px;
    border: 2px solid #ff8800;
    position: relative;
    .viewTop {
      width: 100%;
      height: 22px;
      background: rgba(0, 0, 0, 0.75);
      border-radius: 5px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      padding: 0 6px;
      box-sizing: border-box;
      span {
        font-family: Source Han Sans SC;
        font-weight: 400;
        font-size: 12px;
        color: #b5b9c0;
      }
      svg {
        width: 14px;
        height: 14px;
        cursor: pointer;
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }
}

.hideV:hover {
  opacity: 0.8;
  scale: 1.2;
}

.gap10 {
  gap: 10px;
}

.c3 {
  background: linear-gradient(
    to right,
    rgba(235, 84, 56, 1) 0%,
    rgba(18, 22, 42, 1) 100%
  ) !important;
}
.c2 {
  background: linear-gradient(
    to right,
    rgba(2, 152, 251, 1) 0%,
    rgba(18, 22, 42, 1) 100%
  ) !important;
}
.c1 {
  background: linear-gradient(
    to right,
    rgba(255, 205, 6, 1) 0%,
    rgba(18, 22, 42, 1) 100%
  ) !important;
}

// ----------------------
.listItem {
  // width: 275px;
  padding: 10px 10px 0 10px;
  box-sizing: border-box;
  // margin-bottom: 10px;

  .timeLine {
    width: fit-content;
    padding: 0 7px;
    line-height: 16px;
    height: 16px;
    // background: linear-gradient(90deg, #0298fb 0%, #12162a 100%);
    background: linear-gradient(
      to right,
      rgba(235, 84, 56, 1) 0%,
      rgba(18, 22, 42, 1) 100%
    );
    border-radius: 8px;
    font-size: 12px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #ffffff;
  }

  .statusText {
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #eb5438;
    margin-left: 13px;
  }

  .处理中 {
    background: linear-gradient(90deg, #0298fb 0%, #12162a 100%);
  }

  .待处理 {
    background: linear-gradient(90deg, rgba(235, 84, 56, 1) 0%, #12162a 100%);
  }

  .已转报 {
    background: linear-gradient(90deg, rgba(255, 205, 6, 1) 0%, #12162a 100%);
  }

  .已办结 {
    background: linear-gradient(90deg, rgba(15, 243, 238, 1) 0%, #12162a 100%);
  }

  .进入 {
    background: linear-gradient(
      90deg,
      rgba(255, 138, 74, 1) 0%,
      rgba(18, 22, 42, 1) 100%
    );
  }
  .途经 {
    background: linear-gradient(
      90deg,
      rgb(255, 205, 6) 0%,
      rgb(235, 213, 128) 100%
    ) !important;
    color: #000000 !important;
  }

  .离开 {
    background: linear-gradient(
      90deg,
      rgb(0, 191, 127) 0%,
      rgba(18, 22, 42, 1) 100%
    ) !important;
  }

  .进入Text {
    color: rgba(255, 138, 74, 1) !important;
  }
  .离开Text {
    color: rgb(0, 191, 127) !important;
  }

  .途经Text {
    color: rgb(255, 205, 6) !important;
  }

  .carTime {
    font-size: 12px;
    font-family: DINPro;
    font-weight: 500;
    color: #ffffff;
    white-space: nowrap;
  }

  .eventName {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #cde4f8;
    margin: 14px 0 10px 0;
  }

  .eventDesc {
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #738ea2;
    line-height: 18px;
    margin-right: 30px;

    span:nth-child(1) {
      color: #738ea2;
    }

    span:nth-child(2) {
      color: rgba(161, 184, 205, 1);
    }
    .未确定 {
      color: rgba(255, 138, 74, 1) !important;
    }

    .无效预警 {
      color: rgba(235, 84, 56, 1) !important;
    }

    .有效预警 {
      color: rgba(0, 191, 127, 1) !important;
    }

    .无车牌 {
      color: rgba(169, 180, 200, 1) !important;
    }
  }
}
.timeLine {
  width: fit-content;
  padding: 0 7px;
  line-height: 16px;
  height: 16px;
  // background: linear-gradient(90deg, #0298fb 0%, #12162a 100%);
  background: linear-gradient(
    to right,
    rgba(235, 84, 56, 1) 0%,
    rgba(18, 22, 42, 1) 100%
  );
  border-radius: 8px;
  font-size: 12px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ffffff;
}

.statusText {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #eb5438;
  margin-left: 13px;
}
</style>
