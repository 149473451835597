<template>
  <div class="grow column AnnotationList">
    <!-- default-expand-all  :check-on-click-node="true"-->
    <el-tree
      :allow-drop="allowDrop"
      :allow-drag="allowDrag"
      :data="arr"
      draggable
      node-key="id"
      show-checkbox
      @node-drag-start="handleDragStart"
      @node-drag-enter="handleDragEnter"
      @node-drag-leave="handleDragLeave"
      @node-drag-over="handleDragOver"
      @node-drag-end="handleDragEnd"
      @node-drop="handleDrop"
      @node-click="nodeClick"
      @check="handleCheckChange"
      :default-checked-keys="state.defaultCheckedKeys"
    >
      <template #default="{ node, data }">
        <div
          class="custom-tree-node divFlex flexBet alignCenter"
          style="width: 100%; box-sizing: border-box"
          @dblclick.stop="headerDbClick(node)"
        >
          <div
            class="divFlex flexBet alignCenter"
            style="width: 100%; box-sizing: border-box"
          >
            <img
              v-if="node.data.fileType != 'g'"
              class="icon"
              :src="require(`../assets/xinan/${node.data.markType}.png`)"
              alt=""
            />
            <span
              :title="node.data.name"
              style="width: 150px; box-sizing: border-box"
              class="name_BB"
              v-if="state.disableId != node.data.id"
            >
              {{ node.data.name }}
            </span>
            <el-input
              v-else
              :ref="node.data.id"
              :title="node.data.name"
              style="width: 150px; box-sizing: border-box"
              class="name_BB"
              v-model="node.data.name"
              @change="changeNode(node.data)"
            >
            </el-input>
          </div>
          <!-- <el-icon
            style="margin-right: 10px"
            @click.stop="clickFileShow(node.data, 0)"
            v-if="node.data.showOnMap == 1"
            ><Hide
          /></el-icon>
          <el-icon
            style="margin-right: 10px"
            @click.stop="clickFileShow(node.data, 1)"
            v-else
            ><View
          /></el-icon> -->
          <img
            v-if="node.data.dataFrom == 1"
            src="@/assets/xinan/jy.png"
            alt=""
          />

          <div
            v-else
            class="divFlex flexBet alignCenter"
            @click.stop="lock(node.data)"
          >
            <img
              :src="
                node.data.isLock
                  ? require('../assets/xinan/suo.png')
                  : require('../assets/xinan/suo1.png')
              "
              alt=""
              style="margin-right: 10px"
            />
            <img
              @click.stop="remove(node, data)"
              src="@/assets/xinan/del.png"
              alt=""
            />
          </div>
        </div>
      </template>
    </el-tree>
  </div>
</template>

<script>
// import Node from "element-plus/es/components/tree/src/model/node";
// import { DragEvents } from "element-plus/es/components/tree/src/model/useDragNode";
// import {
//   AllowDropType,
//   NodeDropType,
// } from "element-plus/es/components/tree/src/tree.type";
// VUE
import { View, Hide } from "@element-plus/icons-vue";
import {
  h,
  ref,
  reactive,
  watch,
  computed,
  getCurrentInstance,
  onMounted,
  onActivated,
  onDeactivated,
  inject,
} from "vue";
import http from "@/api/server";
export default {
  props: {
    arr: {
      default: [],
    },
    drop: {
      default: true,
    },
    drag: {
      default: true,
    },
  },
  name: "AnnotationList",
  components: {
    View,
    Hide,
  },
  setup(props, context) {
    const globalData = inject("globalData");
    const _this = getCurrentInstance().proxy;
    let state = reactive({
      defaultCheckedKeys: [],
      currentNode: {},
      lastClickTime: 0,
      clickTimer: false,
      disableId: "",
    }); // 数据

    const handleDragStart = (node, ev) => {
      // console.log("drag start", node);
    };
    const handleDragEnter = (draggingNode, dropNode, ev) => {
      // console.log("tree drag enter:", dropNode.data.name);
    };
    const handleDragLeave = (draggingNode, dropNode, ev) => {
      // console.log("tree drag leave:", dropNode.data.name);
    };
    const handleDragOver = (draggingNode, dropNode, ev) => {
      // console.log("tree drag over:", dropNode.data.name);
    };
    const handleDragEnd = (draggingNode, dropNode, dropType, ev) => {
      console.log(draggingNode, dropNode, dropType);
      if (draggingNode.data.dataFrom == 1 || dropNode.data.dataFrom == 1)
        return;
      if (dropType == "inner") {
        let element_ids = [],
          layer_ids = [],
          orders = {},
          target_layer_id = dropNode.data.uuid,
          moveBody,
          projectUuid = draggingNode.data.projectUuid;
        if (draggingNode.data.fileType == "g") {
          layer_ids = [draggingNode.data.uuid];
        } else if (draggingNode.data.fileType == "e") {
          element_ids = [draggingNode.data.uuid];
        }
        let obj = _this.deepClone(dropNode.data);
        orders.elements = [];
        orders.layers = [];
        if (obj.children && obj.children.length > 0) {
          obj.children.forEach((e, idx) => {
            if (e.fileType == "e") {
              orders.elements.push({ id: e.uuid, order: idx });
            }
            if (e.fileType == "g") {
              orders.layers.push({ id: e.uuid, order: idx });
            }
          });
        }

        moveBody = {
          element_ids,
          layer_ids,
          orders,
          target_layer_id,
        };
        http.cityFlightSkTagMove({
          projectUuid,
          moveBody: JSON.stringify(moveBody),
        });
      } else {
        let element_ids = [],
          layer_ids = [],
          orders = {},
          target_layer_id = dropNode.parent.data.uuid || draggingNode.data.uuid,
          moveBody,
          projectUuid = draggingNode.data.projectUuid;
        if (draggingNode.data.fileType == "g") {
          layer_ids = [draggingNode.data.uuid];
        } else if (draggingNode.data.fileType == "e") {
          element_ids = [draggingNode.data.uuid];
        }
        let obj = _this.deepClone(dropNode.parent.data);

        // console.log(obj,'objobjobjobjobjobj')
        orders.elements = [];
        orders.layers = [];
        let idx = 0;
        if (obj.children && obj.children.length > 0) {
          obj.children.forEach((e) => {
            if (e.dataFrom == 0) {
              if (e.fileType == "e") {
                orders.elements.push({ id: e.uuid, order: idx });
              }
              if (e.fileType == "g") {
                orders.layers.push({ id: e.uuid, order: idx });
              }
              idx++;
            }
          });
        } else if (obj && obj.length > 0) {
          obj.forEach((e, idx) => {
            if (e.dataFrom == 0) {
              if (e.fileType == "e") {
                orders.elements.push({ id: e.uuid, order: idx });
              }
              if (e.fileType == "g") {
                orders.layers.push({ id: e.uuid, order: idx });
              }
              idx++;
            }
          });
        }

        moveBody = {
          element_ids,
          layer_ids,
          orders,
          target_layer_id,
        };
        http.cityFlightSkTagMove({
          projectUuid,
          moveBody: JSON.stringify(moveBody),
        });
      }
    };
    const remove = (node, data) => {
      _this
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          let parent = node.parent;
          let children = parent.data.children || parent.data;
          let index = children.findIndex((d) => d.id === data.id);
          children.splice(index, 1);
          props.arr = [...props.arr];
          http.cityFlightSkTagRemove(node.data.id).then((res) => {
            return _this.$message({
              type: "success",
              message: res.msg,
            });
          });
        });
    };
    const handleDrop = (draggingNode, dropNode, dropType, ev) => {
      // console.log("tree drop:", dropNode.data.name, dropType);
    };

    //是否允许移动到目标位置
    const allowDrop = (draggingNode, dropNode, type) => {
      return type == "inner"
        ? !dropNode.data.isLock &&
            dropNode.data.fileType == "g" &&
            dropNode.data.dataFrom != 1
        : _this.isArray(dropNode.parent.data)
        ? true
        : dropNode.parent.data.dataFrom != 1;
    };
    //是否允许移动
    const allowDrag = (draggingNode) => {
      return !draggingNode.data.isLock && draggingNode.data.dataFrom != 1;
    };

    const nodeClick = (draggingNode, dropNode, dropType, ev) => {
      if (state.clickTimer) clearTimeout(state.clickTimer);
      state.clickTimer = setTimeout(() => {
        // console.log("单击");
        if (draggingNode.fileType != "e") return;
        // if (draggingNode.coordinates && draggingNode.coordinates.length > 0) {
        //   draggingNode.coordinates.forEach((e, idx) => {
        //     draggingNode.coordinates[idx] = Number(e);
        //   });
        // }
        globalData.bzData = draggingNode;
        globalData.bzShow = true;
      }, 400);
    };

    const headerDbClick = (node) => {
      if (state.clickTimer) clearTimeout(state.clickTimer);
      state.clickTimer = setTimeout(() => {
        state.disableId = node.data.id;
        setTimeout(() => {
          _this.$refs[node.data.id].focus();
        }, 300);
      }, 400);
    };

    const lock = (val) => {
      let bool = val.isLock;
      val.isLock = val.isLock == 1 ? 0 : 1;
      let req = bool ? http.cityFlightSkTagUnlock : http.cityFlightSkTagLock;
      req({ ids: [val.id] }).then((res) => {
        context.emit("getList");
        return _this.$message({
          type: "success",
          message: res.msg,
        });
      });
    };
    const clickFileShow = (info, switchValue) => {
      info.showOnMap = switchValue;
      http.showOnMap({ ids: [info.id], switchValue }).then((res) => {
        _this.$message({
          type: "success",
          message: res.msg,
        });
      });
    };
    const handleCheckChange = (data, checked, indeterminate) => {
      // console.log(data,indeterminate)
      data.showOnMap = data.showOnMap == 1 ? 0 : 1;
      context.emit("changeShowOnMapData", data);
      http
        .showOnMap({ ids: [data.id], switchValue: data.showOnMap })
        .then((res) => {
          return _this.$message({
            type: "success",
            message: res.msg,
          });
        });
    };
    const changeNode = (val) => {
      // console.log(val)
      http.updateName({ name: val.name, id: val.id }).then((res) => {
        return _this.$message({
          type: "success",
          message: res.msg,
        });
      });
    };
    onMounted(async () => {
      let arr = _this.getAnnotationData(props.arr);
      state.defaultCheckedKeys = arr.map((e) => {
        return e.id;
      });
    });

    return {
      // 变量
      state,
      globalData,
      //方法
      allowDrop,
      allowDrag,
      handleDrop,
      handleDragEnd,
      handleDragOver,
      handleDragLeave,
      handleDragEnter,
      handleDragStart,
      remove,
      nodeClick,
      lock,
      clickFileShow,
      handleCheckChange,
      headerDbClick,
      changeNode,
    };
  },
};
</script>

<style lang="scss" scoped>
.custom-tree-node {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 14px;

  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #cde4f8;

  .icon {
    width: 14px;
    height: auto;
    margin-right: 15px;
  }
}

.name_BB {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
