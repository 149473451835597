<template>
  <div class="detection_right">
    <!-- <DetectionTilte
      title="图层筛选器"
      englishText="TRAFFIC CALENDAR"
      v-if="!state.lwShow"
    /> -->

    <!-- <div
      v-if="!state.lwShow"
      @click="showLw('疑似两违')"
      class="lwBtn divFlex alignCenter mousePoint"
    >
      <img src="@/assets/xinan/lw.png" alt="" />
      <span>疑似两违</span>
    </div>

    <div
      v-if="!state.lwShow"
      @click="showLw('在建房屋')"
      class="zjBtn divFlex alignCenter mousePoint"
    >
      <img src="@/assets/xinan/lw.png" alt="" />
      <span>在建房屋</span>
    </div> -->

    <div class="topCommon scene hid grow column" v-if="!state.lwShow">
      <SpaceDiv height="16" />
      <div class="btn_box">
        <WarnMapBtns
          @chooseThis="switchTypeFun"
          size="70"
          hgt="30"
          :btn="state.btn"
          :arr="state.typeArr"
        />
      </div>
      <!-- style="height: calc(100vh - 132px)" -->
      <div class="column grow hid manageScroll">
        <!-- <el-scrollbar class="grow hid"> -->
        <div v-for="(item, key) in globalData.chooseObj" :key="key + 'gfdgdgd'">
          <BuildingType
            v-if="!state.allKey?.图斑.includes(key)"
            :key="key + 'gfdgdgd'"
            :title="key"
            :obj="item"
          />
        </div>
        <div v-if="globalData.otherObjs && state.btn == 3">
          <!--  -->
          <BuildingType2
            v-for="(item, key) in globalData.otherObjs"
            :key="key + 'gfdgdgd'"
            :title="key"
            :obj="item"
            :borderShow="false"
          />
        </div>

        <!-- 乡镇街道边界线           :borderShow="false" -->
        <div v-for="(item, key) in globalData.chooseObj" :key="key + 'gfdgdgd'">
          <BuildingType2
            v-if="state.allKey?.图斑.includes(key)"
            :title="key"
            :obj="item"
          />
        </div>
        <!-- </el-scrollbar> -->
      </div>
    </div>

    <div class="topCommon relative" v-else>
      <img
        class="closeLw"
        @click="closeLw()"
        src="@/assets/images/d6.png"
        alt=""
      />

      <SpaceDiv height="16" />
      <div class="lwName">{{ state.lwShow }}图斑标注器</div>
      <SpaceDiv height="58" />
      <!--  名称： -->
      <div class="column lwItem">
        <div class="bet lw mousePoint">
          <div class="divFlex">
            <div class="dot"></div>
            <div class="label">名称：</div>
          </div>
          <el-input v-model="state.lwOption.name" placeholder="请输入..." />
        </div>
        <SpaceDiv height="30" />
      </div>
      <!--  所属网格： -->
      <div class="column lwItem mousePoint">
        <div class="bet lw mousePoint">
          <div class="divFlex">
            <div class="dot"></div>
            <div class="label">所属网格：</div>
          </div>
          <el-input
            disabled
            v-model="state.lwOption.grid"
            placeholder="请输入..."
          />
        </div>
        <SpaceDiv height="30" />
      </div>
      <!-- 在建房屋日期 -->
      <div class="column lwItem mousePoint" v-if="state.lwShow == '在建房屋'">
        <div class="bet lw mousePoint">
          <div class="divFlex">
            <div class="dot"></div>
            <div class="label">审批日期：</div>
          </div>
          <el-date-picker
            v-model="state.lwOption.dateTime"
            type="date"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            placeholder="选择日期..."
            :default-value="new Date()"
          />
        </div>
        <SpaceDiv height="30" />
      </div>

      <!-- 疑似两违日期 -->
      <div class="column lwItem mousePoint" v-if="state.lwShow == '疑似两违'">
        <div class="bet lw mousePoint">
          <div class="divFlex">
            <div class="dot"></div>
            <div class="label">发现日期：</div>
          </div>
          <el-date-picker
            v-model="state.lwOption.dateTime"
            type="date"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            placeholder="选择日期..."
            :default-value="new Date()"
          />
        </div>
        <SpaceDiv height="30" />
      </div>

      <!--  名称： -->
      <div class="column lwItem">
        <div class="bet lw mousePoint">
          <div class="divFlex">
            <div class="dot"></div>
            <div class="label">面积：</div>
          </div>
          <el-input
            disabled
            v-model="state.lwOption.area"
            placeholder="请输入..."
          />
        </div>
        <SpaceDiv height="30" />
      </div>

      <!-- 颜色 -->
      <div class="column lwItem mousePoint">
        <div class="bet">
          <div class="divFlex alignCenter">
            <div class="dot"></div>
            <div class="label">颜色编辑：</div>
            <div class="divFlex alignCenter">
              <div
                :style="`background:${item}`"
                class="colorItem"
                @click="state.lwOption.color = item"
                v-for="(item, i) in state.predefineColors"
                :key="i + 'fdsfgdfgh'"
              >
                <svg
                  v-if="state.lwOption.color == item"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1024 1024"
                  data-v-ea893728=""
                >
                  <path
                    fill="currentColor"
                    d="M77.248 415.04a64 64 0 0 1 90.496 0l226.304 226.304L846.528 188.8a64 64 0 1 1 90.56 90.496l-543.04 543.04-316.8-316.8a64 64 0 0 1 0-90.496z"
                  ></path>
                </svg>
              </div>
            </div>
            <!-- <el-color-picker
              size="small"
              v-model="state.lwOption.color"
              show-alpha
              :predefine="state.predefineColors"
            /> -->
          </div>
        </div>
        <!-- <SpaceDiv height="16" />
        <div class="valueDiv bet">
          <span>{{ state.lwOption.color }}</span>
          <span class="flexShrink">{{ state.lwOption.color }}</span>
        </div> -->
        <SpaceDiv height="30" />
      </div>

      <!--  区域编辑： -->

      <div class="column lwItem mousePoint">
        <div class="bet">
          <div class="divFlex">
            <div class="dot"></div>
            <div class="label">区域编辑：</div>
          </div>
          <div class="divFlex">
            <div class="pickPst" @click="startDrawPolygon">选取区域</div>
            <div class="clearText" @click="clearArea">清除</div>
          </div>
        </div>
        <SpaceDiv height="16" />
        <div class="valueDiv bet">
          <span>{{ state.lwOption.border }}</span>
          <span class="flexShrink">{{ state.lwOption.area }}</span>
        </div>
        <SpaceDiv height="30" />
      </div>

      <!--  编辑位置 -->
      <!-- <div class="column lwItem mousePoint">
        <div class="bet">
          <div class="divFlex">
            <div class="dot"></div>
            <div class="label">编辑位置：</div>
          </div>
          <div class="divFlex">
            <div class="pickPst" @click="startDrawGraphic">选取坐标</div>
            <div class="clearText" @click="clearPos">清除</div>
          </div>
        </div>
        <SpaceDiv height="16" />
        <div class="valueDiv bet">
          <span>{{ state.lwOption.position }}</span>
        </div>
        <SpaceDiv height="30" />
      </div> -->

      <div class="column lwItem mousePoint">
        <div class="bet">
          <div class="divFlex">
            <div class="dot"></div>
            <div class="label">编辑位置：</div>
          </div>
          <div class="divFlex">
            <div class="pickPst" @click="startDrawGraphic">选取坐标</div>
            <div class="clearText" @click="clearPos">清除</div>
          </div>
        </div>
        <SpaceDiv height="16" />
        <div class="valueDiv bet">
          <span>{{ state.lwOption.position }}</span>
        </div>
        <SpaceDiv height="30" />
      </div>
      <!--  固定视口 -->
      <div class="column lwItem mousePoint">
        <div class="bet">
          <div class="divFlex">
            <div class="dot" style="opacity: 0"></div>
            <div class="label">固定视口：</div>
          </div>
          <div class="divFlex">
            <div class="pickPst" @click="pickViews">选取视口</div>
            <div class="clearText" @click="clearViews">清除</div>
          </div>
        </div>
        <SpaceDiv height="16" />
        <div class="valueDiv bet">
          <span>{{ state.lwOption.views }}</span>
        </div>
        <SpaceDiv height="30" />
      </div>

      <div class="bet twoBtn mousePoint">
        <div class="lwSub" @click="createdLw()">
          <span :style="hoverBtn() ? 'opacity: 1;' : 'pointer-events: none;'"
            >新建{{ state.lwShow }}</span
          >
        </div>
        <div @click="closeLw()" class="lwqui"><span>取消</span></div>
      </div>

      <SpaceDiv height="40" />
    </div>

    <div class="divFlex allSelect2" v-if="state.btn == 2">
      <div class="leftSelect allBtnsWarn">
        <el-select
          v-model="state.leftInfo.name"
          class="m-2 leftSelect"
          placeholder="请选择..."
          size="large"
          ref="leftSelect1"
        >
          <el-option hidden :value="state.leftInfo" :label="state.leftValue" />
          <el-tree
            :data="state.allModelList"
            :props="{
              children: 'children',
              label: 'name',
            }"
            :expand-on-click-node="false"
            :check-on-click-node="true"
            @node-click="handleCut"
          >
          </el-tree>
        </el-select>
      </div>
      <div class="rightSelect allBtnsWarn">
        <el-select
          v-model="state.rightInfo.name"
          class="m-2"
          placeholder="请选择..."
          size="large"
          ref="rightSelect1"
        >
          <el-option
            hidden
            :value="state.rightValue"
            :label="state.rightValue"
          />
          <el-tree
            :data="state.allModelList"
            :props="{
              children: 'children',
              label: 'name',
            }"
            :expand-on-click-node="false"
            :check-on-click-node="true"
            @node-click="handleCut1"
          >
          </el-tree>
        </el-select>
      </div>
    </div>
    <div class="divFlex allSelect" v-show="state.btn == 1" ref="allSelect">
      <div class="leftSelect allBtnsWarn">
        <el-select
          v-model="state.leftInfo.name"
          class="m-2 leftSelect"
          placeholder="请选择..."
          size="large"
          ref="leftSelect"
        >
          <el-option hidden :value="state.leftInfo" :label="state.leftValue" />
          <el-tree
            :data="state.allModelList"
            :props="{
              children: 'children',
              label: 'name',
            }"
            :expand-on-click-node="false"
            :check-on-click-node="true"
            @node-click="handleCut"
          >
          </el-tree>
        </el-select>
      </div>
      <div class="rightSelect allBtnsWarn">
        <!-- <el-select
          v-model="state.rightValue"
          class="m-2"
          placeholder="请选择..."
          size="large"
        >
          <el-option
            v-for="item in state.allModelList"
            :key="item.modelUrl"
            :label="item.name"
            :value="item.modelUrl"
          />
        </el-select> -->

        <el-select
          v-model="state.rightInfo.name"
          class="m-2"
          placeholder="请选择..."
          size="large"
          ref="rightSelect"
        >
          <el-option
            hidden
            :value="state.rightValue"
            :label="state.rightValue"
          />
          <el-tree
            :data="state.allModelList"
            :props="{
              children: 'children',
              label: 'name',
            }"
            :expand-on-click-node="false"
            :check-on-click-node="true"
            @node-click="handleCut1"
          >
          </el-tree>
        </el-select>
      </div>
    </div>

    <div
      class="tbDetail"
      style="display: none; width: 0.1px; height: 0.1px"
      ref="tbDetail"
    >
      <img class="linkLine" src="@/assets/detection/linkline.png" alt="" />

      <div
        class="detectionDetailBox column"
        style="right: 92px"
        v-if="state.tbData && state.tbData.data"
      >
        <div class="topLine"></div>

        <div class="divFlex flexBet" style="margin-top: 27px">
          <div class="divFlex">
            <div class="cameraName">{{ state.tbData.name }}</div>
          </div>
          <svg
            @click="closeTbDetail"
            class="closeBtn"
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            data-v-ea893728=""
          >
            <path
              fill="currentColor"
              d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z"
            ></path>
          </svg>
        </div>
        <SpaceDiv :height="30" />
        <div
          class="eventDesc"
          v-for="(item, idx) in state.tbData.data"
          :key="idx + 'fdsfds'"
        >
          <span>{{ idx }}：</span>
          <span>{{ item || "--" }}</span>
        </div>
      </div>
    </div>

    <div class="tbDetail" style="display: none" ref="detectionDetail">
      <div
        class="tbPinBox"
        :style="state.showIcon ? `opacity: 1;` : `opacity: 0;`"
      >
        <div class="divFlex alignCenter">
          <div class="relative">
            <div class="icon">
              <div
                class="child1"
                :style="`border-color:${state.detailInfo.color};`"
              ></div>
              <div class="child2"></div>
            </div>
            <div
              class="biDiv"
              :style="`background:${state.detailInfo.color};`"
            ></div>
          </div>
        </div>
      </div>

      <img class="linkLine" src="@/assets/detection/linkline.png" alt="" />

      <div class="detectionDetailBox column" v-if="state.detailInfo">
        <div class="topLine"></div>

        <div class="divFlex flexBet" style="margin-top: 27px">
          <div class="divFlex">
            <div class="cameraName">{{ state.detailInfo.name }}</div>
          </div>
          <svg
            @click="closeVideo"
            class="closeBtn"
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            data-v-ea893728=""
          >
            <path
              fill="currentColor"
              d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z"
            ></path>
          </svg>
        </div>
        <SpaceDiv :height="30" />
        <div
          class="eventDesc"
          v-for="(item, idx) in state.detailInfo.data"
          :key="idx + 'fdsfdshgd'"
        >
          <span>{{ idx }}：</span>
          <span>{{ item }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import {getSimpleList} from "@/api";
// Component
// VUE
import { ElLoading } from "element-plus";
import {
  h,
  ref,
  reactive,
  watch,
  getCurrentInstance,
  onMounted,
  onActivated,
  onDeactivated,
  onUnmounted,
  onBeforeMount,
  inject,
} from "vue";

import {
  DetectionTilte,
  WarnMapBtns,
  SpaceDiv,
  BuildingType,
  BuildingType1,
  BuildingType2,
} from "@/components";
// Components
let graphicLayer; // 普通地图使用
let rightGraphicLayer; // 画两违  在建房用的
import http from "@/api/server";
export default {
  components: {
    DetectionTilte,
    WarnMapBtns,
    SpaceDiv,
    BuildingType,
    BuildingType1,
    BuildingType2,
  },
  props: {
    name: {
      default: "",
    },
  },
  name: "Manages",
  setup(props, context) {
    const _this = getCurrentInstance().proxy;
    const globalData = inject("globalData");
    let state = reactive({
      // 画点  线  面   圆
      点: false,
      线: false,
      面: false,
      圆: false,
      // --------------------------
      detailInfo: false,
      predefineColors: [
        "rgb(45, 140, 240)",
        "rgb(25, 190, 107)",
        "rgb(255, 187, 0)",
        "rgb(226, 60, 57)",
        "rgb(182, 32, 224)",
      ],
      loading: false,
      loadCount: 0,
      loadNum: 0,
      tbData: false,
      tbPosition: false,
      nowGraphic: false, // 当前选中的图斑模块
      leftAllChoose: [
        {
          modelType: "卫星地图",
          modelUrl: "天地图影像",
          name: "测试地图",
        },
      ],
      leftValue: "",
      leftInfo: "",
      rightAllChoose: [
        {
          modelType: "卫星地图",
          modelUrl: "天地图影像",
          name: "测试地图",
        },
      ],
      rightValue: "",
      rightInfo: "",
      allModelList: false,
      typeArr: [
        {
          label: "单屏",
          value: 3,
        },
        {
          label: "卷帘对比",
          value: 1,
        },
        {
          label: "分屏对比",
          value: 2,
        },
      ],
      btn: 3,
      lwShow: false,
      lwOption: {
        name: "",
        area: "",
        views: map.getCameraView(),
        border: "",
        grid: "",
        position: "",
        type: "",
        color: "rgb(45, 140, 240)",
        gridId: "",
        dateTime: moment().format("YYYY-MM-DD"),
      },
      treeData: {},
      gridInfo: false,
      cameraId: "",
    }); // 数据
    const hoverBtn = () => {
      let bool = false;
      for (let key in state.lwOption) {
        if (state.lwOption[key]) {
          bool = true;
        }
      }
      return bool;
    };

    const initReformModel = (val) => {
      if (window.mapSplit) map.removeControl(window.mapSplit);
      let leftLayer = [globalData.earthArr[state.leftAllChoose[0].modelUrl]],
        rightLayer = [globalData.earthArr[state.rightAllChoose[0].modelUrl]];
      if (state.leftAllChoose[1]) {
        if (state.leftAllChoose[1].modelType == "三维模型") {
          leftLayer.push({
            name: "模型",
            type: "3dtiles",
            url: state.leftAllChoose[1].modelUrl,
            // chinaCRS:mars3d.ChinaCRS.WGS84,
            // clampToGround: true,
          });
        } else {
          leftLayer.push({
            // name: "分屏对比切片",
            name: state.leftAllChoose[1].name,
            type: "xyz",
            zIndex: 9,
            url: state.leftAllChoose[1].modelUrl,
            hasZIndex: true,
            // chinaCRS: mars3d.ChinaCRS.GCJ02,
            show: true,
            // clampToGround: true,
          });
        }
      }

      if (state.rightAllChoose[1]) {
        if (state.rightAllChoose[1].modelType == "三维模型") {
          rightLayer.push({
            name: "模型",
            type: "3dtiles",
            url: state.rightAllChoose[1].modelUrl,
          });
        } else {
          rightLayer.push({
            // name: "分屏对比切片",
            name: state.rightAllChoose[1].name,
            type: "xyz",
            hasZIndex: true,
            zIndex: 9,
            url: state.rightAllChoose[1].modelUrl,
            // chinaCRS: mars3d.ChinaCRS.GCJ02,
            show: true,
            // clampToGround: true,
          });
        }
      }

      // console.log(leftLayer, rightLayer);

      const mapSplit = new mars3d.control.MapSplit({
        leftLayer,
        rightLayer,
      });
      map.addControl(mapSplit);
      window.mapSplit = mapSplit;

      let addHTML = _this.$refs.allSelect;
      // console.log(addHTML)
      $(".mars3d-slider").eq(0).append(addHTML);
    };
    const switchTypeFun = (val) => {
      state.btn = val;
      if ([1, 2].includes(val)) {
        let mapOld = document.getElementById("mars3dContainer");
        if (window.mapSplit) map.removeControl(window.mapSplit);
        if (map.getBasemaps.length > 0) {
          map.getBasemaps().forEach((e) => {
            if (e.options.name == state.leftAllChoose[0].modelUrl) {
              e.show = true;
            } else {
              e.show = false;
            }
          });
        }

        setQP(false);
        setModel(false);
        if (val == 1) {
          if (window.earlyWarntiles3d) window.earlyWarntiles3d.remove();
          if (window.tileLayerWarn) window.tileLayerWarn.remove();
          mapOld.style.width = "100vw";
          initReformModel();
        } else if (val == 2) {
          mapOld.style.width = "50vw";
          if (state.leftAllChoose[1]) {
            if (state.leftAllChoose[1].modelType == "三维模型") {
              // console.log(val);
              let earlyWarntiles3d = new mars3d.layer.TilesetLayer({
                name: "分屏对比模型",
                url: state.leftAllChoose[1].modelUrl,
                // clampToGround: true,
              });
              window.earlyWarntiles3d = earlyWarntiles3d;
              map.addLayer(earlyWarntiles3d);
            } else {
              let tileLayer = new mars3d.layer.XyzLayer({
                url: state.leftAllChoose[1].modelUrl,
                hasZIndex: true,
                zIndex: 6,
                // name: "分屏对比切片",
                name: state.leftAllChoose[1].name,
              });
              window.tileLayerWarn = tileLayer;
              map.addLayer(tileLayer);
            }
          }

          let mapOptions2 = map.getCurrentOptions(); // map.getOptions()

          // 用于双屏同图层，不同配置展示
          // let layers = [globalData.earthArr[state.rightAllChoose[0].modelUrl]];
          let layers = [globalData.earthArr[state.rightAllChoose[0].modelUrl]];
          if (state.rightAllChoose[1]) {
            if (state.rightAllChoose[1].modelType == "三维模型") {
              layers.push({
                name: "分屏对比模型",
                url: state.rightAllChoose[1].modelUrl,
                // clampToGround: true,
                type: "3dtiles",
                show: true,
              });
            } else {
              layers.push({
                // name: "分屏对比切片",
                name: state.rightAllChoose[1].name,
                type: "xyz",
                url: state.rightAllChoose[1].modelUrl,
                show: true,
              });
            }
          }
          let options = _this.deepClone(mapOptions2);
          options.layers = layers;
          window.mapSplit = new mars3d.control.MapCompare({
            ...options,
            parentContainer: document.body,
          });
          map.addControl(window.mapSplit);
        }
      } else if (val == 3) {
        setQP(true);
        setModel(true);
        if (window.mapSplit) map.removeControl(window.mapSplit);
        let mapOld = document.getElementById("mars3dContainer");
        mapOld.style.width = "100vw";
      }
    };
    // 设置切片显隐
    const setQP = (val) => {
      if (window.singleTileLayer.length > 0) {
        window.singleTileLayer.forEach((e) => {
          e.show = val;
        });
      }
    };
    // 设置模型显隐
    const setModel = (val) => {
      if (window.singleTiles3dLayer.length > 0) {
        window.singleTiles3dLayer.forEach((e) => {
          e.show = val;
        });
      }
    };
    const switchSelect = () => {
      let mapOld = document.getElementById("mars3dContainer");
      if (window.mapSplit) map.removeControl(window.mapSplit);
      mapOld.style.width = "50vw";
      let mapOptions2 = map.getCurrentOptions(); // map.getOptions()
      console.log(mapOptions2);
      // 用于双屏同图层，不同配置展示
      let layers = [globalData.earthArr[state.rightAllChoose[0].modelUrl]];
      if (state.rightAllChoose[1]) {
        if (state.rightAllChoose[1].modelType == "三维模型") {
          layers.push({
            name: "分屏对比模型",
            url: state.rightAllChoose[1].modelUrl,
            // clampToGround: true,
            type: "3dtiles",
            show: true,
          });
        } else {
          layers.push({
            // name: "分屏对比切片",
            name: state.rightAllChoose[1].name,
            type: "xyz",
            url: state.rightAllChoose[1].modelUrl,
            show: true,
          });
        }
      }
      let options = _this.deepClone(mapOptions2);
      options.layers = layers;
      window.mapSplit = new mars3d.control.MapCompare({
        ...options,
        parentContainer: document.body,
      });
      map.addControl(window.mapSplit);
    };

    const drawWfs = (data) => {
      // console.log(data);
      let JsonLayer = new mars3d.layer.GeoJsonLayer({
        name: data.name,
        url: data.modelUrl,
        // format: simplifyGeoJSON, // 用于自定义处理geojson
        allowDrillPick: false,
        symbol: {
          merge: true,
          type: data.lineType ? data.lineType : "polygon",
          styleOptions:
            data.lineType == "polygon"
              ? {
                  fill: true,
                  color: data.backGround
                    ? data.backGround
                    : "rgba(211, 230, 148, 1)",
                  opacity: data.opacity ? data.opacity : 0.01,
                  outline: true,
                  outlineWidth: 2,
                  outlineColor: data.color
                    ? data.color
                    : "rgba(211, 230, 148, 1)",
                  materialType: "Color",
                  clampToGround: true,
                  outlineStyle:
                    data.line == "dashed"
                      ? {
                          width: 2,
                          color: data.color ? data.color : "#9D8AC5",
                          materialType: mars3d.MaterialType.PolylineDash,
                          materialOptions: {
                            color: data.color ? data.color : "#9D8AC5",
                            dashLength: 8,
                          },
                        }
                      : "",
                }
              : {
                  width: 1,
                  color: data.color ? data.color : "#9D8AC5",
                  clampToGround: true,
                },
        },
      });
      map.addLayer(JsonLayer);
      JsonLayer.show = data.checked && globalData.manageShowInMap;

      JsonLayer.on(mars3d.EventType.click, function (event) {
        graphicLayer.eachGraphic((graphic) => {
          if (
            graphic &&
            graphic.state == "added" &&
            (graphic.id == "图斑详情边界") != -1
          ) {
            graphic.remove(true);
          }
        });
        // console.log(event)
        http
          .detailByTbCode({
            tbCode: event.graphic.attr.OBJECTID
              ? event.graphic.attr.OBJECTID
              : event.graphic.attr.OID_,
            typeId: data.id,
          })
          .then((res) => {
            state.tbData = res.data;
            let position = event.graphic.centerPoint;
            let dom = _this.$refs.tbDetail;
            let shodow = _this.addDivGraphic(
              graphicLayer,
              dom,
              position,
              state.tbData.data,
              0,
              1000000,
              0,
              8
            );

            shodow.id = "图斑详情" + new Date().getTime();
            shodow.on(mars3d.EventType.click, function (event) {});
          });

        let position = event.cartesian;
        let ellipsoid = viewer.scene.globe.ellipsoid;
        let cartographic = ellipsoid.cartesianToCartographic(position);
        let filter =
          `<Filter xmlns="http://www.opengis.net/ogc" xmlns:gml="http://www.opengis.net/gml"><Intersects><PropertyName>the_geom</PropertyName><gml:Point><gml:coordinates>` +
          Cesium.Math.toDegrees(cartographic.longitude) +
          "," +
          Cesium.Math.toDegrees(cartographic.latitude) +
          `</gml:coordinates></gml:Point></Intersects></Filter>`;

        http.filterJson1({ filter, url: data.modelUrl }).then((res) => {
          if (res.features && res.features.length > 0) {
            let lineData = res.features[0].geometry.coordinates[0][0];
            // console.log(lineData);
            let Polygon = _this.addPolygonAction(
              graphicLayer,
              data,
              lineData,
              "#ffd700"
            );
            // let wall = _this.addScrollWall(graphicLayer, lineData, data);
            Polygon.flyTo({
              radius: 800,
              scale: 1.5,
            });
            Polygon.id = "图斑详情边界";
          }
        });
      });
      JsonLayer.readyPromise.then(function (layer) {
        JsonLayer.show = data.checked;
      });
      // JsonLayer.show = false;
      map.addLayer(JsonLayer);
    };

    const drawWms = (data) => {
      let url = data.wmsModelUrl.split("&version=")[0];
      let layers = data.wmsModelUrl.split("layers=")[1];
      layers = layers.split("&")[0];
      let parameters = {
        version: "1.1.0",
        transparent: true,
        format: "image/png",
        PNG: "8bit",
        tiled: true,
      };
      let JsonLayer = new mars3d.layer.WmsLayer({
        url,
        layers,
        name: data.name,
        parameters,
        zIndex: 11,
        enablePickFeatures: true,
        show: true,
        clampToTileset: true,
        PNG: "8bit",
        tiled: true,
        // cql_filter=QSDWMC+like+%27芹头村%27
        // saturation:0.1,
        // -----------
        hasToGraphic: false,
      });

      JsonLayer.readyPromise.then(function (layer) {
        JsonLayer.show = data.checked;
      });

      JsonLayer.on(
        mars3d.EventType.click,
        _this.Debounce(function (event) {
          // console.log(event);
          // state.tbData = event.attr;
          // event.layer.flyTo();
          // console.log(event)
          removeTbDetail();

          graphicLayer.eachGraphic((graphic) => {
            if (
              graphic &&
              graphic.state == "added" &&
              (graphic.id == "图斑详情边界") != -1
            ) {
              graphic.remove(true);
            }
          });

          let position = event.cartesian;
          let ellipsoid = viewer.scene.globe.ellipsoid;
          let cartographic = ellipsoid.cartesianToCartographic(position);
          let filter =
            `<Filter xmlns="http://www.opengis.net/ogc" xmlns:gml="http://www.opengis.net/gml"><Intersects><PropertyName>the_geom</PropertyName><gml:Point><gml:coordinates>` +
            Cesium.Math.toDegrees(cartographic.longitude) +
            "," +
            Cesium.Math.toDegrees(cartographic.latitude) +
            `</gml:coordinates></gml:Point></Intersects></Filter>`;

          http.filterJson1({ filter, url: data.modelUrl }).then((res) => {
            if (res.features && res.features.length > 0) {
              let lineData = res.features[0].geometry.coordinates[0][0];
              // console.log(lineData);
              let Polygon = _this.addPolygonAction(
                graphicLayer,
                data,
                lineData,
                "#ffd700"
              );
              // let wall = _this.addScrollWall(graphicLayer, lineData, data);
              Polygon.flyTo({
                radius: 800,
                scale: 1.5,
              });
              Polygon.id = "图斑详情边界";
            }
          });
          http
            .detailByTbCode({
              tbCode: event.attr.OBJECTID
                ? event.attr.OBJECTID
                : event.attr.OID_,
              typeId: data.id,
            })
            .then((res) => {
              state.tbData = res.data;

              let dom = _this.$refs.tbDetail;
              let shodow = _this.addDivGraphic(
                graphicLayer,
                dom,
                position,
                state.tbData.data,
                0,
                1000000,
                0,
                8
              );

              shodow.id = "图斑详情" + new Date().getTime();
              shodow.on(mars3d.EventType.click, function (event) {});
            });
        }, 1000)
      );

      map.addLayer(JsonLayer);
    };

    const showLines = () => {
      let JsonLayer = new mars3d.layer.GeoJsonLayer({
        name: "矢量图斑",
        url: `/json/1111.json`,
        symbol: {
          merge: true,
          type: "polygon",
          styleOptions: {
            fill: false,
            outline: true,
            outlineWidth: 2,
            outlineColor: "rgba(211, 230, 148, 1)",
            materialType: "Color",
            clampToGround: true,
            // 高亮时的样式
            highlight: {
              type: "click",
              opacity: 0.9,
            },
          },
        },
      });

      JsonLayer.show = false;
      map.addLayer(JsonLayer);
      // 单击事件
    };

    const getAllTb = () => {
      state.loading = ElLoading.service({
        lock: true,
        text: "正在加载中......",
        // spinner:'testspinner',
        background: "rgba(0, 0, 0, 0.7)",
      });

      http
        .getTypeInfo({
          projectId: globalData.projectId ? globalData.projectId : "",
          name: props.name,
        })
        .then((res) => {
          let arr = res.data;
          // state.treeData = res.data;

          globalData.chooseObj = Object.assign({}, res.data);
          for (let key in arr) {
            let bool = state.allKey.图斑.includes(key);
            if (bool) {
              state.loadNum += arr[key].children.length;
              arr[key].children.forEach((e) => {
                if (!e.checked) return;
                e.useModelType == 0 ? drawWfs(e) : drawWms(e);
                globalData.tbArr.push(e.name);
                globalData.tbTypes.push(e.type);
              });
            }
          }
        });
      // drawAllTb();
      showLines();
      setTimeout(() => {
        state.loading.close();
      }, 2000);
    };
    const getOtherTree = () => {
      http
        .treeBgOther({
          projectId: globalData.projectId ? globalData.projectId : "",
          name: props.name,
        })
        .then((res) => {
          globalData.otherObjs = res.data;
          state.allModelList = Object.values(res.data);
          for (let key in res.data) {
            if (state.allKey.影像.includes(key)) {
              res.data[key].children.forEach((e) => {
                if (e.checked) {
                  let tileLayer = new mars3d.layer.XyzLayer({
                    url: e.modelUrl,
                    hasZIndex: true,
                    zIndex: 6,
                    name: e.id,
                  });
                  tileLayer.show = globalData.manageShowInMap;
                  window.singleTileLayer.push(tileLayer);
                  map.addLayer(tileLayer);
                }
              });
            } else if (state.allKey.模型.includes(key)) {
              res.data[key].children.forEach((e) => {
                if (e.checked) {
                  let tiles3dLayer = new mars3d.layer.TilesetLayer({
                    name: e.id,

                    url: e.modelUrl,
                    // clampToGround: true,
                    Memory: 216,
                    // 额定显存大小(以字节为单位)，允许在这个值上下波动
                    cacheBytes: 536870912,
                    // 最大显存大小(以字节为单位)。
                    maximumCacheOverflowBytes: 536870912,
                    // 选用于驱动细化细节级别的最大屏幕空间错误。可以简单理解为：数值加大，能让最终成像变模糊。
                    maximumScreenSpaceError: 16,
                    // 在移动时选择请求时使用的倍增器。越大的选择性越强，越小的选择性越弱。值越小能够更快的剔除。
                    cullRequestsWhileMovingMultiplier: 60,
                    // 优化选择。当摄像机在飞行时，在摄像机的飞行目的地预加载贴图。
                    preloadFlightDestinations: true,
                    // 可选优化选择。确定在遍历过程中是否应应用跳过详细信息的级别。
                    skipLevelOfDetail: false,

                    // flyTo:true
                  });
                  tiles3dLayer.show = globalData.manageShowInMap;

                  window.singleTiles3dLayer.push(tiles3dLayer);
                  map.addLayer(tiles3dLayer);
                }
              });
            }
          }
        });
    };

    const showNewTb = (val) => {
      // console.log(val,111111);
      state.loading = ElLoading.service({
        lock: true,
        text: "正在加载中......",
        // spinner:'testspinner',
        background: "rgba(0, 0, 0, 0.7)",
      });
      // 单选
      if (val.children.length == 0) {
        if (val.isCheckChildren) {
          // t.show = val.checked;
          if (val.checked) {
            val.useModelType == 0 ? drawWfs(val) : drawWms(val);
          } else {
            let t = map.getLayer(val.name, "name");
            if (t) map.removeLayer(t, true);
          }

          if (val.checked && !globalData.tbArr.includes(val.name)) {
            globalData.tbArr.push(val.name);
            globalData.tbTypes.push(val.type);
          } else if (!val.checked && globalData.tbArr.includes(val.name)) {
            let idx = globalData.tbArr.indexOf(val.name);
            if (idx >= 0) {
              globalData.tbArr.splice(idx, 1);
              globalData.tbTypes.splice(idx, 1);
            }
          }
        } else {
          globalData.tbArr.forEach((e, idx) => {
            if (globalData.tbTypes[idx] == val.type) {
              let o = map.getLayer(e, "name");
              // o.show = false;
              if (o) map.removeLayer(o, true);
              globalData.tbArr.splice(idx, 1);
              globalData.tbTypes.splice(idx, 1);
            }
          });

          // let t = map.getLayer(val.name, "name");
          // t.show = val.checked;
          if (val.checked) {
            val.useModelType == 0 ? drawWfs(val) : drawWms(val);
          } else {
            let t = map.getLayer(val.name, "name");
            if (t) map.removeLayer(t, true);
          }

          val.checked ? globalData.tbArr.push(val.name) : "";
          val.checked ? globalData.tbTypes.push(val.type) : "";
        }
      } else {
        // 全选
        if (val.isAllCheckChildren) {
          val.children.forEach((e) => {
            // let t = map.getLayer(e.name, "name");
            // t.show = val.checked;

            if (val.checked) {
              e.useModelType == 0 ? drawWfs(e) : drawWms(e);
            } else {
              let t = map.getLayer(e.name, "name");
              if (t) map.removeLayer(t, true);
            }

            if (val.checked && !globalData.tbArr.includes(e.name)) {
              globalData.tbArr.push(e.name);
              globalData.tbTypes.push(e.type);
            } else if (!val.checked && globalData.tbArr.includes(e.name)) {
              let idx = globalData.tbArr.indexOf(e.name);
              if (idx >= 0) {
                globalData.tbArr.splice(idx, 1);
                globalData.tbTypes.splice(idx, 1);
              }
            }
          });
        } else {
          console.log("什么都不做");
        }
      }
      // console.log(globalData.tbArr);
      setTimeout(() => {
        state.loading.close();
      }, 1000 + Math.random() * 3000);
    };

    //  wfs  wms  以及正射  模型  卫星地图等
    watch(
      () => globalData.singleObj,
      (val) => {
        if (globalData.clickType != 2 || !val) return;
        // alert(11111)
        let type = val.type;
        if (!val.isMultipleOptions) {
          for (let key in globalData.chooseObj) {
            if (![val.type].includes(key)) {
              if (state.allKey.图斑.includes(key)) {
                let data = globalData.chooseObj[key].children;
                data.forEach((e) => {
                  let o = map.getLayer(e.name, "name");
                  if (o) map.removeLayer(o, true);
                });
              }
              if (!state.allKey.图斑.includes(key)) {
                graphicLayer.eachGraphic((graphic) => {
                  if (
                    graphic &&
                    graphic.state == "added" &&
                    graphic.id.indexOf(globalData.chooseObj[key].name) != -1
                  ) {
                    graphic.remove(true);
                  }
                });
              }
            }
          }
          globalData.wmsObj = false;
        }

        if (state.allKey.地图.includes(type)) {
          if (
            val.name == "卫星地图" &&
            val.parentId == 0 &&
            val.isAllCheckChildren
          ) {
            if (val.checked) {
              let arr = val.children.map((e) => {
                return e.name;
              });
              map.getBasemaps().forEach((e) => {
                if (arr.includes(e.options.name)) {
                  e.show = true;
                } else {
                  e.show = false;
                }
              });
            } else {
              map.getBasemaps().forEach((e) => {
                if (e.options.name == "天地图影像") {
                  e.show = true;
                } else {
                  e.show = false;
                }
              });
            }
          }

          // 单选  可复选
          if (val.isCheckChildren) {
            map.getBasemaps().forEach((e) => {
              if (e.options.name == val.name) {
                e.show = val.checked;
              }
            });
            let bool = false;
            map.getBasemaps().forEach((e) => {
              if (e.show) {
                bool = e.show;
              }
            });

            if (!bool) {
              map.getBasemaps().forEach((e) => {
                if (e.options.name == "天地图影像") {
                  e.show = true;
                }
              });
            }
          } else {
            // 单选  不可复选
            if (val.checked) {
              map.getBasemaps().forEach((e) => {
                if (e.options.name == val.name) {
                  e.show = true;
                } else {
                  e.show = false;
                }
              });
            } else {
              // 因为不可复选   所以为false的时候 说明全部都是false  就默认显示天地图
              map.getBasemaps().forEach((e) => {
                if (e.options.name == "天地图影像") {
                  e.show = true;
                }
              });
            }
          }
        } else if (state.allKey.影像.includes(type)) {
          // 全选
          if (val.parentId == 0 && val.isAllCheckChildren) {
            if (window.singleTileLayer.length > 0) {
              window.singleTileLayer.forEach((e) => {
                e.remove();
              });
              window.singleTileLayer = [];
            }
            if (val.checked) {
              val.children.forEach((e) => {
                let tileLayer = new mars3d.layer.XyzLayer({
                  url: e.modelUrl,
                  hasZIndex: true,
                  zIndex: 6,
                  name: e.id,
                });
                window.singleTileLayer.push(tileLayer);
                map.addLayer(tileLayer);
                tileLayer.show = globalData.manageShowInMap;
              });
            }
          } else {
            if (val.isCheckChildren) {
              //单选
              if (window.singleTileLayer.length > 0) {
                window.singleTileLayer.forEach((e, i) => {
                  if (e.name == val.id) {
                    e.remove();
                    window.singleTileLayer.splice(i, 1);
                  }
                });
              }
            } else {
              if (window.singleTileLayer.length > 0) {
                window.singleTileLayer.forEach((e) => {
                  e.remove();
                });
                window.singleTileLayer = [];
              }
            }
            if (!val.checked) return;

            let tileLayer = new mars3d.layer.XyzLayer({
              url: val.modelUrl,
              hasZIndex: true,
              zIndex: 6,
              name: val.id,
              flyTo: true,
            });
            window.singleTileLayer.push(tileLayer);

            map.addLayer(tileLayer);
            tileLayer.show = globalData.manageShowInMap;
          }
        } else if (state.allKey.模型.includes(type)) {
          console.log(val.parentId);
          console.log(val.isAllCheckChildren);
          if (val.parentId == 0 && val.isAllCheckChildren) {
            if (window.singleTiles3dLayer.length > 0) {
              window.singleTiles3dLayer.forEach((e) => {
                e.remove();
              });
              window.singleTiles3dLayer = [];
            }
            if (val.checked) {
              val.children.forEach((e) => {
                let tiles3dLayer = new mars3d.layer.TilesetLayer({
                  name: e.id,

                  url: e.modelUrl,
                  // clampToGround: true,
                  Memory: 216,
                  // 额定显存大小(以字节为单位)，允许在这个值上下波动
                  cacheBytes: 536870912,
                  // 最大显存大小(以字节为单位)。
                  maximumCacheOverflowBytes: 536870912,
                  // 选用于驱动细化细节级别的最大屏幕空间错误。可以简单理解为：数值加大，能让最终成像变模糊。
                  maximumScreenSpaceError: 16,
                  // 在移动时选择请求时使用的倍增器。越大的选择性越强，越小的选择性越弱。值越小能够更快的剔除。
                  cullRequestsWhileMovingMultiplier: 60,
                  // 优化选择。当摄像机在飞行时，在摄像机的飞行目的地预加载贴图。
                  preloadFlightDestinations: true,
                  // 可选优化选择。确定在遍历过程中是否应应用跳过详细信息的级别。
                  skipLevelOfDetail: false,

                  // flyTo:true
                });

                tiles3dLayer.show = globalData.manageShowInMap;
                window.singleTiles3dLayer.push(tiles3dLayer);
                map.addLayer(tiles3dLayer);
              });
            }
          } else {
            if (val.isCheckChildren) {
              if (window.singleTiles3dLayer.length > 0) {
                window.singleTiles3dLayer.forEach((e, i) => {
                  if (e.name == val.id) {
                    e.remove();
                    window.singleTileLayer.splice(i, 1);
                  }
                });
              }
            } else {
              if (window.singleTiles3dLayer.length > 0) {
                window.singleTiles3dLayer.forEach((e) => {
                  e.remove();
                });
                window.singleTiles3dLayer = [];
              }
            }

            if (!val.checked) return;
            let tiles3dLayer = new mars3d.layer.TilesetLayer({
              name: val.id,

              url: val.modelUrl,
              // clampToGround: true,
              Memory: 216,
              // 额定显存大小(以字节为单位)，允许在这个值上下波动
              cacheBytes: 536870912,
              // 最大显存大小(以字节为单位)。
              maximumCacheOverflowBytes: 536870912,
              // 选用于驱动细化细节级别的最大屏幕空间错误。可以简单理解为：数值加大，能让最终成像变模糊。
              maximumScreenSpaceError: 16,
              // 在移动时选择请求时使用的倍增器。越大的选择性越强，越小的选择性越弱。值越小能够更快的剔除。
              cullRequestsWhileMovingMultiplier: 60,
              // 优化选择。当摄像机在飞行时，在摄像机的飞行目的地预加载贴图。
              preloadFlightDestinations: true,
              // 可选优化选择。确定在遍历过程中是否应应用跳过详细信息的级别。
              skipLevelOfDetail: false,

              // flyTo:true
            });
            tiles3dLayer.show = globalData.manageShowInMap;
            window.singleTiles3dLayer.push(tiles3dLayer);
            map.addLayer(tiles3dLayer);
          }
        } else if (state.allKey.图斑.includes(type)) {
          showNewTb(val);
        }
      },
      {
        deep: true,
      }
    );
    //  border画的边界线    房建   项目用地  两违  在建房屋  等
    watch(
      () => globalData.wmsObj,
      async (obj, old) => {
        if (globalData.clickType != 1 || !obj) return;

        // if (Object.keys(state.treeData).includes(obj.name)) {
        if (state.allKey.非图斑.includes(obj.name)) {
          // isMultipleOptions;
          graphicLayer.eachGraphic((graphic) => {
            if (
              graphic &&
              graphic.state == "added" &&
              graphic.id.indexOf(obj.name) != -1
            ) {
              graphic.remove(true);
            }
            if (
              (!obj.isMultipleOptions || !old.isMultipleOptions) &&
              graphic &&
              graphic.state == "added" &&
              graphic.id.indexOf(old.name) != -1
            ) {
              graphic.remove(true);
            }
            if (globalData.ftbArr[obj.name]) {
              globalData.ftbArr[obj.name] = [];
            }
          });

          if (!obj.isMultipleOptions || !old.isMultipleOptions) {
            for (const key in globalData.chooseObj) {
              if (state.allKey.图斑.includes(key)) {
                let data = globalData.chooseObj[key].children;
                data.forEach((e) => {
                  e.checked = false;
                  let o = map.getLayer(e.name, "name");
                  if (o) map.removeLayer(0, true);
                });
              }
            }
            globalData.tbArr = [];
            globalData.tbTypes = [];
            globalData.singleObj = false;
          }

          if (!obj.checked) return;
          // console.log()
          obj.data.forEach((e) => {
            if (e.border) {
              let polygon;
              polygon = _this.addPolygon(
                graphicLayer,
                e,
                JSON.parse(e.border),
                "",
                0,
                obj.markStatus ? 5000 : 50000000
              );

              polygon.id =
                "图斑列表点" +
                e.type +
                e.id +
                "边线" +
                e.type +
                new Date().getTime();
              polygon.show = globalData.manageShowInMap;
              polygon.on(mars3d.EventType.click, function (event) {
                getCameraInfo(e, false); // bool是否显示图标
              });
              globalData.ftbArr[e.type].push(polygon);
            }

            if (e.position && obj.markStatus) {
              let po = JSON.parse(e.position);
              let dom = `<div class="tbPinBox" data-id="${e.id}">
                  <div class="divFlex alignCenter">
                    <div class="relative">
                      <div class="icon">
                        <div class="child1" style="border-color:${e.color}"></div>
                        <div class="child2"></div>
                      </div>
                      <div class="biDiv" style="background:${e.color}" ></div>
                      <div class="biDiv2" style="background:${e.color}"></div>
                    </div>

                  </div>
                </div>`;
              let dom1 = `<div class="tbPinBox" data-id="${e.id}">
                  <div class="divFlex alignCenter" >
                    <div class="pinName relative">
                      ${e.name}
                      <div class="pinName1">
                        ${e.name}
                    </div>
                    </div>
                  </div>
                </div>`;
              let shodow = _this.addDivGraphic(
                graphicLayer,
                dom,
                po,
                e,
                0,
                10000000,
                -10,
                8,
                true,
                Cesium.HorizontalOrigin.LEFT,
                Cesium.VerticalOrigin.BOTTOM,
                -10
              );
              shodow.on(mars3d.EventType.mouseOver, function (event) {
                let len = $(".tbPinBox").length;
                for (let i = 0; i < len; i++) {
                  if ($(".tbPinBox").eq(i).attr("data-id") == e.id) {
                    $(".tbPinBox").eq(i).find(".icon").css({
                      border: "7px solid white",
                    });
                    $(".tbPinBox").eq(i).find(".pinName1").css({
                      color: e.color,
                    });
                    $(".tbPinBox").eq(i).find(".biDiv").css({
                      background: e.color,
                      border: "1px solid white",
                    });
                    // $(".tbPinBox").eq(i).find(".biDiv1").css({
                    //   background: e.color,
                    //   border: "1px solid white",
                    // });
                  }
                }
              });

              shodow.on(mars3d.EventType.mouseOut, function (event) {
                let len = $(".tbPinBox").length;
                for (let i = 0; i < len; i++) {
                  if ($(".tbPinBox").eq(i).attr("data-id") == e.id) {
                    $(".tbPinBox").eq(i).find(".icon").css({
                      border: "1px solid rgba(0, 0, 0, 0.5)",
                    });
                    $(".tbPinBox").eq(i).find(".pinName1").css({
                      color: "black",
                    });
                    $(".tbPinBox").eq(i).find(".biDiv").css({
                      border: "1px solid rgba(0, 0, 0, 0.5)",
                    });
                    // $(".tbPinBox").eq(i).find(".biDiv1").css({
                    //   border: "1px solid rgba(0, 0, 0, 0.5)",
                    // });
                  }
                }
              });

              shodow.on(mars3d.EventType.click, function (event) {
                getCameraInfo(e, false); // bool是否显示图标
              });

              shodow.id =
                "图斑列表点" +
                e.type +
                e.id +
                "边线" +
                e.type +
                new Date().getTime();
              let shodow1 = _this.addDivGraphic(
                graphicLayer,
                dom1,
                po,
                e,
                0,
                10000,
                -10,
                8,
                true,
                Cesium.HorizontalOrigin.LEFT,
                Cesium.VerticalOrigin.BOTTOM,
                10
              );
              shodow1.id =
                "图斑列表点" +
                e.type +
                e.id +
                "边线" +
                e.type +
                new Date().getTime() +
                Math.random();

              shodow1.show = globalData.manageShowInMap;
              shodow.show = globalData.manageShowInMap;
              shodow1.on(mars3d.EventType.click, function (event) {
                getCameraInfo(e, false); // bool是否显示图标
              });

              shodow1.on(mars3d.EventType.mouseOver, function (event) {
                let len = $(".tbPinBox").length;
                for (let i = 0; i < len; i++) {
                  if ($(".tbPinBox").eq(i).attr("data-id") == e.id) {
                    $(".tbPinBox").eq(i).find(".icon").css({
                      border: "7px solid white",
                    });
                    $(".tbPinBox").eq(i).find(".pinName1").css({
                      color: e.color,
                    });
                    $(".tbPinBox").eq(i).find(".biDiv").css({
                      background: e.color,
                      border: "1px solid white",
                    });
                    // $(".tbPinBox").eq(i).find(".biDiv1").css({
                    //   background: e.color,
                    //   border: "1px solid white",
                    // });
                  }
                }
              });

              shodow1.on(mars3d.EventType.mouseOut, function (event) {
                let len = $(".tbPinBox").length;
                for (let i = 0; i < len; i++) {
                  if ($(".tbPinBox").eq(i).attr("data-id") == e.id) {
                    $(".tbPinBox").eq(i).find(".icon").css({
                      border: "1px solid rgba(0, 0, 0, 0.5)",
                    });
                    $(".tbPinBox").eq(i).find(".pinName1").css({
                      color: "black",
                    });
                    $(".tbPinBox").eq(i).find(".biDiv").css({
                      border: "1px solid rgba(0, 0, 0, 0.5)",
                    });
                    // $(".tbPinBox").eq(i).find(".biDiv1").css({
                    //   border: "1px solid rgba(0, 0, 0, 0.5)",
                    // });
                  }
                }
              });

              globalData.ftbArr[e.type].push(shodow1, shodow);
            }
          });
        } else {
          let tileLayer2 = map.getLayer(obj.name, "name");
          if (tileLayer2) tileLayer2.show = obj.value;
          // initReformModel()
        }

        if (state.btn == 2) {
          switchSelect();
        }
      },
      {
        deep: true,
      }
    );

    watch(
      () => state.leftValue,
      async (value) => {
        if (!value) return;

        let val = state.leftInfo;
        console.log(val);
        if (state.btn == 1) {
          if (val.modelType != "卫星地图") {
            state.leftAllChoose[1] = val;
          } else {
            state.leftAllChoose[0] = val;
          }

          initReformModel();
        } else {
          if (val.modelType == "卫星地图") {
            state.leftAllChoose[0] = val;
            map.getBasemaps().forEach((e) => {
              if (e.options.name == val.modelUrl) {
                e.show = true;
              } else {
                e.show = false;
              }
            });
            // switchSelect(2);
          } else {
            if (window.earlyWarntiles3d) window.earlyWarntiles3d.remove();
            if (window.tileLayerWarn) window.tileLayerWarn.remove();
            state.leftAllChoose[1] = val;
            if (val.modelType == "三维模型") {
              // console.log(val);
              let earlyWarntiles3d = new mars3d.layer.TilesetLayer({
                name: "分屏对比模型",
                url: val.modelUrl,
                // clampToGround: true,
              });
              window.earlyWarntiles3d = earlyWarntiles3d;
              map.addLayer(earlyWarntiles3d);
              // switchSelect(2);
            } else {
              let tileLayer = new mars3d.layer.XyzLayer({
                url: val.modelUrl,
                hasZIndex: true,
                zIndex: 6,
                // name: "分屏对比切片",
                name: val.name,
              });
              window.tileLayerWarn = tileLayer;
              map.addLayer(tileLayer);
              // switchSelect(2);
            }
          }
        }
      },
      {
        deep: true,
      }
    );

    watch(
      () => state.rightValue,
      async (value) => {
        if (!value) return;
        let val = state.rightInfo;
        if (state.btn == 1) {
          if (val.modelType != "卫星地图") {
            state.rightAllChoose[1] = val;
          } else {
            state.rightAllChoose[0] = val;
          }
          initReformModel();
        } else {
          if (val.modelType == "三维模型" || val.modelType == "正射影像") {
            state.rightAllChoose[1] = val;
          } else if (val.modelType == "卫星地图") {
            state.rightAllChoose[0] = val;
          }
          switchSelect();
        }
      },
      {
        deep: true,
      }
    );

    watch(
      () => props.name,
      async () => {
        graphicLayer.clear();
        getAllTb();
        getOtherTree();
      },
      {
        deep: true,
      }
    );

    // -------------两违编辑器部分------------
    const handleClear2 = () => {
      state.gridInfo = globalData.chooseBtns.所属网格[0];
      state.lwOption.grid = globalData.chooseBtns.所属网格[0].title;
      _this.$refs.selectUpResId2.blur();
    };

    const handleNodeClick2 = (data) => {
      state.gridInfo = data;
      state.lwOption.grid = data.title;
      _this.$refs.selectUpResId2.blur();
    };

    const handleCut = (data) => {
      if (data.children && data.children.length > 0) return;
      state.leftInfo = data;
      state.leftValue = data.modelUrl;
      _this.$refs.leftSelect.blur();
      _this.$refs.leftSelect1.blur();
    };

    const handleCut1 = (data) => {
      if (data.children && data.children.length > 0) return;
      state.rightInfo = data;
      state.rightValue = data.modelUrl;
      _this.$refs.rightSelect.blur();
      _this.$refs.rightSelect1.blur();
    };

    const showLw = (val) => {
      state.lwShow = val;
    };
    const startDrawGraphic = () => {
      if (state.lwOption.position) {
        return _this.$message({
          message: "定位已存在，请在地图上右键开启编辑！",
          type: "warning",
        });
      }
      let graphic = rightGraphicLayer.startDraw({
        type: "billboardP",
        hasEdit: true,
        style: {
          image: _this.getViteUrl("../assets/xinan/疑似两违.png"),
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          label: {
            text: state.lwOption.name,
            font_size: 16,
            color: "#ffffff",
            pixelOffsetY: -80,
            distanceDisplayCondition: true,
            distanceDisplayCondition_far: 500000000,
            distanceDisplayCondition_near: 0,
          },
        },
        success: (graphic) => {
          graphic.id = "graphic";
          console.log(graphic);
          state.lwOption.position = graphic.coordinates[0];
          graphic.bindContextMenu([
            {
              text: "开始编辑对象",
              icon: "fa fa-edit",
              show: function (e) {
                const graphic = e.graphic;
                if (!graphic || !graphic.hasEdit) {
                  return false;
                }
                return !graphic.isEditing;
              },
              callback: (e) => {
                const graphic = e.graphic;
                if (!graphic) {
                  return false;
                }
                if (graphic) {
                  rightGraphicLayer.startEditing(graphic);
                }
              },
            },
            // {
            //   text: "停止编辑对象",
            //   icon: "fa fa-edit",
            //   show: function (e) {
            //     console.log(e);
            //     const graphic = e.graphic;
            //     if (!graphic || !graphic.hasEdit) {
            //       return false;
            //     }
            //     return graphic.isEditing;
            //   },
            //   callback: (e) => {
            //     console.log(e);
            //     const graphic = e.graphic;
            //     console.log(graphic);
            //     if (!graphic) {
            //       return false;
            //     }
            //     if (graphic) {
            //       // state.lwOption.position = graphic.coordinates;
            //       graphic.stopEditing();
            //     }
            //   },
            // },
            {
              text: "停止编辑对象",
              icon: "fa fa-trash-o",
              callback: (e) => {
                const graphic = e.graphic;
                if (graphic) {
                  // console.log(graphic)
                  state.lwOption.position = graphic.coordinates[0];
                  graphic.stopEditing();
                }
              },
            },
          ]);
        },
        updateDrawPosition: (graphic) => {
          // console.log(graphic, 111111);
          state.lwOption.position = graphic.coordinates[0];
        },
      });
    };

    const startDrawPolygon = () => {
      if (state.lwOption.border) {
        return _this.$message({
          message: "区域已存在，请在地图上右键开启编辑！",
          type: "warning",
        });
      }
      let polygonP = rightGraphicLayer.startDraw({
        type: "polygonP",
        hasEdit: true,
        style: {
          color: "rgba(255, 136, 0, 1)",
          opacity: 0,
          outline: true,
          clampToGround: true,
          // diffHeight: 300,
          outlineStyle: {
            width: 4,
            color: state.lwOption.color,
            opacity: 1,
          },
        },
        success: (graphic) => {
          console.log(graphic);
          setTimeout(() => {
            console.log(graphic);
          }, 800);
          graphic.id = "polygonP" + new Date().getTime();
          window.drawPolygon = graphic;
          state.lwOption.border = graphic.coordinates;
          // let center = _this.getCenterPoint(graphic.coordinates);
          let { lng, lat, alt } = graphic.centerPoint;
          // let center = {}graphic.centerPoint];
          // console.log(center);
          state.lwOption.position = {
            lng,
            lat,
            alt,
          };

          let idx = state.predefineColors.indexOf(state.lwOption.color);
          idx = idx != 1 ? idx + 1 : 1;
          let img1 = _this.getViteUrl("../assets/images/t" + idx + ".png");
          let primitive = _this.addBillboardPrimitive(
            rightGraphicLayer,
            state.lwOption.position,
            img1,
            {},
            true,
            {
              text: state.lwOption.name,
              font_size: 16,
              color: "#ffffff",
              pixelOffsetY: -80,
              distanceDisplayCondition: true,
              distanceDisplayCondition_far: 500000000,
              distanceDisplayCondition_near: 0,
            }
          );

          // let dom = `<div class="tbPinBox">
          //     <div class="divFlex alignCenter">
          //       <div class="relative">
          //         <div class="icon">
          //           <div class="child1" style="border-color:${state.lwOption.color};"></div>
          //           <div class="child2"></div>
          //         </div>
          //         <div class="biDiv" style="background:${state.lwOption.color};"></div>
          //       </div>
          //       </div>
          //     </div>
          //   </div>`;
          // let primitive = _this.addDivGraphic(
          //   rightGraphicLayer,
          //   dom,
          //   state.lwOption.position,
          //   {},
          //   0,
          //   10000000,
          //   2,
          //   18,
          //   true,
          //   Cesium.HorizontalOrigin.CENTER,
          //   Cesium.VerticalOrigin.BOTTOM,
          //   -10
          // );
          // primitive.hasEdit = true;

          primitive.on(mars3d.EventType.click, function (event) {});
          primitive.bindContextMenu([
            {
              text: "开始编辑对象",
              icon: "fa fa-edit",
              show: function (e) {
                const graphic = e.graphic;
                if (!graphic || !graphic.hasEdit) {
                  return false;
                }
                return !graphic.isEditing;
              },
              callback: (e) => {
                const graphic = e.graphic;
                if (!graphic) {
                  return false;
                }
                if (graphic) {
                  rightGraphicLayer.startEditing(graphic);
                }
              },
            },
            // {
            //   text: "停止编辑对象",
            //   icon: "fa fa-edit",
            //   show: function (e) {
            //     console.log(e);
            //     const graphic = e.graphic;
            //     if (!graphic || !graphic.hasEdit) {
            //       return false;
            //     }
            //     return graphic.isEditing;
            //   },
            //   callback: (e) => {
            //     console.log(e);
            //     const graphic = e.graphic;
            //     console.log(graphic);
            //     if (!graphic) {
            //       return false;
            //     }
            //     if (graphic) {
            //       // state.lwOption.position = graphic.coordinates;
            //       graphic.stopEditing();
            //     }
            //   },
            // },
            {
              text: "停止编辑对象",
              icon: "fa fa-trash-o",
              callback: (e) => {
                const graphic = e.graphic;
                if (graphic) {
                  // console.log(graphic)
                  state.lwOption.position = graphic.coordinates[0];
                  graphic.stopEditing();
                }
              },
            },
          ]);
          // 面筛选
          // `<Filter xmlns="http://www.opengis.net/ogc" xmlns:gml="http://www.opengis.net/gml"><Intersects><PropertyName>GEOM</PropertyName><gml:Polygon><gml:outerBoundaryIs><gml:LinearRing><gml:coordinates>113.763,34.435 113.763,34.5 113.763,34.435</gml:coordinates></gml:LinearRing></gml:outerBoundaryIs></gml:Polygon></Intersects></Filter>`
          // 点筛选
          let filter =
            `<Filter xmlns="http://www.opengis.net/ogc" xmlns:gml="http://www.opengis.net/gml"><Intersects><PropertyName>the_geom</PropertyName><gml:Point><gml:coordinates>` +
            graphic.centerPoint.lng +
            "," +
            graphic.centerPoint.lat +
            `</gml:coordinates></gml:Point></Intersects></Filter>`;
          http.filterJson({ filter }).then((res) => {
            if (res.features.length > 0) {
              state.lwOption.grid = res.features[0].properties.村名称;
              let arr = globalData.chooseBtns.所属网格[0].children;
              let obj = arr.find((e) => {
                return e.title == state.lwOption.grid;
              });
              // console.log(obj)
              state.lwOption.gridId = obj.id;
            } else {
              _this.$message({
                message: "未找到对应乡镇信息，请确认区域信息是否正确！！！",
                type: "warning",
              });
            }
          });
          state.lwOption.area = graphic.area.toFixed(0) + "平方米";
          graphic.bindContextMenu([
            {
              text: "开始编辑对象",
              icon: "fa fa-edit",
              show: function (e) {
                const graphic = e.graphic;
                if (!graphic || !graphic.hasEdit) {
                  return false;
                }
                return !graphic.isEditing;
              },
              callback: (e) => {
                const graphic = e.graphic;
                if (!graphic) {
                  return false;
                }
                if (graphic) {
                  rightGraphicLayer.startEditing(graphic);
                }
              },
            },
            {
              text: "停止编辑对象",
              icon: "fa fa-trash-o",
              callback: (e) => {
                const graphic = e.graphic;
                if (graphic) {
                  // console.log(graphic)
                  state.lwOption.area =
                    (graphic.area * 0.0001).toFixed(0) + "公顷";
                  state.lwOption.border = graphic.coordinates;
                  graphic.stopEditing();
                }
              },
            },
          ]);
        },
      });
    };
    const createdLw = () => {
      if (!state.lwOption.name) {
        return _this.$message({
          message: "请填写" + state.lwShow + "名称",
          type: "warning",
        });
      }
      state.lwOption.type = state.lwShow;
      let obj = Object.assign({}, state.lwOption);
      obj.views = JSON.stringify(obj.views);
      obj.border = JSON.stringify(obj.border);
      obj.position = JSON.stringify(obj.position);
      http.subSimplex(obj).then((res) => {
        closeLw();
        // console.log(globalData.chooseObj)
        globalData.chooseObj[res.data.type].data.push(res.data);
        if (res.data.border) {
          let polygon = _this.addPolygon(
            graphicLayer,
            res.data,
            JSON.parse(res.data.border)
          );
          polygon.show = false;
          polygon.id = "图斑列表点" + res.data.id + "边线" + Math.random();
        }
        globalData.createdLw += 1; //左边列表监听重新获取
        _this.$message({
          message: res.msg,
          type: "success",
        });
      });
    };
    const pickViews = () => {
      state.lwOption.views = map.getCameraView();
    };

    const closeLw = (bool) => {
      // if (bool) {
      state.lwShow = false;
      // }
      rightGraphicLayer.clear();
      state.lwOption = {
        name: "",
        area: "",
        views: "",
        border: "",
        grid: "",
        position: "",
        type: "",
      };
    };

    const clearViews = () => {
      state.lwOption.views = "";
    };

    const clearArea = () => {
      state.lwOption.border = "";
      state.lwOption.area = "";
      rightGraphicLayer.eachGraphic((graphic) => {
        console.log(graphic.id);
        if (
          graphic &&
          graphic.state == "added" &&
          graphic.id.indexOf("polygonP") != -1
        ) {
          graphic.remove();
        }
      });
    };

    const clearPos = () => {
      state.lwOption.position = "";
      rightGraphicLayer.eachGraphic((graphic) => {
        console.log(graphic.id);
        if (
          graphic &&
          graphic.state == "added" &&
          graphic.id.indexOf("graphic") != -1
        ) {
          graphic.remove();
        }
      });
    };

    const removeTbDetail = () => {
      graphicLayer.eachGraphic((graphic) => {
        if (
          graphic &&
          graphic.state == "added" &&
          graphic.id.indexOf("图斑详情") != -1
        ) {
          graphic.remove(true);
        }
      });
    };
    const showTbArr = (bool) => {
      // console.log(globalData.tbArr);
      globalData.tbArr.forEach((e) => {
        let a = map.getLayer(e, "name");
        if (a) a.show = bool;
      });
    };
    const closeTbDetail = _this.Throttle(() => {
      removeTbDetail();
      // state.nowGraphic.setStyle({
      //   outlineColor: state.tbData.outlineColor,
      // });
      state.tbData = false;
      _this.flyHome();
    });

    const getAllKey = async () => {
      await http.getDict().then((res) => {
        state.allKey = res.data;
        if (state.allKey.非图斑) {
          state.allKey.非图斑.forEach((e) => {
            globalData.ftbArr[e] = [];
          });
        }
      });
    };
    const getCameraInfo = async (val, bool = true) => {
      // console.log(val);
      graphicLayer.eachGraphic((graphic) => {
        if (
          graphic &&
          graphic.state == "added" &&
          graphic.id.indexOf("列表点详情") != -1
        ) {
          graphic.show = false;
        }
        if (
          graphic &&
          graphic.state == "added" &&
          graphic.id.indexOf("边线fromlist") != -1
        ) {
          graphic.remove(true);
        }
      });
      state.showIcon = bool;
      if (state.cameraId == val.id || !val) {
        state.cameraId = false;
        state.detailInfo = false;
        _this.flyHome();
        return;
      }
      state.cameraId = val.id;
      let nowpolygon;
      http.simplexDetail({ id: val.id }).then((res) => {
        state.detailInfo = res.data;
        if (!val.position) {
          _this.$message({
            message: "该图斑暂无位置信息！！！！",
            type: "warning",
          });
        }

        if (res.data.border) {
          let border = JSON.parse(res.data.border);
          nowpolygon = _this.addPolygon(graphicLayer, res.data, border);
          nowpolygon.id = "图斑列表点" + val.type + val.id + "边线fromlist";
        }
        if (res.data.position) {
          let pos = JSON.parse(res.data.position);
          let dom = _this.$refs.detectionDetail;
          let shodow = _this.addDivGraphic(
            graphicLayer,
            dom,
            pos,
            state.detailInfo,
            0,
            1000000,
            -9,
            8,
            true
          );

          shodow.id = "列表点详情" + state.detailInfo.id;
          shodow.on(mars3d.EventType.click, function (event) {});

          // _this.addBillboardPrimitive(graphicLayer,pos,_this.getViteUrl('../assets/images/t3.png',{}))
        }

        // }
        if (val.views) {
          map.setCameraView(JSON.parse(val.views));
        } else if (val.position) {
          map.flyToPoint(JSON.parse(val.position), {
            radius: Math.floor(Math.random() * (500 - 100 + 1) + 400), // 距离目标点的距离
            duration: 2,
          });
        } else if (nowpolygon) {
          map.flyToGraphic(nowpolygon, {
            minHeight: 50,
          });
        }
      });
    };

    // ----------------------------------------------------
    // 画点  线  面   圆
    const drawPoint = () => {
      let img = _this.getViteUrl("../assets/images/l1.png");
      let html = `<div class="divFlex alignCenter tbPinBox">
                    <img src="${img}" />
                    <div class="pinName relative">
                      测试
                      <div class="pinName1">
                        测试
                    </div>
                </div>`;
      let graphic = rightGraphicLayer.startDraw({
        type: "div",
        hasEdit: true,
        style: {
          // image: _this.getViteUrl("../assets/images/l1.png"),
          html,
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          clampToGround: true,
        },
        success: (graphic) => {
          graphic.id = "graphic";
          state.点 = graphic.coordinates[0];
          graphic.bindContextMenu([
            {
              text: "开始编辑对象",
              icon: "fa fa-edit",
              show: function (e) {
                const graphic = e.graphic;
                if (!graphic || !graphic.hasEdit) {
                  return false;
                }
                return !graphic.isEditing;
              },
              callback: (e) => {
                const graphic = e.graphic;
                if (!graphic) {
                  return false;
                }
                if (graphic) {
                  rightGraphicLayer.startEditing(graphic);
                }
              },
            },
            {
              text: "停止编辑对象",
              icon: "fa fa-trash-o",
              callback: (e) => {
                const graphic = e.graphic;
                if (graphic) {
                  // console.log(graphic)
                  state.点 = graphic.coordinates[0];
                  graphic.stopEditing();
                }
              },
            },
          ]);
        },
        updateDrawPosition: (graphic) => {
          state.点 = graphic.coordinates[0];
        },
      });
    };
    const delAllTb = () => {
      // 删除正射影像
      if (window.singleTileLayer.length > 0) {
        window.singleTileLayer.forEach((e) => {
          if (e) map.removeLayer(e, true);
        });
      }
      window.singleTileLayer = [];

      // 删除图斑
      globalData.tbArr.forEach((e) => {
        let a = map.getLayer(e, "name");
        if (a) map.removeLayer(a, true);
      });
      globalData.tbArr = [];
      globalData.tbTypes = [];

      // 删除模型
      if (window.singleTiles3dLayer.length > 0) {
        window.singleTiles3dLayer.forEach((e) => {
          if (e) map.removeLayer(e, true);
        });
      }
      window.singleTiles3dLayer = [];

      let ftbDatas = Object.keys(globalData.ftbArr);

      ftbDatas.forEach((e) => {
        if (globalData.ftbArr[e].length > 0) {
          globalData.ftbArr[e].forEach((graphic) => {
            if (graphic && graphic.state == "added") {
              graphic.remove(true);
            }
          });
        }
      });
    };
    const drawPolygon = () => {
      if (state.面) {
        return _this.$message({
          message: "区域已存在，请在地图上右键开启编辑！",
          type: "warning",
        });
      }
      let polygonP = rightGraphicLayer.startDraw({
        type: "polygonP",
        hasEdit: true,
        style: {
          color: "rgba(255, 136, 0, 1)",
          opacity: 0,
          outline: true,
          clampToGround: true,
          outlineStyle: {
            width: 4,
            color: state.lwOption.color,
            opacity: 1,
          },
        },
        success: (graphic) => {
          console.log(graphic);
          setTimeout(() => {
            console.log(graphic);
          }, 800);
          graphic.id = "polygonP" + new Date().getTime();
          window.drawPolygon = graphic;
          state.lwOption.border = graphic.coordinates;

          let { lng, lat, alt } = graphic.centerPoint;

          state.lwOption.position = {
            lng,
            lat,
            alt,
          };

          let idx = state.predefineColors.indexOf(state.lwOption.color);
          idx = idx != 1 ? idx + 1 : 1;
          let img1 = _this.getViteUrl("../assets/images/t" + idx + ".png");
          let primitive = _this.addBillboardPrimitive(
            rightGraphicLayer,
            state.lwOption.position,
            img1,
            {},
            true,
            {
              text: state.lwOption.name,
              font_size: 16,
              color: "#ffffff",
              pixelOffsetY: -80,
              distanceDisplayCondition: true,
              distanceDisplayCondition_far: 500000000,
              distanceDisplayCondition_near: 0,
            }
          );

          primitive.on(mars3d.EventType.click, function (event) {});
          primitive.bindContextMenu([
            {
              text: "开始编辑对象",
              icon: "fa fa-edit",
              show: function (e) {
                const graphic = e.graphic;
                if (!graphic || !graphic.hasEdit) {
                  return false;
                }
                return !graphic.isEditing;
              },
              callback: (e) => {
                const graphic = e.graphic;
                if (!graphic) {
                  return false;
                }
                if (graphic) {
                  rightGraphicLayer.startEditing(graphic);
                }
              },
            },
            {
              text: "停止编辑对象",
              icon: "fa fa-trash-o",
              callback: (e) => {
                const graphic = e.graphic;
                if (graphic) {
                  // console.log(graphic)
                  state.lwOption.position = graphic.coordinates[0];
                  graphic.stopEditing();
                }
              },
            },
          ]);
          // 面筛选
          // `<Filter xmlns="http://www.opengis.net/ogc" xmlns:gml="http://www.opengis.net/gml"><Intersects><PropertyName>GEOM</PropertyName><gml:Polygon><gml:outerBoundaryIs><gml:LinearRing><gml:coordinates>113.763,34.435 113.763,34.5 113.763,34.435</gml:coordinates></gml:LinearRing></gml:outerBoundaryIs></gml:Polygon></Intersects></Filter>`
          // 点筛选
          let filter =
            `<Filter xmlns="http://www.opengis.net/ogc" xmlns:gml="http://www.opengis.net/gml"><Intersects><PropertyName>the_geom</PropertyName><gml:Point><gml:coordinates>` +
            graphic.centerPoint.lng +
            "," +
            graphic.centerPoint.lat +
            `</gml:coordinates></gml:Point></Intersects></Filter>`;
          http.filterJson({ filter }).then((res) => {
            if (res.features.length > 0) {
              state.lwOption.grid = res.features[0].properties.村名称;
              let arr = globalData.chooseBtns.所属网格[0].children;
              let obj = arr.find((e) => {
                return e.title == state.lwOption.grid;
              });
              // console.log(obj)
              state.lwOption.gridId = obj.id;
            } else {
              _this.$message({
                message: "未找到对应乡镇信息，请确认区域信息是否正确！！！",
                type: "warning",
              });
            }
          });
          state.lwOption.area = graphic.area.toFixed(0) + "平方米";
          graphic.bindContextMenu([
            {
              text: "开始编辑对象",
              icon: "fa fa-edit",
              show: function (e) {
                const graphic = e.graphic;
                if (!graphic || !graphic.hasEdit) {
                  return false;
                }
                return !graphic.isEditing;
              },
              callback: (e) => {
                const graphic = e.graphic;
                if (!graphic) {
                  return false;
                }
                if (graphic) {
                  rightGraphicLayer.startEditing(graphic);
                }
              },
            },
            {
              text: "停止编辑对象",
              icon: "fa fa-trash-o",
              callback: (e) => {
                const graphic = e.graphic;
                if (graphic) {
                  // console.log(graphic)
                  state.lwOption.area =
                    (graphic.area * 0.0001).toFixed(0) + "公顷";
                  state.lwOption.border = graphic.coordinates;
                  graphic.stopEditing();
                }
              },
            },
          ]);
        },
      });
    };

    const closeVideo = async () => {
      getCameraInfo(false);
      _this.flyHome();
    };

    onMounted(async () => {
      graphicLayer = new mars3d.layer.GraphicLayer();
      map.addLayer(graphicLayer);

      rightGraphicLayer = new mars3d.layer.GraphicLayer({});
      map.addLayer(rightGraphicLayer);
      // drawPoint();
      await getAllKey();
      getAllTb();
      getOtherTree();
      // http.getAllModelList().then((res) => {

      // });
      // initReformModel();
    });

    onActivated(() => {
      // alert('onActivated')
      switchTypeFun(state.btn);
      if (state.btn == 3) {
        // window.tiles3dLayer.forEach((e) => {
        //   e.show = globalData.show3D;
        // });
        let mapOld = document.getElementById("mars3dContainer");
        mapOld.style.width = "100vw";
        setQP(true);
        setModel(true);
        showTbArr(true);
      } else {
        // window.tiles3dLayer.forEach((e) => {
        //   e.show = false;
        // });

        setQP(false);
        setModel(false);
      }
    });
    onDeactivated(() => {
      if (rightGraphicLayer && rightGraphicLayer.show) {
        rightGraphicLayer.remove(); //隐藏图层
      }
      setQP(false);
      setModel(false);
      showTbArr(false);
    });

    onUnmounted(() => {
      if (rightGraphicLayer && rightGraphicLayer.show) {
        rightGraphicLayer.remove(); //隐藏图层
      }
      delAllTb();
      if (window.mapSplit) map.removeControl(window.mapSplit);
      let mapOld = document.getElementById("mars3dContainer");
        mapOld.style.width = "100vw";
    });

    return {
      // 变量
      state,
      globalData,
      // 方法
      switchTypeFun,
      showLw,
      handleClear2,
      handleNodeClick2,
      hoverBtn,
      startDrawGraphic,
      startDrawPolygon,
      createdLw,
      pickViews,
      clearViews,
      clearPos,
      clearArea,
      closeLw,
      closeTbDetail,
      handleCut,
      handleCut1,
      closeVideo,
    };
  },
};
</script>

<style lang="scss" scoped>
.topCommon {
  margin: 10px 0px 0 0px;
  box-sizing: border-box;
  width: auto;
  height: auto;
  background: rgba(2, 11, 18, 0.75);
  // border: 1px solid rgba(51, 102, 153, 0.5);
  border-radius: 3px;
}
.btn_box {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
}

.zjBtn {
  height: 26px;
  background: rgba(2, 11, 18, 0.75);
  border: 1px solid rgba(19, 67, 113, 0.75);
  border-radius: 4px;
  position: absolute;
  right: 90px;
  top: 0;
  padding: 5px 7px;
  box-sizing: border-box;
  z-index: 2;
  span {
    width: 50px;
    margin-left: 6px;
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    font-style: italic;
    color: #cde4f8;
    // line-height: 24px;
    background: linear-gradient(to top, #8ac7ff 0%, #ffffff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.lwBtn {
  height: 26px;
  background: rgba(2, 11, 18, 0.75);
  border: 1px solid rgba(19, 67, 113, 0.75);
  border-radius: 4px;
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px 7px;
  box-sizing: border-box;
  z-index: 2;
  span {
    width: 50px;
    margin-left: 6px;
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    font-style: italic;
    color: #cde4f8;
    // line-height: 24px;
    background: linear-gradient(to top, #8ac7ff 0%, #ffffff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.lwName {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #0ff3ee;
  padding: 0 24px;
  box-sizing: border-box;
}

.lwItem {
  > * {
    padding: 0 24px;
    box-sizing: border-box;
  }
  .dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #eb5438;
    margin-right: 6px;
  }

  .label {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: rgba(205, 228, 248, 1);
    line-height: 14px;
    white-space: nowrap;
  }

  .pickPst {
    width: 60px;
    height: 16px;
    background: rgba(38, 130, 240, 0.2);
    border: 1px solid rgba(38, 182, 240, 0.8);
    box-shadow: inset 0px -2px 10px 0px rgba(4, 244, 252, 0.3),
      0px -1px 20px 0px rgba(38, 182, 240, 0.6);
    border-radius: 3px;

    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #04f4fc;
    line-height: 16px;
    text-align: center;
    margin-right: 6px;
  }

  .clearText {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #d72828;
    line-height: 16px;
    opacity: 0.4;
  }

  .clearText:hover {
    opacity: 1;
  }

  .valueDiv {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #697ca7;
    line-height: 20px;
    span:nth-child(1) {
      max-width: 180px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.twoBtn {
  padding: 0 33px;
  box-sizing: border-box;
  .lwSub {
    width: 100px;
    height: 30px;
    background: rgba(0, 178, 255, 0.25);
    border: 1px solid rgba(0, 178, 255, 0.5);
    border-radius: 15px;
    text-align: center;
    span {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #cde4f8;
      opacity: 0.3;
      line-height: 30px;
    }
  }

  .lwqui {
    width: 100px;
    height: 30px;
    background: rgba(105, 124, 167, 0.25);
    border: 1px solid rgba(105, 124, 167, 0.5);
    border-radius: 15px;
    text-align: center;
    span {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #cde4f8;
      line-height: 30px;
    }
  }
}

.closeLw {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 20px;
  top: 14px;
  z-index: 10;
  cursor: pointer;
}

.allSelect {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translate(-50%, 0);
  // width: 190px;
  // height: 26px;
  z-index: 12;
}
.allSelect2 {
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translate(-50%, 0);
  // width: 190px;
  // height: 26px;
  z-index: 12;
}

.leftSelect {
  // position: absolute;
  // top: 20px;
  // left: -335px;
  width: 300px;
  height: 26px;
  margin-right: 10px;
}
.rightSelect {
  // position: absolute;
  // top: 20px;
  // left: 45px;
  width: 300px;
  height: 26px;
  margin-left: 10px;
}

.colorItem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  cursor: pointer;
  color: #fff;
  margin-right: 8px;
}

.detection_right {
  // width: 338px;
  // position: fixed;
  // right: 30px;
  // top: 92px;
  // bottom: 40px;
  // z-index: 10;
  width: 100%;
  height: 100%;
  // max-height: calc(100vh - 132px);
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  // background: rgba(9, 45, 92, 0.3);

  .textItem {
    margin: 0 12px;
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #a1b8cd;

    .dot1 {
      width: 10px;
      height: 10px;
      background: #00b2ff;
      border-radius: 50%;
      margin-right: 8px;
    }

    .dot2 {
      width: 10px;
      height: 10px;
      background: rgba(255, 205, 6, 1);
      border-radius: 50%;
      margin-right: 8px;
    }

    .dot3 {
      width: 10px;
      height: 10px;
      background: rgba(235, 84, 56, 1);
      border-radius: 50%;
      margin-right: 8px;
    }
  }

  .monitorAvatar {
    width: 290px;
    height: 160px;
    margin: 0px 10px 1px 0;
    box-sizing: border-box;
    border: 1px solid black;
    box-sizing: border-box;
    // margin-bottom: 2px;
  }

  .calendarLeftArrow {
    width: 14px;
    object-fit: contain;
    cursor: pointer;
  }

  .calendarRightArrow {
    width: 14px;
    object-fit: contain;
    cursor: pointer;
    rotate: 180deg;
  }

  .calendarBox {
    width: 288px;
  }

  .warningTrend {
    width: 100%;
    height: auto;
    padding-left: 16px;
    box-sizing: border-box;
  }

  .warningList {
    width: 100%;
    height: auto;
    padding-left: 34px;
    box-sizing: border-box;
  }

  .newWarnsBox {
    width: 100%;
    height: auto;
    padding-left: 34px;
    box-sizing: border-box;

    .warnTitleBox {
      margin-top: 2px;

      > div {
        padding-left: 16px;
        box-sizing: border-box;
      }

      .titleItem {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #cde4f8;
        width: 130px;
        height: 30px;
        background: rgba(9, 45, 92, 0.3);
        line-height: 30px;
        border-top: 1px solid rgba(37, 87, 126, 1);
      }

      .titleItem1 {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #cde4f8;
        width: 171px;
        height: 30px;
        line-height: 30px;
        background: rgba(9, 45, 92, 0.3);
        border-top: 1px solid rgba(37, 87, 126, 1);
      }

      .titleItemTr {
        width: 130px;
        // height: 40px;
        background: rgba(9, 45, 92, 0.15);
        border-left: 1px solid rgba(37, 87, 126, 1);
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;

        span:nth-child(1) {
          color: #738ea2;
        }

        span:nth-child(2) {
          color: #cde4f8;
        }
      }

      .titleItemTr2 {
        width: 171px;
        // height: 40px;
        // padding-left: 16px;
        padding: 5px 0 5px 16px;
        background: rgba(9, 45, 92, 0.15);
        box-sizing: border-box;

        .column {
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;

          span:nth-child(1) {
            color: #738ea2;
          }

          span:nth-child(2) {
            color: #cde4f8;
          }
        }

        svg {
          width: 10px;
          margin-right: 10px;
          color: rgba(105, 124, 167, 0.5);
        }
      }
    }
  }
}

.manageScroll {
  overflow-y: scroll;
}

.manageScroll::-webkit-scrollbar {
  /* WebKit浏览器的滚动条样式 */
  width: 2px;
}

.manageScroll::-webkit-scrollbar-track {
  /* 滚动条轨道的样式 */
  background-color: rgba(255, 255, 255, 0.1);
}

.manageScroll::-webkit-scrollbar-thumb {
  /* 滚动条滑块的样式 */
  background-color: rgba(255, 255, 255, 0.1);
}

.manageScroll::-webkit-scrollbar-thumb:hover {
  /* 滚动条滑块的悬停样式 */
  background-color: rgba(255, 255, 255, 0.1);
}
</style>
