<template>
  <el-drawer v-model="show" :with-header="false" direction="btt" :append-to-body="true" size="100%">
    <svg @click="clearYSY()" :style="shanboShow || !cameraType ? 'right:20px;' : ''" class="box_camera_clear"
      viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
      <path fill="currentColor"
        d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm0 393.664L407.936 353.6a38.4 38.4 0 1 0-54.336 54.336L457.664 512 353.6 616.064a38.4 38.4 0 1 0 54.336 54.336L512 566.336 616.064 670.4a38.4 38.4 0 1 0 54.336-54.336L566.336 512 670.4 407.936a38.4 38.4 0 1 0-54.336-54.336L512 457.664z">
      </path>
    </svg>
    <div class="allContent divFlex alignCenter flexCenter"
      :style="shanboShow ? `background: black;` : 'background: transparent;'">
      <div class="box_camera" :style="shanboShow ? `background: black;` : ''" v-if="cameraType && !shanboUrl">
        <div class="box_camera_content">
          <!-- <div class="shadow11"></div> -->
          <div id="videContainer">
            
          </div>
          <PresuppositionView style="transform: translate(0px, -48px);" v-if="isHui" :setUp="true" :array="viewArr" :token="token" :cameraInfo="camera" type="2"
            @setViewArray="(val) => (viewArr = val)" @setiStreamType="setiStreamType" />
          <Viewcamera style="bottom:58px;" v-if="isHui" :token="token" :cameraInfo="camera" />
        </div>
        <div class="box_camera_datas">
          <div class="box_camera_name">
            <Icons v-if="camera.type == '1'" name="a-jiankong1" class="icon3" />
            <Icons v-if="camera.type == '2'" name="a-jiankong3" class="icon3" />
            {{ camera.type == "1" ? "AI智能感知摄像机" : "高位球机" }}
          </div>
          <div class="box_camera_data">
            设备名称：<span>{{ camera.equipmentName }}</span>
          </div>
          <div class="box_camera_data">
            设备位置：<span>{{ camera.address }}</span>
          </div>
          <div class="box_camera_data">
            是否在线：<span>{{ camera.status == "2" ? "是" : "否" }}</span>
          </div>
          <div class="divFlex">
            <button class="box_camera_button" @click="lishi()">
              {{ isHui ? "回放" : "实时直播" }}
            </button>
            <button class="box_camera_button" v-if="!shanboShow" @click="showShanbo">
              云闪播>>
            </button>
          </div>

        </div>
      </div>

      <div class="rox_camera"
        :style="shanboShow ? `background: black;width:80%;margin-right: 20px;box-sizing: border-box;` : ''"
        v-if="!cameraType && !shanboUrl">
        <div class="box_camera_content">
          <div id="videContainer"></div>
        </div>
        <div class="box_camera_datas">
          <div class="box_camera_name">
            <Icons name="a-jiankong2" class="icon3" />
            高位全景摄像机
          </div>
          <div class="box_camera_data">
            设备名称：<span>{{ camera.equipmentName }}</span>
          </div>
          <div class="box_camera_data">
            设备位置：<span>{{ camera.address }}</span>
          </div>
          <div class="box_camera_data">
            是否在线：<span>{{ camera.status == "2" ? "是" : "否" }}</span>
          </div>
          <div class="divFlex">
            <button class="box_camera_button" @click="lishi()">
              {{ isHui ? "回放" : "实时直播" }}
            </button>
            <button class="box_camera_button" v-if="!shanboShow" @click="showShanbo">
              云闪播>>
            </button>
          </div>
        </div>
      </div>

      <!-- //云闪播视频容器 -->
      <transition name="diy-slide-left">
        <div v-if="shanboUrl" class="liveVideo">
          <img @click="closeSB()" src="@/assets/images/d6.png" class="closeBtn" />
          <live-player :video-url="shanboUrl" live="true" fluent stretch aspect="fullscreen" :autoplay="true">
          </live-player>
        </div>
      </transition>
      <transition name="diy-slide-right">
        <!-- <template> -->
        <div class="shanbo_right" v-if="shanboShow">
          <SeaTilte title="网格云闪播" />
          <div class="date-picker">
            <div class="date-picker-label">时间筛选：</div>
            <el-date-picker v-model="datetime" type="datetimerange" range-separator="-" start-placeholder="开始日期"
              end-placeholder="结束日期" align="left" format="MM月DD日 HH时" value-format="YYYY-MM-DD HH:mm:ss">
            </el-date-picker>
          </div>
          <div class="count" v-if="topCount">
            <div class="allCount">
              <span>今日云闪播</span>
              <div class="numberBox">
                <div class="num">{{ topCount.today }}</div>
                <div class="unit">条</div>
              </div>
            </div>
            <div class="allCount">
              <span>云闪播总数</span>
              <div class="numberBox">
                <div class="num">{{ topCount.all }}</div>
                <div class="unit">条</div>
              </div>
            </div>
          </div>

          <div style="height: 20px; flex-shrink: 0"></div>
          <transition name="diy-slide-right" v-if="videoList">
            <el-scrollbar class="hidden absolute grow w vvv">
              <el-timeline>
                <el-timeline-item v-for="( item, idx, i ) in  videoList " :key="idx + 'fdsfdsgsdge'"
                  :size="i == 0 ? 'large' : 'normal'">
                  <div class="timeBox">
                    <span>{{ idx }}</span>
                  </div>
                  <div class="overview_card yunText" @click="seeThis(item1)" v-for="( item1, i ) in  item "
                    :key="i + item1">
                    <div class="allView">{{ ['枪机', '球机', '全景机'][item1.deviceType] }}</div>
                    <div class="overview_card_images w">
                      <img :src="item1.previewImage" class="w h" />
                      <div class="timeBox1">
                        <img src="@/assets/images/pp.png" alt="">
                        <span class="text">{{ item1.duration }}</span>
                      </div>
                    </div>
                    <div class="overview_card_text">{{ item1.deviceName }}</div>
                    <div class="overview_card_text1">{{ item1.timePeriod || '' }}</div>
                  </div>
                </el-timeline-item>
              </el-timeline>
            </el-scrollbar>
          </transition>

          <div class="shanbo" @click="showShanbo">
            <button class="box_camera_button">云闪播>></button>
          </div>
        </div>

        <!-- </template> -->
      </transition>
    </div>

  </el-drawer>
</template>

<script>
import EZUIKit from 'ezuikit-js';
import { Viewcamera } from "@/components";
import SeaTilte from "./SeaTilte.vue";
import PresuppositionView from "./PresuppositionView.vue";

import { getMonitor, addressGet, getVideoAddress, createSeaPage, getvideos, getVideoSize } from "@/api";
import Icons from "./Icons.vue";
var player = null;
export default {
  name: "Ysy",
  components: { Viewcamera, PresuppositionView, Icons, SeaTilte },
  props: ["camera"],
  watch: {},
  data() {
    return {
      show: true,
      isHui: true,
      token: false,
      cameraType: false,
      viewArr: [],
      // ------------云闪播-------
      shanboUrl: false,
      shanboShow: false,
      datetime: '',
      startTime: '',
      endTime: '',
      videoList: [],
      topCount: false,
    };
  },
  watch: {
    datetime(val) {
      if (!val) {
        this.startTime = ""
        this.endTime = ""
      } else {
        this.startTime = val[0]
        this.endTime = val[1]
      }

      this.videoList = [];
      this.getList();
    }
  },
  mounted() {
    createSeaPage({ monitorId: this.camera.id }).then(res => {
      this.viewArr = res.data.records
    })
    getMonitor().then((res) => {
      // console.log(this.camera)
      this.token = res.data.data.accessToken;
      this.cameraType = this.camera.type != 3;
      this.ec();
    });
    this.getList()
  },
  setup(props, ctx) {
    const clearClick = (data) => {
      ctx.emit("clearClick", data);
    };
    return { clearClick };
  },
  methods: {
    getList() {
      let projectName = JSON.parse(decodeURIComponent(this.getCookie('userInfo').replace(/%22/g, '"').replace(/%2C/g, ',')));
      projectName = projectName.title;
      getvideos({ startTime: this.startTime, endTime: this.endTime, project: projectName, serialNumber: this.camera.serialNumber, channel: this.camera.channelNumber }).then(res => {
        this.videoList = res.data
      })
      getVideoSize({ projectName, serialNumber: this.camera.serialNumber, channel: this.camera.channelNumber }).then(res => {
        this.topCount = res.data;
      })
    },
    async closeSB() {
      this.shanboUrl = false;
      this.ec();
    },
    showShanbo() {
      this.shanboUrl = false;
      this.shanboShow = !this.shanboShow;
    },
    async seeThis(val) {
      console.log(player)
      if (player) {
        await player.stop().then(() => {
          // player.destroy().then(
          //   () => { 
              player = false 
          //   }
          // )
        });
      }
      this.shanboUrl = val.filePath;


    },
    async clearYSY(fun = false) {
      if (player) {
        await player.stop().then(() => {
          // player.destroy().then(
          //   () => { }
          // )
        });
      }

      if (fun === false) {
        this.show = false;
        setTimeout(() => {
          this.clearClick();
        }, 200);
      } else {
        fun();
      }

    },

    lishi() {
      this.clearYSY(() => {
        if (this.isHui) {
          const yy = new Date().getFullYear();
          const MM =
            new Date().getMonth() + 1 < 10
              ? "0" + (new Date().getMonth() + 1)
              : new Date().getMonth() + 1;
          const dd =
            new Date().getDate() < 10
              ? "0" + new Date().getDate()
              : new Date().getDate();
          const HH =
            new Date().getHours() < 10
              ? "0" + new Date().getHours()
              : new Date().getHours();
          const mm =
            new Date().getMinutes() < 10
              ? "0" + new Date().getMinutes()
              : new Date().getMinutes();
          const ss =
            new Date().getSeconds() < 10
              ? "0" + new Date().getSeconds()
              : new Date().getSeconds();
          addressGet({
            accessToken: this.token,
            code: this.camera.code,
            deviceSerial: this.camera.serialNumber,
            channelNo: Number(this.camera.channelNumber),
            protocol: 1,
            quality: 1,
            startTime: yy + "-" + MM + "-" + dd + " 00:00:00",
            stopTime: yy + "-" + MM + "-" + dd + " " + HH + ":" + mm + ":" + ss,
            type: "2",
          }).then((res) => {
            // console.log(res);
            let dom = document.getElementsByClassName("box_camera_content")[0];
            let width = dom.offsetWidth;
            let height = dom.offsetHeight + 48;
            player = new EZUIKit.EZUIKitPlayer({
              id: "videContainer", // 视频容器ID
              accessToken: this.token,
              url: res.data.url,
              // simple - 极简版; pcLive-pc直播；pcRec-pc回放；mobileLive-移动端直播；mobileRec-移动端回放;security - 安防版;voice-语音版;
              template: "pcRec",
              // plugin: ['talk'], // 加载插件，talk-对讲
              width,
              height,
            });
          });
        } else {
          this.ec();
        }

        this.isHui = !this.isHui;
      });
    },
    ec() {
      setTimeout(() => {
        getVideoAddress({
          serialNumber: this.camera.serialNumber,
          channelNo: Number(this.camera.channelNumber),
          protocol: 1,
          quality: 1,
          code: this.camera.code,
        }).then((rest) => {
          // if (!rest.data.data.url) return false;
          if (!rest.data?.data?.url)
          return this.$message({
            message: rest.data.msg,
            type: "warning",
          });
          let dom = document.getElementsByClassName("box_camera_content")[0];
          let width = dom.offsetWidth;
          let height = dom.offsetHeight + 48;
          player = new EZUIKit.EZUIKitPlayer({
            id: "videContainer", // 视频容器ID
            accessToken: this.token,
            url: rest.data.data.url,
            // simple - 极简版; pcLive-pc直播；pcRec-pc回放；mobileLive-移动端直播；mobileRec-移动端回放;security - 安防版;voice-语音版;
            template: "pcLive",
            // plugin: ['talk'], // 加载插件，talk-对讲
            width,
            height,
          });
        });
      }, 800);

    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/default.scss";

.icon3 {
  width: 24px;
  height: 30px;
  color: var(--oneItem);
  margin-right: 10px;
}

.allContent {
  width: 100%;
  height: 980px;
  padding: 10px;
  box-sizing: border-box;
  position: fixed;
  top: 60px;
}

.liveVideo {
  width: 1520px;
  height: 960px;
  background: rgba(7, 28, 49, 1);
  border-radius: 20px;
  transition: all 0.3s;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
  margin-right: 10px;
  box-sizing: border-box;

  .closeBtn {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
    width: 20px;
    object-fit: contain;
  }
}

.box_camera {
  width: 1520px;
  height: 960px;
  background: rgba(7, 28, 49, 1);
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  transition: all 0.3s;
  // position: fixed;
  // left: 50%;
  // top: 80px;
  box-sizing: border-box;
  padding: 10px;
  // transform: translate(-50%, 0px);

  .box_camera_content {
    width: 100%;
    height: 888px;
    background: #000;
    border-radius: 14px;
    // overflow: hidden;
    position: relative;

    .shadow11 {
      width: 100%;
      height: 48px;
      background: #000;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
    }
  }
}

.rox_camera {
  width: 100%;
  height: 640px;
  transition: all 0.3s;
  background: rgba(7, 28, 49, 1);
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  // position: fixed;
  // left: 0;
  // bottom: 200px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 10px;

  .box_camera_content {
    width: 100%;
    height: 568px;
    background: #000;
    border-radius: 14px;
    // overflow: hidden;
    position: relative;
  }
}

.box_camera_datas {
  margin: 0px auto 0 auto;
  // margin: 42px auto 0 auto;
  max-width: 1500px;
  height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  // padding-right: 100px;
  .box_camera_name {
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #A6FFEF;
    background: linear-gradient(0deg, #A6FFEF 0%, #22E2FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    img {
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }
  }

  .box_camera_data {
    font-size: 18px;
    font-weight: 400;
    color: rgba(91, 119, 147, 1);
    line-height: 26px;

    span {
      color: rgba(193, 222, 224, 1);
    }
  }
}

.box_camera_clear {
  position: fixed;
  right: 170px;
  top: 65px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 9;
  color: rgba(236, 87, 78, 1);
  background: #fff;
  border-radius: 50%;
  transition: all 0.3s;
}

.box_camera_button {
  width: 120px;
  height: 40px;
  background: linear-gradient(to right, rgba(34, 226, 255, 1)0%, rgba(166, 255, 239, 1) 100%);
  // box-shadow: 0px 5px 15px 0px rgba(1, 180, 255, 0.25),
  //   0px 10px 50px 0px rgba(1, 180, 255, 0.25);
  border-radius: 20px;
  margin-bottom: -5px;
  -webkit-text-stroke: 0.5px rgba(1, 180, 255, 0.25);
  font-size: 24px;
  font-family: YouSheBiaoTiHei;
  font-weight: 400;
  color: #071C31;


  cursor: pointer;
}

#videContainer {
  width: 100%;
  height: 100%;
}



// -------------------------云闪播

.closeBtn {
  z-index: 2;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  top: 5px;
  z-index: 5;
  cursor: pointer;
}

.towBox {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  overflow: hidden;

  .oneItem {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}

.addressInfo {
  margin-top: 20px;
  margin-left: 4px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;

  .addressName {
    color: var(--ssaddress);
  }

  .address {
    color: var(--ssprogress2);
  }
}

.infoDetailBox {
  width: 100%;
  height: auto;
  // background: var(--specialBack);
  border-radius: 5px;
  padding: 0 10px 20px 10px;
  box-sizing: border-box;
}

.intr {
  width: fit-content;
  padding: 0 10px;
  display: flex;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 30px;

  div:nth-child(1) {
    color: var(--ssintr);
    flex-shrink: 0;
  }

  div:nth-child(2) {
    color: var(--ssallInfo);
  }
}

.familyDetail {
  border: 1px solid;
  // border-image: linear-gradient(
  //     0deg,
  //     var(--servicesPin-button3),
  //     var(--infolist2)
  //   )
  //   10 10;
  background: linear-gradient(90deg,
      var(--servicesPin-button3) 0%,
      var(--infolist2) 100%);
  border-radius: 5px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: var(--oneItem);
  position: absolute;
  right: 18px;
  top: 108px;
  z-index: 38;
  padding: 0 4px;
  box-sizing: border-box;
  cursor: pointer;
}

.timeBox {
  width: fit-content;
  display: flex;
  align-items: center;
  height: 20px;
  background: var(--servicesPin2);
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: var(--servicesPin3);
  line-height: 20px;
  margin-bottom: 15px;

  span {
    margin-left: 10px;
  }
}

.timeBox::before {
  content: "";
  display: block;
  position: absolute;
  width: 5px;
  height: 20px;
  background: var(--servicesPin3);
  border-radius: 0px 4px 4px 0px;
}

.overview_card {
  width: 146px;
  height: 130px;
  background: rgba(114, 113, 102, 0.1);
  border: 1px solid rgba(89, 255, 162, 0.2);
  border-radius: 5px;
  overflow: hidden;
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 6px;
  margin-right: 3px;
  cursor: pointer;

  .overview_card_images {
    height: 90px;
    overflow: hidden;
    position: relative;

    >img {
      object-fit: cover;
      height: 90px;
      width: 100%;
    }

    .video_button {
      position: absolute;
      left: calc(50% - 25px);
      top: calc(50% - 25px);
      width: 50px;
      height: 50px;
      z-index: 3;
    }

    .divRadius {
      border: 10px solid #555555;
      border-left: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-top-right-radius: 4px;
      content: "";
      position: relative;
      width: 0;
    }

    .timeBox1 {
      height: 18px;
      background: rgba(0, 0, 0, 0.75);

      border-radius: 3px;
      padding: 2px 7px;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: var(--servicesPin-typeBtn1);
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      left: 6px;
      bottom: 6px;

      .text {
        margin-left: 4px;
      }
    }
  }

  .overview_card_text {
    height: 20px;
    line-height: 25px;
    box-sizing: border-box;
    padding-left: 7px;
    position: relative;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: var(--ssallInfo);
  }

  .overview_card_text1 {
    height: 20px;
    line-height: 20px;
    box-sizing: border-box;
    padding-left: 7px;
    position: relative;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: var(--ssintr);
  }
}

.overview_card:nth-child(even) {
  margin-right: 0;
}

.allDesc {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .dataDesc {
    display: flex;
    align-items: flex-end;
    width: 50%;

    .nameTitle {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #72c583;
    }

    .numBox {
      width: 80px;
      height: 24px;
      margin-left: 10px;
      background: linear-gradient(90deg,
          rgba(114, 113, 102, 0.3) 0%,
          rgba(114, 113, 102, 0) 100%);
      display: flex;
      align-items: flex-end;

      .numSpan {
        font-size: 30px;
        font-family: Square722;
        font-weight: bold;
        font-style: italic;
        color: var(--servicesPin3);
        line-height: 26px;
      }

      .unitSpan {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #aa947c;
        line-height: 12px;
        margin: 0 0 2px 4px;
      }
    }
  }
}

.allView {
  // width: 34px;
  padding: 2px 5px;
  box-sizing: border-box;
  // height: 18px;
  background: var(--servicesPin3);
  border-radius: 0px 3px 0px 3px;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #1c2025;
  z-index: 2;
}

.yunText {
  position: relative;
}

.yunText:nth-child(even) {
  margin-right: 4px;
}

.date-picker {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  height: 26px;
  background: var(--date-picker);
  border: 1px solid var(--date-picker1);
  border-radius: 3px;
  flex-shrink: 0;

  .date-picker-label {
    margin-left: 5px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: var(--date-picker-label);
    line-height: 24px;
    white-space: nowrap;
  }
}

.count {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .allCount {
    display: flex;
    align-items: flex-end;

    >span {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: var(--date-picker-label);
      white-space: nowrap;
    }

    .numberBox {
      width: 80px;
      height: 18px;
      margin-left: 5px;
      display: flex;
      align-items: flex-end;
      background: linear-gradient(to right,
          var(--videoList) 0%,
          var(--levelNames) 100%);

      .num {
        font-size: 24px;
        font-family: Square722;
        font-weight: bold;
        font-style: italic;
        color: var(--servicesPin3);
        margin-bottom: -4px;
      }

      .unit {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: var(--ssallInfo);
        margin-left: 2px;
      }
    }
  }
}

.shanbo_right {
  width: 380px;
  height: 940px;
  // border: 1px solid rgba(0, 198, 255, 0.3);
  // background: rgba(7, 28, 49, 0.6);
  // box-shadow: 0px 10px 40px 0px rgba(51, 102, 153, 0.5);
  padding: 10px 15px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  position: relative;
  flex-direction: column;

  .shanbo {
    height: 200px;
    width: 100%;
    background: linear-gradient(to top, black 0%, transparent 100%);
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 5;

    .box_camera_button {
      width: 120px;
      height: 40px;
      background: linear-gradient(to right, rgba(34, 226, 255, 1)0%, rgba(166, 255, 239, 1) 100%);
      border-radius: 20px;
      margin-bottom: -5px;
      -webkit-text-stroke: 0.5px rgba(1, 180, 255, 0.25);
      font-size: 24px;
      font-family: YouSheBiaoTiHei;
      font-weight: 400;
      color: #071C31;
      cursor: pointer;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0px);
      bottom: 3px;
    }
  }
}
</style>
