<template>
    <div>
        <div class="allDetectionList column" v-for="(item, idx) in  menuData " :key="idx + 'lkjtfdf' + item.id">
            <div @click="allChooseItem(item)" :style="`padding-left:${Number(Number(item.level) - deflection) * 20 + 15}px`"
                v-if="(item.children && item.children.length > 0) || item.isGrid" class="detectionItem divFlex alignCenter">
                
                <svg :class="allChooseId.includes(item.id)?'rotate180':''" :style="item.children && item.children.length > 0 ? 'opacity:1;' : 'opacity:0;'" viewBox="0 0 1024 1024"
                    xmlns="http://www.w3.org/2000/svg" data-v-ea893728="">
                    <path fill="currentColor"
                        d="M104.704 338.752a64 64 0 0 1 90.496 0l316.8 316.8 316.8-316.8a64 64 0 0 1 90.496 90.496L557.248 791.296a64 64 0 0 1-90.496 0L104.704 429.248a64 64 0 0 1 0-90.496z">
                    </path>
                </svg>


                <div class="iconAndNameAndNum divFlex alignCenter"
                    v-if="(item.children && item.children.length > 0) || item.isGrid">
                    <img v-if="item.level == deflection" class="icon" src="@/assets/detection/a5.png" alt="">
                    <img v-else class="icon" src="@/assets/detection/a6.png" alt="">
                    <div class="countyName">{{ item.title }}</div>
                    <div class="num">(
                        <span>{{ item.monitorCount }}/</span>
                        <span>{{ item.offLineCount }}/</span>
                        <span>{{ item.collectCount }}</span>
                        )
                    </div>
                </div>
            </div>

            <transition name="el-zoom-in-top" v-if="allChooseId.includes(item.id) && item.isGrid">
                <DetectionList @setCollect="setCollect" @getCameraInfo="getCameraInfo" :cameraId="cameraId" :deflection="deflection"
                    @ChooseChildItem="ChooseChildItem" @allChooseItem="allChooseItem" :allChooseId="allChooseId"
                    v-if="item.children && item.children.length > 0" :menuData="item.children" />

                <!-- ---分割线----- -->
                <!-- <div @click="chooseChildItem(item)" v-else class="lastItem bet"
                    :style="`padding-left:${Number(Number(item.level) - deflection) * 20 + 15}px`">
                    <div class="divFlex alignCenter">
                        <img class="icon" src="@/assets/detection/a7.png" alt="">
                        <span class="lastText">{{ item.title }}</span>
                    </div>
                    <svg :style="item.isCollect ? 'width:12px;color:rgba(161, 184, 205, 1);' : 'width:12px;color:rgba(253, 226, 37, 1);'"
                        viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728="">
                        <path fill="currentColor"
                            d="M283.84 867.84 512 747.776l228.16 119.936a6.4 6.4 0 0 0 9.28-6.72l-43.52-254.08 184.512-179.904a6.4 6.4 0 0 0-3.52-10.88l-255.104-37.12L517.76 147.904a6.4 6.4 0 0 0-11.52 0L392.192 379.072l-255.104 37.12a6.4 6.4 0 0 0-3.52 10.88L318.08 606.976l-43.584 254.08a6.4 6.4 0 0 0 9.28 6.72z">
                        </path>
                    </svg>
                </div> -->


            </transition>

            <!-- ---分割线----- -->
            <!-- <div @click="chooseChildItem(item)" v-if="!item.children && !item.isGrid" class="lastItem bet" :style="`padding-left:${Number(Number(item.level) - deflection) * 20 + 35}px;` + (JSON.stringify(cameraId) == JSON.stringify(item.id) ? 'background:rgba(37, 87, 126, 0.5);border-bottom: 1px solid rgba(37, 87, 126, 1)' : '') + -->
            <div @click="getCameraInfo(item)" :dataii="cameraId  ==item.id" v-if="!item.children && !item.isGrid" class="lastItem bet" :style="`padding-left:${Number(Number(item.level) - deflection) * 20 + 35}px;` + (JSON.stringify(cameraId) == JSON.stringify(item.id) ? 'background:rgba(37, 87, 126, 0.5);border-bottom: 1px solid rgba(37, 87, 126, 1)' : '') +
                (item.onlineStatus ? '' : 'opacity:0.5')">
                <div class="divFlex alignCenter iconBox">
                    <div class="relative">
                        <img class="icon"
                        :src="JSON.stringify(cameraId) == JSON.stringify(item.id) ? require('@/assets/detection/2045.png') : require('@/assets/detection/a7.png')"
                        alt="">
                        <div v-if="item.eventStatus" class="dot"></div>
                    </div>
                    
                    <span :style="JSON.stringify(cameraId) == JSON.stringify(item.id) ? 'color:rgba(15, 243, 238, 1)' : ''" class="lastText">{{ item.title
                    }}</span>
                    
                </div>
                <svg @click.stop="setCollect(item)"
                    :style="item.isCollect ? 'width:12px;color:rgba(253, 226, 37, 1);' : 'width:12px;color:rgba(161, 184, 205, 1);'"
                    viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728="">
                    <path fill="currentColor"
                        d="M283.84 867.84 512 747.776l228.16 119.936a6.4 6.4 0 0 0 9.28-6.72l-43.52-254.08 184.512-179.904a6.4 6.4 0 0 0-3.52-10.88l-255.104-37.12L517.76 147.904a6.4 6.4 0 0 0-11.52 0L392.192 379.072l-255.104 37.12a6.4 6.4 0 0 0-3.52 10.88L318.08 606.976l-43.584 254.08a6.4 6.4 0 0 0 9.28 6.72z">
                    </path>
                </svg>
            </div>


        </div>

    </div>
</template>
  
<script>
// VUE
import {
    h,
    ref,
    reactive,
    watch,
    computed,
    getCurrentInstance,
    onMounted,
    onActivated,
    onDeactivated,
} from "vue";
import { delCollect, collect } from "@/api"
// Components

// import DetectionList from './DetectionList.vue'
export default {
    name: 'DetectionList',
    components: {
        // DetectionList
    },
    props: ['menuData', 'allChooseId', 'deflection', 'cameraId'],

    setup(props, context) {
        const _this = getCurrentInstance().proxy;
        let state = reactive({

        }); // 数据
        const allChooseItem = (val) => {
            context.emit('allChooseItem', val)
        };

        const ChooseChildItem = (val) => {
            context.emit('ChooseChildItem', val)
        };
        const getCameraInfo = (val) => {
            context.emit('getCameraInfo', val)
        };
        const setCollect = (item) => {
            // console.log(item)
            if (item.isCollect != 1) {
                collect({ id: item.id })
            } else {
                delCollect({ id: item.id })
            }
            item.isCollect = !item.isCollect
        };

        onMounted(async () => {

        });

        return {
            // 变量
            state,
            //方法
            allChooseItem,
            ChooseChildItem,
            getCameraInfo,
            setCollect
        };
    },
};
</script>
  
<style lang="scss" scoped>
.detectionItem {
    padding: 0 20px 0 0px;
    padding: 15px;
    width: calc(100% - 30px);
    height: 30px;
    background: rgba(9, 45, 92, 0.3);
    margin-bottom: 4px;
    box-sizing: border-box;
    margin-left: 30px;
    cursor: pointer;

}

.lastItem {
    padding: 0 20px 0 15px;
    width: calc(100% - 30px);
    height: 30px;
    background: rgba(9, 45, 92, 0.15);
    margin-bottom: 4px;
    box-sizing: border-box;
    margin-left: 30px;
    cursor: pointer;

    .iconBox {
        position: reactive;

        .icon {
            width: 14px;
            height: 14px;
            opacity: 0.55;
        }

        .dot {
            width: 4px;
            height: 4px;
            background: #EB5438;
            border-radius: 2px;
            position: absolute;
            right: -2px;
            top: -2px;
        }
    }


    .lastText {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #CDE4F8;
        margin-left: 5px;
    }
}

// .detectionItem {
//     padding: 0 20px 0 15px;
//     width: calc(100% - 30px);
//     height: 30px;
//     background: rgba(9, 45, 92, 0.3);
//     margin-bottom: 4px;
//     box-sizing: border-box;
//     margin-left: 30px;
//     cursor: pointer;

// }

svg {
    width: 10px;
    color: #738EA2;
}

.iconAndNameAndNum {
    margin-left: 10px;

    .icon {
        width: 14px;
        height: 14px;
        opacity: 0.55;
    }

    .countyName {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #A1B8CD;
        margin: 0 12px 0 6px;
        max-width: 60%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .num {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #738EA2;
        opacity: 0.5;

        span:nth-child(3) {
            color: rgba(255, 205, 6, 1);
        }
    }
}

.rotate180{
    rotate: 180deg;
}
</style>
  