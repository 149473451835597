<template>
    <div class="titleBox divFlex alignCenter">
        <span class="text">{{ title }}</span>
        <!-- <div class="englishText">{{ englishText }}</div> -->
    </div>
</template>
  
<script>
// VUE
import {
    h,
    ref,
    reactive,
    watch,
    computed,
    getCurrentInstance,
    onMounted,
    onActivated,
    onDeactivated,
} from "vue";

// Components


export default {
    name:'DetectionTilte',
    components: {

    },
    props: ['title', 'englishText'],

    setup(props, context) {
        const _this = getCurrentInstance().proxy;
        let state = reactive({

        }); // 数据


        onMounted(async () => {

        });

        return {
            // 变量
            state,
            //方法
        };
    },
};
</script>
  
<style lang="scss" scoped>
.titleBox {
    width: 100%;
    height: 41px;
    position: relative;
    background-image: url('../assets/detection/a2.png');
    // background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    white-space: nowrap;
    .text {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
        margin-left: 62px;
        margin-bottom: 12px;
    }

    .englishText {
        font-size: 11px;
        font-family: DINPro;
        font-weight: 400;
        color: #2062A5;
        background: linear-gradient(0deg, #FFA902 0%, #0BCEFF 0%, #9BE4FF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-left: 8px;
        margin-bottom: 12px;
    }
}
</style>
  