<template>
  <div class="tool cc_tool_style">
    <el-icon
      style="cursor: pointer; right: 16px; top: 16px; position: absolute"
      size="24"
      color="rgba(105, 124, 167, 1)"
      @click="close()"
      ><Close
    /></el-icon>
    <div class="tool-title">基础标注</div>
    <div class="tool-types">
      <el-tooltip
        class="box-item"
        effect="dark"
        content="点"
        placement="bottom"
      >
        <div
          class="tool-icon"
          @click="init('点')"
          :class="type == '点' ? 'tool-true' : ''"
        >
          <img src="@/assets/images/tool/i1.png" />
        </div>
      </el-tooltip>
      <el-tooltip
        class="box-item"
        effect="dark"
        content="线"
        placement="bottom"
      >
        <div
          class="tool-icon"
          @click="init('线')"
          :class="type == '线' ? 'tool-true' : ''"
        >
          <img src="@/assets/images/tool/i2.png" />
        </div>
      </el-tooltip>
      <el-tooltip
        class="box-item"
        effect="dark"
        content="面"
        placement="bottom"
      >
        <div
          class="tool-icon"
          @click="init('面')"
          :class="type == '面' ? 'tool-true' : ''"
        >
          <img src="@/assets/images/tool/i3.png" />
        </div>
      </el-tooltip>
      <el-tooltip
        class="box-item"
        effect="dark"
        content="圆"
        placement="bottom"
      >
        <div
          class="tool-icon"
          @click="init('圆')"
          :class="type == '圆' ? 'tool-true' : ''"
        >
          <img src="@/assets/images/tool/i4.png" />
        </div>
      </el-tooltip>
      <div class="singleName">
        <el-input v-model="username" />
      </div>

      <img
        @click="pinFlyTo()"
        class="tool-img"
        src="@/assets/images/tool/view.png"
      />
      <img
        class="tool-del"
        :class="delIndex == 1 ? 'true' : ''"
        @click="clear"
        src="@/assets/images/tool/del.png"
      />
    </div>
    <template
      v-if="
        type && (json.polyline || json.billboard || json.polygon || json.circle)
      "
    >
      <div class="tool-row">
        <div class="text">颜色</div>
        <div
          :key="key"
          class="color"
          v-for="(val, key) in colorArr"
          :style="{ background: val }"
          @click="
            () => {
              colorIndex = key;
            }
          "
        >
          <el-icon v-show="key == colorIndex" :size="14" color="#ffffff">
            <Select />
          </el-icon>
        </div>
      </div>
      <template v-if="openCount">
        <div class="tool-row">
          <div class="label">测量数据</div>
        </div>
        <template v-if="type == '点'">
          <div class="tool-row">
            <div class="text">经纬模式</div>
            <el-radio-group v-model="pointType">
              <el-radio :label="1" style="color: rgba(205, 228, 248, 1)">
                十进制
              </el-radio>
              <el-radio :label="2" style="color: rgba(205, 228, 248, 1)">
                度分秒
              </el-radio>
            </el-radio-group>
          </div>
          <div class="tool-row">
            <div class="text">经度</div>
            <div v-if="pointType == 1">
              <el-input type="text" @change="changeJD" v-model="json.lng" />
              <span class="text">°</span>
            </div>

            <el-input type="text" v-else v-model="json.lng2" />
          </div>
          <div class="tool-row">
            <div class="text">纬度</div>
            <div v-if="pointType == 1">
              <el-input @change="changeWD" type="text" v-model="json.lat" />
              <span class="text">°</span>
            </div>

            <el-input type="text" v-else v-model="json.lat2" />
          </div>
          <div class="tool-row">
            <div class="text">ASL</div>
            <input class="input" :value="json.alt" type="text" />m
          </div>
          <!-- <div class="tool-row">
          <div class="text">AGL</div>
          <input class="input" :value="json.lat" type="text" />m
        </div> -->
        </template>
        <template v-else-if="type == '线'">
          <div class="tool-row">
            <div class="text">水平距离</div>
            <input
              class="input none"
              :value="Number(json.horizontalDistance).toFixed(2) + 'm'"
              type="text"
            />
          </div>
          <div class="tool-row">
            <div class="text">直线距离</div>
            <input
              class="input none"
              :value="Number(json.lineDistance).toFixed(2) + 'm'"
              type="text"
            />
          </div>
          <div class="tool-row">
            <div class="text">高度差</div>
            <input
              class="input none"
              :value="Number(json.heightDiff).toFixed(2) + 'm'"
              type="text"
            />
          </div>
        </template>
        <template v-else-if="type == '面'">
          <div class="tool-row">
            <div class="text">水平面积</div>
            <input
              class="input none"
              :value="json.horizontalArea"
              type="text"
            />
          </div>
          <div class="tool-row">
            <div class="text">水平周长</div>
            <input
              class="input none"
              :value="Number(json.horizontalPerimeter).toFixed(2) + 'm'"
              type="text"
            />
          </div>
          <div class="tool-row">
            <div class="text">高度差</div>
            <input
              class="input none"
              :value="Number(json.heightDiff).toFixed(2) + 'm'"
              type="text"
            />
          </div>
        </template>
        <template v-else-if="type == '圆'">
          <div class="tool-row">
            <div class="text">经纬模式</div>
            <el-radio-group v-model="pointType">
              <el-radio :label="1" style="color: rgba(205, 228, 248, 1)">
                十进制
              </el-radio>
              <el-radio :label="2" style="color: rgba(205, 228, 248, 1)">
                度分秒
              </el-radio>
            </el-radio-group>
          </div>
          <div class="tool-row">
            <div class="text">经度</div>
            <div v-if="pointType == 1">
              <el-input type="text" @change="changeJD" v-model="json.lng" />
              <span class="text">°</span>
            </div>

            <el-input type="text" v-else v-model="json.lng2" />
          </div>
          <div class="tool-row">
            <div class="text">纬度</div>
            <div v-if="pointType == 1">
              <el-input type="text" @change="changeWD" v-model="json.lat" />
              <span class="text">°</span>
            </div>

            <el-input type="text" v-else v-model="json.lat2" />
          </div>
          <!-- <div class="tool-row" v-if="pointType == 1">
          <div class="text">经度</div>
          <input class="input none" :value="json.lng + '°'" type="text" />
        </div>
        <div class="tool-row" v-if="pointType == 1">
          <div class="text">纬度</div>
          <input class="input none" :value="json.lat + '°'" type="text" />
        </div>
        <div class="tool-row" v-if="pointType == 2">
          <div class="text">经度</div>
          <input class="input none" :value="json.lng + '°'" type="text" />
        </div>
        <div class="tool-row" v-if="pointType == 2">
          <div class="text">纬度</div>
          <input class="input none" :value="json.lat + '°'" type="text" />
        </div> -->
          <div class="tool-row">
            <div class="text">ASL</div>
            <input class="input none" :value="json.alt" type="text" />
          </div>
          <!-- <div class="tool-row">
          <div class="text">AGL</div>
          <input class="input none" :value="json.alt" type="text" />
        </div> -->
          <div class="tool-row">
            <div class="text">水平半径</div>
            <input class="input none" :value="json.radius + 'm'" type="text" />
          </div>
          <div class="tool-row">
            <div class="text">水平面积</div>
            <input
              class="input none"
              :value="Number(json.horizontalArea).toFixed(2) + '㎡'"
              type="text"
            />
          </div>
          <div class="tool-row">
            <div class="text">水平周长</div>
            <input
              class="input none"
              :value="Number(json.horizontalPerimeter).toFixed(2) + 'm'"
              type="text"
            />
          </div>
        </template>

        <div class="tool-row">
          <div class="text">绘制者</div>
          <input class="input none" :value="creator" type="text" />
        </div>
      </template>
      <div
        class="celiang divFlex alignCenter flexCenter mousePoint"
        @click="openCount = !openCount"
      >
        <div class="text">
          {{ openCount ? "收起测量数据" : "展开测量数据" }}
        </div>
        <el-icon style="color: rgba(38, 120, 213, 1)"
          ><CaretTop v-if="openCount"
        /></el-icon>
        <el-icon style="color: rgba(38, 120, 213, 1)"
          ><CaretBottom v-if="!openCount"
        /></el-icon>
      </div>
      <div class="tool-row">
        <div class="text">文件夹</div>

        <el-cascader
          style="width: 180px; flex-grow: 1"
          :props="{
            emitPath: false,
            checkStrictly: true,
            value: 'id',
            label: 'name',
          }"
          v-model="infoData.parentId"
          :options="fileTree"
          filterable
          clearable
        />
        <div class="unit">
          <el-icon
            style="cursor: pointer"
            size="20"
            color="rgba(255, 255, 255, 1)"
            @click="addFile()"
            ><FolderAdd
          /></el-icon>
        </div>
      </div>
      <!-- <div class="tool-row divFlex">
        <div class="text">文件</div>
        <div class="divFlex">
          <div class="grow fileName">名称名称</div>
          <div class="unit mousePoint">
            <img src="@/assets/images/tool/del.png" alt="" />
          </div>
        </div>
      </div> -->

      <div class="tool-row">
        <div class="label">
          标注属性
          <span>(选填)</span>
        </div>
      </div>

      <div class="tool-row">
        <div class="btns">
          <div
            class="btn"
            :key="key"
            v-for="(val, key) in globalData.bzSelectData.extType"
            @click="keyTypeChange(val, key)"
            :class="val.value == infoData.extType ? 'true' : ''"
          >
            {{ val.label }}
          </div>
        </div>
      </div>

      <!-- <div class="tool-row">
        <div class="text">名称</div>
        <input class="input" v-model="infoData.name" type="text" />
      </div> -->

      <div class="tool-row">
        <div class="text">所属网格</div>

        <el-cascader
          :props="{
            emitPath: false,
            checkStrictly: false,
            value: 'id',
            label: 'title',
          }"
          v-model="infoData.gridId"
          :options="grids"
          filterable
          clearable
        />

        <!-- <el-select
          style="width: 170px"
          v-model="infoData.gridId"
          placeholder="请选择..."
          ref="selectUpResId2"
        >
          <el-option hidden :value="infoData.gridName" :label="infoData.gridName">
          </el-option>
          <el-tree
            style="width: 170px"
            :data="grids"
            :props="{
              children: 'children',
              label: 'title',
            }"
            :expand-on-click-node="false"
            :check-on-click-node="true"
            @node-click="gridClick"
          >
          </el-tree>
        </el-select> -->
      </div>

      <!-- <div class="tool-row">
        <div class="text">发布日期</div>
        <el-date-picker v-model="infoData.date" type="date" placeholder="" />
      </div> -->

      <!-- <div class="tool-row">
        <div class="text">面积</div>
        <input class="input" v-model="infoData.area" type="text" />
      </div> -->

      <div
        class="tool-row"
        v-for="(item, idx) in propertyData"
        :key="item + idx"
      >
        <div class="text">{{ item.label }}</div>
        <input
          v-if="item.controlType == 'input'"
          class="input"
          v-model="item[item.property]"
          type="text"
        />
        <input
          v-else-if="item.controlType == 'number'"
          class="input"
          v-model="item[item.property]"
          type="number"
        />
        <el-date-picker
          v-else-if="item.controlType == 'date'"
          v-model="item[item.property]"
          type="date"
          placeholder=""
        />

        <el-upload
          v-else-if="item.controlType == 'upload'"
          v-model:file-list="item[item.property]"
          class="upload-demo"
          :action="action"
          :headers="headers"
          multiple
          :on-preview="handlePreview"
          :limit="1"
        >
          <div class="uploadFileBtn divFlex alignCenter flexCenter mousePoint">
            点击上传
          </div>
        </el-upload>
      </div>
      <div class="tool-row divFlex">
        <div class="text">文件</div>
        <el-upload
          v-model:file-list="infoData.fileArr"
          class="upload-demo"
          :action="action"
          :headers="headers"
          multiple
          :limit="1"
          :on-remove="handleRemove"
          :on-success="handleSuccess"
        >
          <div class="uploadFileBtn divFlex alignCenter flexCenter mousePoint">
            点击上传
          </div>
        </el-upload>
      </div>
      <!-- <div class="tool-row">
        <div class="label">
          问题属性
          <span>(选填)</span>
        </div>
      </div>
      <div class="tool-row">
        <div class="text">问题类型</div>
        <el-select v-model="infoData.eventType" placeholder="请选择...">
          <el-option label="item.label" value="item.value"></el-option>
        </el-select>
      </div>
      <div class="tool-row">
        <div class="text">是否转报</div>
        <el-radio-group v-model="infoData.isZhuan">
          <el-radio :label="1" style="color: rgba(205, 228, 248, 1)">
            是
          </el-radio>
          <el-radio :label="2" style="color: rgba(205, 228, 248, 1)">
            否
          </el-radio>
        </el-radio-group>
      </div> -->
      <div class="yBtn" v-if="infoData.isLock">当前标注被锁定，无法编辑</div>
      <div class="tool-bottom" v-if="showSaveBtn && !infoData.isLock">
        <div class="no" @click="close()">取消</div>
        <div class="yes" @click="up()">保存</div>
      </div>
    </template>

    <transition name="el-fade-in-linear">
      <div v-if="addFileShow" class="fileAdd">
        <el-icon
          style="cursor: pointer; right: 16px; top: 16px; position: absolute"
          size="24"
          color="rgba(105, 124, 167, 1)"
          @click="addFileShow = false"
          ><Close
        /></el-icon>
        <div class="tool-title">创建文件夹</div>
        <div class="tool-row divFlex">
          上级：<el-cascader
            class="input"
            :props="{
              emitPath: false,
              checkStrictly: false,
              value: 'id',
              label: 'name',
            }"
            v-model="parentId"
            :options="fileTree"
            filterable
            clearable
          />
        </div>
        <div class="tool-row">
          名称：<input class="input" v-model="fileName" type="text" />
        </div>
        <div class="tool-bottom">
          <div class="no" @click="addFileShow = false">取消</div>
          <div class="yes" @click="addFileUp()">创建</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
let graphicLayer;
import http from "@/api/server";
import { CaretTop, CaretBottom } from "@element-plus/icons-vue";
export default {
  name: "Annotation",
  inject: ["globalData"],
  components: {
    CaretTop,
    CaretBottom,
  },
  props: {
    info: {
      default: {},
    },
  },
  data() {
    return {
      openCount: false,
      action: url2 + "/file/upload-img",
      headers: {
        Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
        "Blade-Auth": `bearer ` + this.getCookie("access_token"),
      },
      type: "",
      colorArr: ["#2D8CF0", "#19BE6B", "#FFBB00", "#E23C39", "#B620E0"],
      colorIndex: 0,
      file: "",
      pointType: 1,
      keyTypes: [],

      delIndex: 0,
      json: {},

      addFileShow: false,
      fileName: "",
      parentId: "",
      username: "",

      fileTree: [],
      propertyData: [],
      infoData: {},
      creator: "",
      op: 0, // 是否操作
      showSaveBtn: false,
      canSave: true,
    };
  },
  watch: {
    colorIndex(val) {
      if (this.op == 1) {
        this.showSaveBtn = true;
      }
      if (this.json.billboard) {
        this.json.billboard.setStyle({
          image: require(`../assets/images/tool/c${val + 1}.png`),
        });
      }
      if (this.json.polyline) {
        this.json.polyline.setStyle({
          color: this.colorArr[this.colorIndex],
          outlineColor: this.colorArr[this.colorIndex],
        });
      }
      if (this.json.polygon) {
        this.json.polygon.setStyle({
          color: this.colorArr[this.colorIndex],
          outlineColor: this.colorArr[this.colorIndex],
        });
      }

      if (this.json.circle) {
        this.json.circle.setStyle({
          color: this.colorArr[this.colorIndex],
          outline: true,
          outlineWidth: 4.0,
          outlineColor: this.colorArr[this.colorIndex],
        });
      }
    },
    info: {
      async handler(data) {
        console.log(data);
        this.showSaveBtn = false;

        this.op = 0;
        if (data.extType) {
          await http.propertyList({ typeId: data.extType }).then((res) => {
            this.propertyData = res.data;
          });
        }

        if (!graphicLayer) {
          graphicLayer = new mars3d.layer.GraphicLayer();
          map.addLayer(graphicLayer);
        }
        this.username = this.userInfo.user_name;

        if (this.userInfo.checkPostName) {
          this.username = this.username + "-" + this.userInfo.checkPostName;
        }
        this.init();
        if (!data.id) {
          this.op = 1;
          this.showSaveBtn = true;
          this.openCount = true;
          return;
        }
        this.openCount = false;
        http.cityFlightSkTagDetail({ id: data.id }).then((res) => {
          let { extra, flightSkTag } = res.data;

          let val = this.deepClone(flightSkTag);
          if (extra && extra.length > 0) {
            let extraData = JSON.parse(extra[0].data);
            let keys = Object.keys(extraData);
            if (this.propertyData && this.propertyData.length > 0) {
              this.propertyData.forEach((e) => {
                if (keys.includes(e.property)) {
                  e[e.property] = extraData[e.property];
                }
              });
            }
          }

          val.fileArr = val.fileArr ? val.fileArr : [];
          this.infoData = val;
          let obj = {
            Point: "点",
            LineString: "线",
            Polygon: "面",
            Circle: "圆",
          };
          this.type = obj[val.markType];
          this.username = val.name;
          let ext = this.fileTree.find((e) => {
            return e.value == val.extType;
          });
          if (ext) this.typeName = ext.label;

          let colorIndex = this.colorArr.findIndex((e) => {
            return e == val.color;
          });

          if (this.type == "点") {
            let image = require(
              `../assets/images/tool/c${colorIndex + 1}.png`
            );
            let dom = `<div class='divFlex alignCenter bzPinBox relative'>
                    <img src="${image}" />
                    <div class="bzName relative">${this.username}
                      <div class="bzName1">${this.username}</div>
                    </div>
            </div>`;

            let position = JSON.parse(val.coordinates);
            // let e = this.addBillboardPrimitive(
            //   graphicLayer,
            //   position,
            //   image,
            //   val
            // );
            let e = this.addDivGraphic(graphicLayer, dom, position, val);

            // e.flyTo();
            map.flyToPoint(position, {
              radius: Math.floor(Math.random() * (500 - 100 + 1) + 400), // 距离目标点的距离
              duration: 2,
            });
            this.pin = e;
            this.json = {
              billboard: e,
              lat: position[1],
              lng: position[0],
              alt: position[2] || 0,
              lng2: this.decimalToDMS(position[0], false),
              lat2: this.decimalToDMS(position[1], true),
            };

            // val.fileArr =
          } else if (this.type == "线") {
            // console.log(val.coordinates, "coordinatescoordinatescoordinates");
            if (val.coordinates) {
              let positions = JSON.parse(val.coordinates);
              let e = this.addDistanceMeasure(
                graphicLayer,
                positions,
                val,
                val.color
              );

              const {
                distance,
                distanceLastFd,
                distanceLastFdStr,
                distanceStr,
              } = e.measured;
              let json = {
                polyline: e,
                horizontalDistance: val.horizontalDistance || distance,
                lineDistance: val.lineDistance || distanceLastFd,
                heightDiff:
                  val.heightDiff ||
                  this.calculateHeightDifference(e.getCoordinates()),
                // maxHeight: e.getMaxHeight(),
                border: val.coordinates || e.getCoordinates(),
                lng: e.centerPoint.lng,
                lat: e.centerPoint.lat,
              };
              this.json = json;
              e.flyTo();
              this.pin = e;
            }
          } else if (this.type == "面") {
            if (val.coordinates) {
              let positions = JSON.parse(val.coordinates)[0];
              let e = this.addAreaMeasure(
                graphicLayer,
                positions,
                val,
                val.color
              );
              const { distance } = e;
              const { areaStr } = e.measured;
              let json = {
                polygon: e,
                horizontalArea: val.horizontalArea || areaStr,
                horizontalPerimeter: val.horizontalPerimeter || distance,
                heightDiff:
                  val.heightDiff ||
                  this.calculateHeightDifference(e.getCoordinates()),
                border: val.coordinates || [e.getCoordinates()],
                lng: e.centerPoint.lng,
                lat: e.centerPoint.lat,
              };
              this.json = json;
              e.flyTo();
              this.pin = e;
            }
          } else if (this.type == "圆") {
            if (val.coordinates) {
              let position = JSON.parse(val.coordinates);
              let e = new mars3d.graphic.CirclePrimitive({
                position,
                style: {
                  radius: Number(val.radius),
                  color: val.color ? val.color : "#13C982",
                  opacity: 0.1,
                  outline: true,
                  outlineColor: val.color ? val.color : "#13C982",
                  outlineWidth: 4.0,
                  clampToGround: true,
                },
              });
              graphicLayer.addGraphic(e);
              // let e = this.addWaterCircle(graphicLayer,position)
              e.setStyle({
                label: {
                  text: `面积：${(e.area / 1000).toFixed(2)}平方公里`,
                  background: "rgba(0,0,0,0.5)",
                },
              });
              const { area, distance, radius } = e;
              const { lat, lng, alt } = e.centerPoint;
              let json = {
                circle: e,
                lat,
                lng,
                alt,
                lng2: this.decimalToDMS(lng, false),
                lat2: this.decimalToDMS(lat, true),
                horizontalArea: val.horizontalArea || area,
                horizontalPerimeter: val.horizontalPerimeter || distance,
                radius,
              };
              this.json = json;
              // e.flyTo();
              map.flyToPoint(position, {
                radius: Math.floor(Math.random() * (500 - 100 + 1) + 400), // 距离目标点的距离
                duration: 2,
              });
              this.pin = e;
            }
          }
          setTimeout(() => {
            this.colorIndex = colorIndex >= 0 ? colorIndex : 0;
            this.op = 1;
          }, 1000);
        });
      },
      deep: true,
      immediate: true,
    },
    type() {
      if (this.op == 1) {
        this.showSaveBtn = true;
      }
    },
    username() {
      if (this.op == 1) {
        this.showSaveBtn = true;
      }
    },

    propertyData: {
      handler(data) {
        if (this.op == 1) {
          this.showSaveBtn = true;
        }
      },
      deep: true,
    },
    json: {
      handler(data) {
        if (this.op == 1) {
          this.showSaveBtn = true;
        }
      },
      deep: true,
    },
    infoData: {
      handler(data) {
        if (this.op == 1) {
          this.showSaveBtn = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    path() {
      return this.$route.path;
    },
    userInfo() {
      if (!this.getCookie("userInfo")) return {};
      return JSON.parse(
        decodeURIComponent(this.getCookie("userInfo"))
          .replace(/%22/g, '"')
          .replace(/%2C/g, ",")
      );
    },
    grids() {
      return this.globalData.chooseBtns.所属网格;
    },
  },
  methods: {
    changeJD(val) {
      this.json.lng2 = this.decimalToDMS(val, false);
    },
    changeWD(val) {
      this.json.lat2 = this.decimalToDMS(val, true);
    },
    pinFlyTo() {
      if (this.pin) this.pin.flyTo();
    },
    handleRemove(file, uploadFiles) {
      let idx = this.infoData.fileArr.findIndex((e) => {
        return e.url == file.url;
      });
      this.infoData.fileArr.splice(idx, 1);
    },
    handleSuccess(res, file) {
      if (!this.infoData.fileArr) this.infoData.fileArr = [];
      this.infoData.fileArr.push({
        name: file.name,
        url: res.data.url,
      });

      console.log(this.infoData);
    },
    close() {
      this.$emit("close");
      this.globalData.bzShow = false;
    },
    addFile() {
      this.addFileShow = true;
    },
    closeFile() {
      this.fileName = "";
      this.parentId = "";
      this.addFileShow = false;
    },
    addFileUp() {
      if (!this.fileName)
        return this.$message({
          type: "warning",
          message: "请填写文件夹名称！",
        });
      http
        .cityFlightSkTagSave({
          flightSkTag: {
            parentId: this.parentId,
            name: this.fileName,
            fileType: "g",
          },
        })
        .then((res) => {
          this.closeFile();
          this.getFileTree();
          this.globalData.updateFile = new Date().getTime();
        });
    },
    keyTypeChange(val, key) {
      this.infoData.extType = val.value;
      this.typeName = val.label;
      http.propertyList({ typeId: val.value }).then((res) => {
        this.propertyData = res.data;
      });
    },
    clear() {
      if (this.delIndex == 0) {
        this.delIndex = 1;
      } else if (this.delIndex == 1) {
        this.init();
      }
    },
    decimalToDMS(decimal, isLatitude) {
      // 判断是否为纬度，纬度范围-90到90，经度范围-180到180
      // 这里假设isLatitude为false表示经度
      let direction = isLatitude
        ? decimal < 0
          ? "S"
          : "N"
        : decimal < 0
        ? "W"
        : "E";
      decimal = Math.abs(decimal); // 转换为正值进行计算

      let degrees = Math.floor(decimal);
      let minutes = Math.floor((decimal - degrees) * 60);
      let seconds =
        Math.round(((decimal - degrees) * 60 - minutes) * 60 * 1000) / 1000; // 乘以1000取整后再除以1000保留到小数点后三位

      // 格式化秒数，确保它始终是三位数
      seconds = seconds.toString().padStart(3, "0");

      return `${degrees}°${minutes}'${seconds}"${direction}`;
    },
    calculateHeightDifference(locations) {
      let maxHeight = -Infinity;
      let minHeight = Infinity;

      for (let i = 0; i < locations.length; i++) {
        const height = locations[i][2];

        if (height > maxHeight) {
          maxHeight = height;
        }

        if (height < minHeight) {
          minHeight = height;
        }
      }

      return maxHeight - minHeight;
    },
    init(val = "") {
      // console.clear();

      this.type = val;
      this.delIndex = 0;
      this.colorIndex = 0;
      // this.btnShow = val ? true : false;
      this.json = {};
      this.closeFile();
      // this.keyTypes = this.keyTypes.map((e) => {
      //   e.is = false;
      //   return e;
      // });
      graphicLayer.clear();
      if (val == "点") {
        graphicLayer.startDraw({
          type: "billboard",
          style: {
            image: require("../assets/images/tool/c1.png"),
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          },
          success: (e) => {
            // console.log(e.point);
            const { lat, lng, alt } = e.point;
            let json = {
              billboard: e,
              lat,
              lng,
              alt,
              lng2: this.decimalToDMS(lng, false),
              lat2: this.decimalToDMS(lat, true),
            };
            // this.getHeight([lng,lat,alt]).then(result=>{
            //   console.log(result,'resultresultresult')
            // })
            this.json = json;
            // e.flyTo();
            map.flyToPoint(e.centerPoint, {
              radius: Math.floor(Math.random() * (500 - 100 + 1) + 400), // 距离目标点的距离
              duration: 2,
            });
            this.pin = e;
          },
        });
      } else if (val == "线") {
        graphicLayer.startDraw({
          type: "distanceMeasure",
          style: {
            color: this.colorArr[this.colorIndex],
            outlineColor: this.colorArr[this.colorIndex],
          },
          success: (e) => {
            const { distance, distanceLastFd, distanceLastFdStr, distanceStr } =
              e.measured;
            let json = {
              polyline: e,
              horizontalDistance: distance,
              lineDistance: distanceLastFd,
              heightDiff: this.calculateHeightDifference(e.getCoordinates()),
              // maxHeight: e.getMaxHeight(),
              border: e.getCoordinates(),
              lng: e.centerPoint.lng,
              lat: e.centerPoint.lat,
            };
            console.log(e);
            this.json = json;
            e.flyTo();
            this.pin = e;
          },
        });
      } else if (val == "面") {
        graphicLayer.startDraw({
          type: "areaMeasure",
          style: {
            color: this.colorArr[this.colorIndex],
            opacity: 0.1,
            outline: true,
            outlineColor: this.colorArr[this.colorIndex],
            outlineWidth: 4.0,
            clampToGround: true,
          },
          success: (e) => {
            const { distance } = e;
            const { areaStr } = e.measured;
            console.log(e);
            let json = {
              polygon: e,
              horizontalArea: areaStr,
              horizontalPerimeter: distance,
              heightDiff: this.calculateHeightDifference(e.getCoordinates()),
              border: [e.getCoordinates()],
              lng: e.centerPoint.lng,
              lat: e.centerPoint.lat,
            };
            this.json = json;
            e.flyTo();
            this.pin = e;
          },
        });
      } else if (val == "圆") {
        graphicLayer.startDraw({
          type: "circleP",
          style: {
            color: this.colorArr[this.colorIndex],
            opacity: 0.1,
            outline: true,
            outlineColor: this.colorArr[this.colorIndex],
            outlineWidth: 4.0,
            clampToGround: true,
          },
          success: (e) => {
            e.setStyle({
              label: {
                text: `面积：${(e.area / 1000).toFixed(2)}平方公里`,
                background: "rgba(0,0,0,0.5)",
              },
            });
            const { area, distance, radius } = e;
            const { lat, lng, alt } = e.centerPoint;
            let json = {
              circle: e,
              lat,
              lng,
              alt,
              lng2: this.decimalToDMS(lng, false),
              lat2: this.decimalToDMS(lat, true),
              horizontalArea: area,
              horizontalPerimeter: distance,
              radius,
            };
            this.json = json;
            // e.flyTo();
            map.flyToPoint(e.centerPoint, {
              radius: Math.floor(Math.random() * (500 - 100 + 1) + 400), // 距离目标点的距离
              duration: 2,
            });
            this.pin = e;
          },
        });
      }
    },
    gridClick(data) {
      this.infoData.gridName = data.title;
      this.infoData.gridId = data.id;

      this.$refs.selectUpResId2.blur();
    },
    up() {
      if (!this.canSave) return;
      this.canSave = false;
      let obj = {
        点: "Point",
        线: "LineString",
        面: "Polygon",
        圆: "Circle",
      };
      let coordinates;
      switch (this.type) {
        case "点":
          coordinates = [
            Number(this.json.lng),
            Number(this.json.lat),
            this.json.alt,
          ];
          break;
        case "线":
          coordinates = this.json.border;
          break;
        case "面":
          coordinates = this.json.border;
          break;
        case "圆":
          coordinates = [this.json.lng, this.json.lat];
          break;

        default:
          break;
      }
      let markType = obj[this.type];
      let color = this.colorArr[this.colorIndex];

      let extra = {};
      let { name, parentId, uuid, gridId, puuid, fileType, radius, extType } =
        this.infoData;
      this.infoData.markType = markType;
      this.infoData.color = color;
      let tempObj = { coordinates: coordinates };
      if (typeof tempObj.coordinates != "string") {
        tempObj.coordinates = JSON.stringify(tempObj.coordinates);
      }
      this.infoData = Object.assign(this.infoData, tempObj);
      // console.log(this.infoData);

      extra.typeId = extType;

      let pro = {};
      this.propertyData.forEach((el) => {
        pro[el.property] = el[el.property];
      });
      extra.data = JSON.stringify(pro);
      extra.typeName = this.typeName;

      if (this.infoData.fileArr && this.infoData.fileArr.length > 0) {
        this.infoData.fileList = JSON.stringify(this.infoData.fileArr);
      }

      console.log(this.infoData);
      console.log(this.json);
      let flightSkTag = Object.assign(this.infoData, this.json);

      flightSkTag.fileType = "e";
      flightSkTag.name = this.username;
      flightSkTag.creator = this.creator;
      flightSkTag.position = this.json.lng + "," + this.json.lat;
      if (!this.infoData.extType) {
        this.canSave = true;
        return this.$message({
          type: "warning",
          message: "请选择标注属性",
        });
      }

      if (this.infoData.uuid) {
        let newObj = Object.assign(flightSkTag, {});
        if (this.type == "点") delete newObj.billboard;
        if (this.type == "线") delete newObj.polyline;
        if (this.type == "面") delete newObj.polygon;
        if (this.type == "圆") delete newObj.circle;
        if (newObj.dataFrom == 1) {
          newObj.parentId = "";
          newObj.projectUuid = "";
        }

        if (newObj.coordinates && (this.type == "点" || this.type == "圆")) {
          let a = JSON.parse(newObj.coordinates);
          a.forEach((e, idx) => {
            a[idx] = Number(e);
          });
          newObj.coordinates = JSON.stringify(a);
        }
        http
          .cityFlightSkTagUpdate({ flightSkTag: newObj, extra: [extra] })
          .then((res) => {
            this.globalData.bzUpdata = new Date().getTime();
            this.init();
            this.canSave = true;
            this.globalData.bzShow = false;
            this.globalData.bzData = false;
            return this.$message({
              type: "success",
              message: res.msg,
            });
          })
          .catch((err) => {
            this.canSave = true;
          });
      } else {
        http
          .cityFlightSkTagSave({ flightSkTag, extra: [extra] })
          .then((res) => {
            this.globalData.bzUpdata = new Date().getTime();
            this.globalData.bzShow = false;
            this.globalData.bzData = false;
            this.init();
            this.canSave = true;
            return this.$message({
              type: "success",
              message: res.msg,
            });
          })
          .catch((err) => {
            this.canSave = true;
          });
      }
    },
    getFileTree() {
      http.groupTree().then((res) => {
        res.data.forEach((e) => {
          if (!e.hasChildren) {
            delete e.children;
          }
        });
        this.fileTree = res.data;
      });
    },
  },

  mounted() {
    if (!graphicLayer) {
      graphicLayer = new mars3d.layer.GraphicLayer();
      window.map.addLayer(graphicLayer);
    }
    this.creator = this.userInfo.user_name;

    this.globalData.showR = false;
    this.getFileTree();
    // http.getByCodeOther({ groupCode: 1 }).then((res) => {
    //   this.keyTypes = res.data.map((e) => {
    //     return {
    //       is: false,
    //       ...e,
    //     };
    //   });
    // });
  },
  beforeUnmount() {
    this.init();
    if (!["/Annotation"].includes(this.path)) this.globalData.showR = true;
  },
};
</script>
<style lang="scss" scoped>
.fileName {
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}
.fileAdd {
  width: 240px;
  height: auto;
  background: rgba(2, 11, 18, 0.9);
  border-radius: 10px;
  border: 1px solid rgba(2, 152, 251, 0.9);
  position: fixed;
  right: calc(50% - 120px);
  top: calc(50% - 90px);
  z-index: 20;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .tool-title {
    font-size: 18px !important;
  }
  input {
    width: 100% !important;
  }
}
.tool {
  width: 300px;
  background: rgba(2, 11, 18, 0.9);
  border-radius: 10px;
  border: 1px solid rgba(2, 152, 251, 0.9);
  position: fixed;
  right: 10px;
  top: 76px;
  z-index: 20;
  padding: 16px;
  box-sizing: border-box;
  .tool-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 0;
    div {
      width: 100px;
      height: 30px;
      border-radius: 15px;
      font-size: 14px;
      color: #cde4f8;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      box-sizing: border-box;
      cursor: pointer;
    }
    div + div {
      margin-left: 10px;
    }
    .no {
      background: rgba(105, 124, 167, 0.25);
      border: 1px solid rgba(105, 124, 167, 0.5);
    }
    .yes {
      background: rgba(0, 178, 255, 0.25);
      border: 1px solid rgba(0, 178, 255, 0.5);
    }
  }
  .tool-title {
    font-weight: 600;
    font-size: 24px;
    color: #0ff3ee;
    margin-bottom: 20px;
  }
  .tool-types {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .tool-icon {
      width: 30px;
      height: 30px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      overflow: hidden;
      position: relative;
      img {
        width: auto;
        height: 20px;
      }
    }
    .tool-true {
      background: #020b12;
      border: 1px solid #0298fb;
      &::before {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-top: 12px solid #0298fb;
      }
    }
    .tool-input {
      flex-grow: 1;
      height: 30px;
      background: rgba(105, 124, 167, 0.2);
      border-radius: 3px;
      margin-left: 10px;
    }
    .tool-img {
      width: 14px;
      height: 14px;
      margin-left: 10px;
      cursor: pointer;
    }
    .tool-del {
      padding: 4px;
      width: 14px;
      height: 14px;
      border-radius: 2px;
      margin-left: 10px;
      cursor: pointer;
      &.true {
        background-color: red;
      }
    }
  }
  .tool-row {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    font-size: 14px;
    color: #697ca7;
    white-space: nowrap;
    .btns {
      display: flex;
      flex-wrap: wrap;
      .btn {
        width: 80px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        box-sizing: border-box;

        background: rgba(17, 58, 98, 0.75);
        border-radius: 4px;
        border: 1px solid #2678d5;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: rgba(138, 199, 255, 1);
        opacity: 0.3;
        cursor: pointer;
        margin: 0 4px 4px 0;
        &:hover {
          opacity: 0.55;
        }
        &.true {
          opacity: 1 !important;
        }
      }
    }

    .text {
      font-size: 14px;
      color: #697ca7;
      min-width: 70px;
    }
    .label {
      font-size: 14px;
      color: #cde4f8;
      display: flex;
      align-items: center;
      min-width: 70px;
      &::before {
        content: "";
        display: inline-block;
        width: 4px;
        height: 14px;
        background: #0298fb;
        border-radius: 2px;
        margin-right: 6px;
      }
      span {
        color: rgba(92, 94, 95, 1);
        margin-left: 10px;
      }
    }
    .select {
      width: 170px;
      height: 30px;
      background: rgba(105, 124, 167, 0.2);
      border-radius: 3px;
      border: 0;
      color: rgba(205, 228, 248, 1);
      padding: 0 8px;
      box-sizing: border-box;
    }
    .input {
      width: 170px;
      height: 30px;
      background: rgba(105, 124, 167, 0.2);
      border-radius: 3px;
      border: 0;
      color: rgba(205, 228, 248, 1);
      padding: 0 15px;
      box-sizing: border-box;
      font-size: 14px;

      &.none {
        background: transparent !important;
        padding: 0 !important;
        pointer-events: none;
      }
    }
    .unit {
      font-size: 14px;
      color: #cde4f8;
      margin-left: 8px;
    }
    .color {
      width: 20px;
      height: 20px;
      border-radius: 3px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.uploadFileBtn {
  width: 70px;
  height: 24px;
  background: rgba(105, 124, 167, 0.2);
  border-radius: 3px;

  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 12px;
  color: #2678d5;
  line-height: 24px;
}

.yBtn {
  width: 260px;
  height: 40px;
  background: rgba(255, 203, 80, 0.1);
  border-radius: 5px;
  border: 1px solid rgba(255, 203, 80, 0.2);

  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #ffcb50;
  text-align: center;
  line-height: 40px;
}

.celiang {
  height: 26px;
  background: rgba(38, 120, 213, 0.2);
  border-radius: 5px;
  margin-bottom: 20px;
  .text {
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 12px;
    color: #2678d5;
  }
}
</style>
