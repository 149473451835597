<template>
  <div class="bottomBtn">
    <div
      v-for="(item, idx) in arr"
      :key="item + idx"
      :style="size ? 'width:' + size + 'px' : ''"
      @click="chooseThis(item.value)"
      :class="btn == item.value ? 'activeBtn' : 'disBtn'"
    >
      <span>{{ item.label }}</span>
    </div>
  </div>
</template>

<script>
import {
  h,
  ref,
  reactive,
  watch,
  computed,
  getCurrentInstance,
  onMounted,
  onActivated,
  onDeactivated,
} from "vue";

export default {
  props: ["arr", "size",'btn'],
  setup(props, ctx) {
    const _this = getCurrentInstance().proxy;
    const state = reactive({
 
    });
    const chooseThis = (item) => {
      ctx.emit("chooseThis", item);
    };
    onMounted(() => {});

    return {
      state,
      chooseThis,
    };
  },
};
</script>

<style lang="scss" scoped>
.bottomBtn {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  cursor: pointer;

  .activeBtn {
    height: 24px;
    border: 1px solid rgba(38, 120, 213, 1);
    border-radius: 5px;
    padding: 4px 9px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    background: rgba(17, 58, 98, 0.75);
    border: 1px solid #2678d5;
    border-radius: 4px;
    span {
      font-size: 14px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #738ea2;
      background: linear-gradient(
        to top,
        rgba(138, 199, 255, 1) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .disBtn {
    height: 24px;
    background: rgba(17, 58, 98, 0.21);
    border: 1px solid rgba(38, 120, 213, 0.3);
    border-radius: 5px;
    padding: 4px 9px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

    span {
      font-size: 14px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: rgba(115, 142, 162, 1);
    }
  }
}
</style>