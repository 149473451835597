<template>
  <!-- 菜单 -->

  <div class="nav_box">
    <div class="centertBox mousePoint">
      <img src="@/assets/images/bbb.png" class="backtre" />

      <div class="navItems column bet">
        <div class="bet">
          <div
            :class="state.idx == item.id ? 'atvBox1' : 'disBox1'"
            v-for="(item, idx) in menu.slice(0, 3)"
            :key="item + idx"
            @click="chooseThis(item, idx)"
          >
            <div class="item column flexCenter alignCenter">
              <img
                :src="
                  state.idx == item.id
                    ? require(`../../assets/dc/${item.id}.png`)
                    : require(`../../assets/dc/${item.id}s.png`)
                "
                alt=""
              />
              <span class="titleText">{{ item.name }}</span>
            </div>
          </div>
        </div>
        <div class="bet">
          <div
            :class="state.idx == item.id ? 'atvBox' : 'disBox'"
            v-for="(item, idx) in menu.slice(3, 5)"
            :key="item + idx"
            @click="chooseThis(item, idx)"
          >
            <div class="item column flexCenter alignCenter">
              <img
                :src="
                  state.idx == item.id
                    ? require(`../../assets/dc/${item.id}.png`)
                    : require(`../../assets/dc/${item.id}s.png`)
                "
                alt=""
              />
              <span class="titleText">{{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  onBeforeUpdate,
  onUpdated,
  onBeforeUnmount,
  onUnmounted,
  onActivated,
  onDeactivated,
  inject,
} from "vue";
// import wxlogin from "vue-wxlogin";
import { ref, reactive, toRefs, watch, computed } from "vue";
import { useStore, mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { useRouter } from "vue-router";

// ----动画-------
var SEPARATION = 100,
  AMOUNTX = 60,
  AMOUNTY = 40;
var container;
var camera, scene, renderer;
var particles,
  particle,
  count = 0;
var mouseX = 0,
  mouseY = 0;
var windowHalfX = window.innerWidth / 2;
var windowHalfY = window.innerHeight / 2;

$(function () {
  init(); //初始化
  animate(); //动画效果
});

//初始化
function init() {
  container = document.createElement("div"); //创建容器
  // document.body.appendChild(container); //将容器添加到页面上
  $(".nav_box").append(container);
  camera = new THREE.PerspectiveCamera(
    120,
    window.innerWidth / window.innerHeight,
    1,
    1500
  ); //创建透视相机设置相机角度大小等
  camera.position.set(0, 450, 2000); //设置相机位置

  scene = new THREE.Scene(); //创建场景
  particles = new Array();

  var PI2 = Math.PI * 2;
  //设置粒子的大小，颜色位置等
  var material = new THREE.ParticleCanvasMaterial({
    color: 0x0f96ff,
    vertexColors: true,
    size: 4,
    program: function (context) {
      context.beginPath();
      context.arc(0, 0, 0.01, 0, PI2, true); //画一个圆形。此处可修改大小。
      context.fill();
    },
  });
  //设置长条粒子的大小颜色长度等
  var materialY = new THREE.ParticleCanvasMaterial({
    color: 0xffffff,
    vertexColors: true,
    size: 1,
    program: function (context) {
      context.beginPath();
      //绘制渐变色的矩形
      var lGrd = context.createLinearGradient(-0.008, 0.25, 0.016, -0.25);
      lGrd.addColorStop(0, "#16eff7");
      lGrd.addColorStop(1, "#0090ff");
      context.fillStyle = lGrd;
      context.fillRect(-0.008, 0.25, 0.016, -0.25); //注意此处的坐标大小
      //绘制底部和顶部圆圈
      context.fillStyle = "#0090ff";
      context.arc(0, 0, 0.008, 0, PI2, true); //绘制底部圆圈
      context.fillStyle = "#16eff7";
      context.arc(0, 0.25, 0.008, 0, PI2, true); //绘制顶部圆圈
      context.fill();
      context.closePath();
      //绘制顶部渐变色光圈
      var rGrd = context.createRadialGradient(0, 0.25, 0, 0, 0.25, 0.025);
      rGrd.addColorStop(0, "transparent");
      rGrd.addColorStop(1, "#16eff7");
      context.fillStyle = rGrd;
      context.arc(0, 0.25, 0.025, 0, PI2, true); //绘制一个圆圈
      context.fill();
    },
  });

  //循环判断创建随机数选择创建粒子或者粒子条
  var i = 0;
  for (var ix = 0; ix < AMOUNTX; ix++) {
    for (var iy = 0; iy < AMOUNTY; iy++) {
      var num = Math.random() - 0.1;
      if (num > 0) {
        particle = particles[i++] = new THREE.Particle(material);
        // console.log("material");
      } else {
        particle = particles[i++] = new THREE.Particle(materialY);
        // console.log("materialY");
      }
      //particle = particles[ i ++ ] = new THREE.Particle( material );
      particle.position.x = ix * SEPARATION - (AMOUNTX * SEPARATION) / 2;
      particle.position.z = iy * SEPARATION - (AMOUNTY * SEPARATION) / 2;
      scene.add(particle);
    }
  }

  renderer = new THREE.CanvasRenderer();
  renderer.setSize(window.innerWidth, window.innerHeight);
  container.appendChild(renderer.domElement);
  //document.addEventListener( 'mousemove', onDocumentMouseMove, false );
  //document.addEventListener( 'touchstart', onDocumentTouchStart, false );
  //document.addEventListener( 'touchmove', onDocumentTouchMove, false );
  window.addEventListener("resize", onWindowResize, false);
}

//浏览器大小改变时重新渲染
function onWindowResize() {
  windowHalfX = window.innerWidth / 2;
  windowHalfY = window.innerHeight / 2;
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
}

function animate() {
  requestAnimationFrame(animate);
  render();
}

//将相机和场景渲染到页面上
function render() {
  var i = 0;
  //更新粒子的位置和大小
  for (var ix = 0; ix < AMOUNTX; ix++) {
    for (var iy = 0; iy < AMOUNTY; iy++) {
      particle = particles[i++];
      //更新粒子位置
      particle.position.y =
        Math.sin((ix + count) * 0.3) * 50 + Math.sin((iy + count) * 0.5) * 50;
      //更新粒子大小
      particle.scale.x =
        particle.scale.y =
        particle.scale.z =
          ((Math.sin((ix + count) * 0.3) + 1) * 4 +
            (Math.sin((iy + count) * 0.5) + 1) * 4) *
          50; //正常情况下再放大100倍*1200
    }
  }

  renderer.render(scene, camera);
  count += 0.1;
}

// -----------------------结束------
export default {
  // components: { wxlogin },

  setup(props, context) {
    const _this = getCurrentInstance().proxy;
    const globalData = inject("globalData");
    const router = useRouter();
    const store = useStore();
    const menu = computed(() => _this.$store.state.index.menu);
    const partyUrl = computed(() => _this.$store.state.index.partyUrl);
    const path = computed(() => _this.$route.path);
    const state = reactive({
      arr: [],
      idx: 0,
      nowidx: 0,
      nowSecidx: 0,
      Secidx: 0,
      historyMenu: [],

      mouseStatus: false,
    });
    const chooseThis = (item, idx) => {
      if (state.idx == item.id) {
        globalData.allNavShow = false;
        return
      }
      state.idx = item.id;
      if (item.id != "1735188093220192257") {
        context.emit("setSecMenu", item);
      } else {
        context.emit("setSecMenu", false);
      }

      _this.globalData.logoTitle = item.name;

      if (item.isSidebar) {
        state.Secidx = 0;
        _this.$router.push(globalData.menuArr[0].url);
        return;
      }
      _this.$store.commit("index/set", { val: 2, key: "navshow" });
      let flg = false;
      if (state.historyMenu.length > 0) {
        state.historyMenu.forEach((e) => {
          if (e.name == item.name) {
            flg = true;
            item = e;
          }
        });
      }
      if (!flg) {
        state.historyMenu.push(item);
      }

      let flag = false;
      let href = window.location.href;
      if (item.children && item.children.length > 0) {
        item.children.forEach((e, index) => {
          // _this.setCookie('secMenuName', e.secMenu, 7)
          // 刷新时使用 若是菜单里的  转跳菜单路由
          if (e.url && href.indexOf(e.url) != -1) {
            flag = true;
            chooseThisSec(e, index, item);
          }
          // 页面刷新时候方法  是党群的路由  就把菜单设置成党群网格
          if (
            item.name == "党建引领" &&
            (href.indexOf("/PartyMap") != -1 ||
              href.indexOf("/PartyTalent") != -1 ||
              href.indexOf("/RedAlert") != -1 ||
              href.indexOf("/PartyReport") != -1 ||
              href.indexOf("/Alliance") != -1)
          ) {
            if (e.name == "党群网格") {
              flag = true;
              e.visite = true;
              state.Secidx = e.id;
            }
          }
          // if (
          //   item.name == "综治平安" &&
          //   href.indexOf("/ImportantPlace") != -1
          // ) {
          //   if (e.name == "重点部件场所") {
          //     flag = true;
          //     e.visite = true;
          //     state.Secidx = e.id;
          //   }
          // }
        });

        if (flag) return;

        item.children.forEach((e, index) => {
          if (e.visite == true) {
            flag = true;
            chooseThisSec(e, index, item);
          }
        });
        if (flag) return;

        chooseThisSec(item.children[0], 0, item);
      }
    };
    const chooseThisSec = (secItem, idx, item) => {
      _this.setCookie("secName", JSON.stringify({ name: secItem.name }));
      globalData.secUrl = secItem.url;
      item.children.forEach((e, index) => {
        e.visite = false;
      });
      item.children[idx].visite = true;
      state.Secidx = item.children[idx].id;
      _this.$router.push(secItem.url);
    };
    onActivated(() => {});

    onMounted(async () => {
      let href = window.location.href;
      // console.log(menu)
      menu.value.forEach((e, idx) => {
        console.log(e);
        if (e.children && e.children.length > 0 && !e.isSidebar) {
          e.children.forEach((el, i) => {
            if (href.indexOf(el.url) != -1 && el.url) {
              chooseThis(e, idx);
            }
            // if (href.indexOf("/ImportantPlace") != -1) {
            //   if (e.name == "综治平安") {
            //     chooseThis(e, idx);
            //   }
            // }
          });
        } else if (e.isSidebar) {
          globalData.menuArr = e.children;
        }
      });
    });
    return {
      state,
      globalData,
      menu,
      chooseThis,
    };
  },
};
</script>
<style lang="scss" scoped>
.nav_box {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  background-image: url("../../assets/images/loback.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  position: fixed;
  left: 0;
  top: 0;
  z-index: 11;

  display: flex;
  align-items: center;
  justify-content: center;

  .centertBox {
    width: 1120px;
    height: 540px;
    // width: 100%;
    // padding: 270px 400px;
    // box-sizing: border-box;

    .backtre {
      position: absolute;
      top: 0;
      width: auto;
      object-fit: contain;
      z-index: -1;
    }

    .titleText {
      font-family: Source Han Sans SC;
      font-weight: bold;
      font-size: 26px;
      color: #cde4f8;
      background: linear-gradient(0deg, #22e2ff 0%, #a6ffef 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-top: 35px;
    }

    .navItems {
      width: 100%;
      height: 100%;

      .disBox1 {
        width: 360px;
        height: 260px;
        background: linear-gradient(to top, #2a5564 0%, #283142 100%);
        padding: 4px;
        box-sizing: border-box;
        border-radius: 20px;
        .item {
          width: 100%;
          height: 100%;
          background: linear-gradient(
            to bottom,
            rgba(37, 66, 76, 1) 0%,
            rgba(40, 49, 66, 0.75) 100%
          );
          border-radius: 20px;
        }
      }

      .atvBox1 {
        width: 360px;
        height: 260px;
        background: rgba(0, 101, 136, 1);
        padding: 4px;
        box-sizing: border-box;
        border-radius: 20px;
        .item {
          width: 100%;
          height: 100%;
          background: linear-gradient(
            to bottom,
            rgba(37, 66, 76, 1) 0%,
            rgba(40, 49, 66, 0.75) 100%
          );
          border-radius: 20px;
        }
      }

      .disBox {
        width: 550px;
        height: 260px;
        background: linear-gradient(to top, #2a5564 0%, #283142 100%);
        padding: 4px;
        box-sizing: border-box;
        border-radius: 20px;
        .item {
          width: 100%;
          height: 100%;
          background: linear-gradient(
            to bottom,
            rgba(37, 66, 76, 1) 0%,
            rgba(40, 49, 66, 0.75) 100%
          );
          border-radius: 20px;
        }
      }

      .atvBox {
        width: 550px;
        height: 260px;
        background: rgba(0, 101, 136, 1);
        padding: 4px;
        box-sizing: border-box;
        border-radius: 20px;
        .item {
          width: 100%;
          height: 100%;

          background: linear-gradient(
            to bottom,
            rgba(37, 66, 76, 1) 0%,
            rgba(40, 49, 66, 0.75) 100%
          );
          border-radius: 20px;
        }
      }
    }
  }
}
</style>
