<template>
  <div>
    <transition name="el-fade-in-linear">
      <div v-if="!viewShow" @click="viewShow = true" class="presupposition_view_button" v-html="'预设视角'"></div>
    </transition>
    <transition name="el-zoom-in-bottom">
      <div class="presupposition_views" v-if="viewShow"
        element-loading-background="rgba(0, 0, 0, 0.8)">
        <!-- 关闭按钮 -->
        <img class="presupposition_views_clear" @click="viewShow = false" :src="require('@/assets/dy/关闭.png')" />

        <div class="presupposition_views-left-icon" @click="switchView('左')">
          <i class="el-icon-arrow-left"></i>
        </div>
        <div class="presupposition_views_orders">
          <!-- 视口列表  val.code === code ? 'presupposition_views_order_true' : '', -->
          <div :class="['presupposition_views_order']" v-for="(val, key) in array" :key="key + 'array'">
            <div class="presupposition_views_order_image">
              <img @click="clickGoPreset(val.number)" class="presupposition_views_order_image_icon"
                :src="require('@/assets/dy/播放.png')" />
              <el-image @click="clickGoPreset(val.number)" class="presupposition_views_order_image_img" :src="val.images"
                fit="cover"></el-image>
              <template v-if="setUp">
                <img :src="require('@/assets/dy/垃圾桶.png')" v-if="!val.delShow" @click="val.delShow = true"
                  class="presupposition_views_order_image_del_view" alt="" />

                <div class="presupposition_views_order_addbox" v-else style="background: rgba(11, 11, 28, 0.8)">
                  <div class="presupposition_views_order_addbox_del">
                    是否确认删除？
                  </div>

                  <div class="presupposition_views_order_addbox_row" v-if="val.delShow">
                    <button class="button_no" @click="val.delShow = false">
                      取消
                    </button>
                    <button class="button_clear" @click="clear(val,key)">
                      确定删除
                    </button>
                  </div>
                </div>
              </template>
            </div>
            <div class="presupposition_views_order_name">
              {{ val.name }}
            </div>
          </div>

          <!-- 添加视口 -->
          <div class="presupposition_views_order" v-if="setUp">
            <div class="presupposition_views_order_add" @click="viewaAdd = true" v-if="!viewaAdd">
              <img class="presupposition_views_order_addicon" :src="require('@/assets/dy/添加视角.png')" alt="" />
              <div class="presupposition_views_order_addtext">添加预设点</div>
            </div>

            <div class="presupposition_views_order_addbox" v-else>
              <div class="presupposition_views_order_addbox_row">
                <div class="presupposition_views_order_addbox_row_label">
                  编号
                </div>
                <div class="presupposition_views_order_addbox_row_value">
                  {{ array.length > 0 ? array[array.length - 1].number + 1 : 1 }}
                </div>
              </div>
              <div class="presupposition_views_order_addbox_row">
                <div class="presupposition_views_order_addbox_row_label">
                  名称
                </div>
                <input type="text" class="presupposition_views_order_addbox_row_input" v-model="viewName" />
              </div>
              <div class="presupposition_views_order_addbox_row">
                <button class="button_no" @click="viewaAdd = false">
                  取消
                </button>
                <button class="button" @click="clickDeviceCapturePic">
                  确定添加
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="presupposition_views-right-icon" @click="switchView('右')">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </transition>
    <!-- <div class="sdhd">
      <transition name="el-fade-in-linear">
        <div class="camera-button-sk" v-if="!viewShow" @click="hdsdClick(2)">
          <img v-if="iStreamType == 2" :src="require('@/assets/img/高清影像..@3x.png')" />
          <img v-else :src="require('@/assets/img/高清影像拷贝@3x.png')" />
        </div>
      </transition>
      <transition name="el-fade-in-linear">
        <div class="camera-button-sk" v-if="!viewShow" @click="hdsdClick(1)">
          <img v-if="iStreamType == 1" :src="require('@/assets/img/高清影像拷贝2@3x.png')" />
          <img v-else :src="require('@/assets/img/高清影像@3x.png')" />
        </div>
      </transition>
    </div> -->
  </div>
</template>

<script>
import { h } from "vue";
import {
  get_auth_token, createSeaPtz, deleteSeaPtz,
  presetMove,
  presetClear,
  presetAdd,
  capture,
  getMountainCameraIdCapture
} from "@/api";

export default {
  name: "PresuppositionViews",
  data() {
    return {
      viewShow: false,
      viewLeft: 0,
      viewaAdd: false,
      viewName: "",
      loading: false,
      iStreamType: 1,
    };
  },
  inject:["globalData"],
  props: {
    array: Array,
    setUp: Boolean,
    cameraInfo: Boolean || Object,
    type: Number,
    token: String,
  },
  setup(props, ctx) {
    const setViewArray = (data) => {
      ctx.emit("setViewArray", data);
    };

    const setiStreamType = (data) => {
      console.log(data, 111111)
      ctx.emit("setiStreamType", data);
    };

    return { setViewArray, setiStreamType };
  },
  watch: {
    viewaAdd(val) {
      if (val === false) {
        this.viewName = "";
      }
    },
  },
  mounted() { this.chooseCss() },
  methods: {

    // 预设左右切换
    switchView(type) {
      const width = $(".presupposition_views_orders").width();

      if (type == "左") {
        if (this.viewLeft < 0) {
          this.viewLeft += 210;
        } else {
          this.msg("success", "前面没有了哦！");
        }
      } else {
        if (this.viewLeft > -(210 * (this.array.length - width / 210 + 1))) {
          this.viewLeft -= 210;
        } else {
          this.msg("success", "后面没有了哦！");
        }
      }

      $(".presupposition_views_order").css({
        transform: `translate(${this.viewLeft}px,0)`,
        "transition-duration": "300ms",
      });
    },
    // 删除预设点
    clear(val,i) {
      // console.log(val)
      presetClear({
        accessToken: this.token,
        deviceSerial: this.cameraInfo.serialNumber,
        channelNo: this.cameraInfo.channelNumber,
        index: val.number,
        url:this.globalData.IP
      }).then(res => {
        deleteSeaPtz({ id: this.array[i].id,
        url:this.globalData.IP }).then((res) => {
          console.log(res)
          // if (res.code) {
            this.msg("success", "删除成功！");
            var arr = this.array;
            arr.splice(i, 1);
            this.setViewArray(arr);
          // } else {
          //   this.msg("error", res.msg);
          // }
        });
      })

    },
    // 弹框
    msg(type, text) {
      this.$message({
        message: h(
          "p",
          {
            style:
              "color: #e9f0fe;text-shadow: 1px 1px 1px rgba(33, 57, 255, 0.23);background: linear-gradient(120deg, #1ad0f2 0%, #077df4 100%);-webkit-background-clip: text;-webkit-text-fill-color: transparent;",
          },
          text
        ),
        type,
        offset: 120,
        center: true,
      });
    },
    //  将base64转换为blob
    baseToBlob(dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    //将blob转换为file
    blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date();
      theBlob.name = fileName;
      return theBlob;
    },
    getBase64Image(img) {
      var i = 8;
      var canvas = document.createElement("canvas");
      canvas.width = img.width / i;
      canvas.height = img.height / i;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width / i, img.height / i);
      var ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
      var dataURL = canvas.toDataURL("image/" + ext);
      return dataURL;
    },
    // 新增预设点
    clickDeviceCapturePic() {
      var code = this.array.length > 0 ? this.array.length + 1 : 1;
      if (code > 255) return this.msg("error", "最多添加255个预设视角！");
      // console.log(this.cameraInfo)
      this.loading = true;
      capture({
        accessToken: this.token,
        deviceSerial: this.cameraInfo.serialNumber,
        channelNo: this.cameraInfo.channelNumber,
        url:this.globalData.IP
      }).then(im => {
        // console.log(im)
        this.clickSetPreset().then(ress => {
          // console.log(ress)
          var name = this.viewName,
            images = im.data.picUrl
          this.apiCode(ress.index, name, images).then(({ id }) => {
            this.viewaAdd = false;
            var arr = this.array;
            arr.push({
              id,
              number: ress.index,
              images,
              name,
            });
            this.setViewArray(arr);

            this.loading = false;
            this.msg("success", "添加成功！");
            setTimeout(() => {
              this.switchView("右");
            });
          });
        })

      })


    },
    // 设置预设的
    clickSetPreset() {
      return new Promise((resolve, reject) => {
        presetAdd({
          accessToken: this.token,
          deviceSerial: this.cameraInfo.serialNumber,
          channelNo: this.cameraInfo.channelNumber,
          url:this.globalData.IP
        }).then((res) => {
          resolve(res.data);
        });
      });

    },
    // 调用预置点
    clickGoPreset(i) {
      presetMove({
        accessToken: this.token,
        deviceSerial: this.cameraInfo.serialNumber,
        channelNo: this.cameraInfo.channelNumber,
        index: i,
        url:this.globalData.IP
      }).then(res => {
        console.log(res)
      })

    },

    apiCode(number, name, images) {
      return new Promise((resolve, reject) => {
        createSeaPtz({ number, name, images, monitorId: this.cameraInfo.id,url:this.globalData.IP }).then((res) => {
          resolve(res.data);
        });
      });
    },

    hdsdClick(val) {
      this.iStreamType = val;
      this.setiStreamType(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.presupposition_view_button {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0;
  width: 176px;
  height: 31px;
  line-height: 31px;
  text-align: center;
  background: url("../assets/dy/矩形2734.png") no-repeat center center;
  cursor: pointer;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: var(--countyTitle);
  z-index: 9;
}

.presupposition_views {
  position: absolute;
  left: 220px;
  bottom: 10px;
  width: calc(100% - 230px);
  height: 172px;
  background: rgba(0, 0, 0, 0.9);
  border: 1px solid var(--farmers-number-type-label1);
  border-radius: 10px;
  z-index: 9;
  display: flex;
  padding: 15px 0;
  box-sizing: border-box;

  .presupposition_views_clear {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
  }

  .presupposition_views_orders {
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
    display: flex;

    .presupposition_views_order {
      width: 200px;
      height: 100%;
      box-sizing: border-box;
      margin-right: 10px;
      flex-shrink: 0;
      display: flex;
      position: relative;
      justify-content: space-between;
      flex-direction: column;
      cursor: pointer;

      .presupposition_views_order_image {
        width: 100%;
        height: 112px;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        box-sizing: border-box;
        border: 2px solid transparent;

        .presupposition_views_order_image_img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
        }

        .presupposition_views_order_image_icon {
          width: 42px;
          height: 42px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
        }

        .presupposition_views_order_image_del_view {
          position: absolute;
          right: 0;
          bottom: 0;
          display: none;
          z-index: 5;
        }
      }

      .presupposition_views_order_image:hover {
        .presupposition_views_order_image_del_view {
          display: inline-block;
          cursor: pointer;
        }
      }

      .presupposition_views_order_name {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: var(--el-range-editor);
        line-height: 16px;
        box-sizing: border-box;
        padding-left: 10px;
        position: relative;
      }

      .presupposition_views_order_name::before {
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        width: 4px;
        background: var(--countyTitle);
        border-radius: 0px 4px 4px 0px;
        content: "";
      }

      .presupposition_views_order_add {
        width: 100%;
        height: 112px;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        box-sizing: border-box;
        cursor: pointer;
        background: rgba(255, 255, 255, 0.025);
        border: 1px solid var(--allFloors2);

        .presupposition_views_order_addicon {
          width: 36px;
          height: 36px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -90%);
        }

        .presupposition_views_order_addtext {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, 90%);
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: rgba(128, 137, 163, 0.7);
        }
      }

      .presupposition_views_order_addbox {
        width: 100%;
        height: 100%;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        box-sizing: border-box;
        background: rgba(255, 255, 255, 0.025);
        border: 1px solid var(--allFloors2);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        z-index: 9;

        .presupposition_views_order_addbox_row {
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 100%;
          font-size: 14px;
          font-family: Source Han Sans CN;
          box-sizing: border-box;
          padding: 0 13px;
          font-weight: 400;
          line-height: 20px;

          .presupposition_views_order_addbox_row_label {
            color: rgba(128, 137, 163, 0.7);
            width: 20%;
            flex-shrink: 0;
          }

          .presupposition_views_order_addbox_row_value {
            color: rgba(233, 240, 254, 0.7);
            flex-grow: 1;
          }

          .presupposition_views_order_addbox_row_input {
            color: rgba(233, 240, 254, 0.7);
            max-width: 80%;
            flex-grow: 1;
            border-radius: 3px;
            border: 0;
            height: 24px;
            box-sizing: border-box;
            padding: 0 5px;
            background: rgba(128, 137, 163, 0.1);
            box-shadow: 0px 5px 15px 0px rgba(11, 11, 28, 0.05),
              0px 0px 10px 0px rgba(11, 11, 28, 0.1);
          }
        }

        .presupposition_views_order_addbox_del {
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #e9f0fe;
          text-align: center;
          margin-bottom: -20px;
        }
      }

      button {
        min-width: 80px;
      }
    }

    .presupposition_views_order_true {
      .presupposition_views_order_image {
        border: 2px solid var(--farmers-number-type-label) !important;
      }

      .presupposition_views_order_name {
        color: var(--breed-number-info-label3) !important;
      }
    }
  }

  .presupposition_views-left-icon,
  .presupposition_views-right-icon {
    width: 40px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;

    i {
      color: rgba(255, 255, 255, 0.5);
      font-size: 20px;
    }
  }
}

.camera-button-sk {
  // position: absolute;
  // left: 220px;
  margin-bottom: 10px;
  width: 80px;
  height: 30px;
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid var(--farmers-number-type-label1);
  box-sizing: border-box;
  padding: 10px;
  border-radius: 10px;
  z-index: 9;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;

  div {
    font-size: 14px;
    line-height: 14px;
    color: var(--ssdaodi);
  }

  // img {
  //   width: 21px;
  // }
}

// 通用按钮
.button,
.button_no,
.button_clear {
  padding: 3px 8px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  cursor: pointer;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.button {
  background-color: rgba(69, 205, 245, 0.36);
  border: 1px solid rgba(69, 205, 245, 0.6);
  color: var(--servicesPinTypeclass2);
}

.button_clear {
  background-color: rgba(255, 49, 49, 0.36);
  border: 1px solid rgba(255, 49, 49, 0.6);
  color: #b9b9b9;
}

.button_no {
  background-color: rgba(128, 137, 163, 0.36);
  border: 1px solid rgba(128, 137, 163, 0.6);
  color: rgba(197, 200, 208, 1);
}

.button:hover {
  background-color: rgba(69, 205, 245, 0.6);
}

.button_clear:hover {
  background-color: rgba(255, 49, 49, 0.6);
}

.button_no:hover {
  background-color: rgba(128, 137, 163, 0.6);
}

.button_clear:active.button:active,
.button_no:active {
  opacity: 0.9;
}

.sdhd {
  z-index: 50;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0%, -50%);
}
</style>
