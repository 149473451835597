<template>
  <div>
    <img class="bu" @click="state.is = !state.is" src="@/assets/images/b.png" />

    <div class="mars-dialog fadein-left" v-show="state.mounted && state.is">
      <div class="marsHeader bet">
        <span>3D视频融合配置</span>
        <img v-if="info.monitorType=='无人机监控'" @click="close" src="@/assets/images/d6.png" alt="" />
      </div>
      <SpaceDiv height="37" />
      <div class="bet">
        <span class="demonstration">水平张角:</span>
        <el-slider
          @input="changeAngle"
          style="width: 200px; margin-right: 10px"
          size="small"
          :min="0"
          :max="60"
          v-model="state.videoView.angle"
        />
        <el-input-number
          step="0.1"
          size="small"
          v-model="state.videoView.angle"
          :min="0"
          :max="60"
          @change="changeAngle"
        />
      </div>
      <div class="bet">
        <span class="demonstration">垂直张角:</span>
        <el-slider
          @input="changeAngle2"
          style="width: 200px; margin-right: 10px"
          size="small"
          :min="0"
          :max="30"
          v-model="state.videoView.angle2"
        />
        <el-input-number
          step="0.1"
          size="small"
          v-model="state.videoView.angle2"
          :min="0"
          :max="30"
          @change="changeAngle2"
        />
      </div>
      <div class="bet">
        <span class="demonstration">投射距离:</span>
        <el-slider
          @input="changeDistance"
          style="width: 200px; margin-right: 10px"
          size="small"
          :min="20"
          :max="5000"
          v-model="state.videoView.distance"
        />
        <el-input-number
          step="0.1"
          size="small"
          v-model="state.videoView.distance"
          :min="20"
          :max="5000"
          @change="changeDistance"
        />
      </div>
      <div class="bet">
        <span class="demonstration">四周方向:</span>
        <el-slider
          @input="changeHeading"
          style="width: 200px; margin-right: 10px"
          size="small"
          :min="0"
          :max="360"
          v-model="state.videoView.heading"
        />
        <el-input-number
          step="0.1"
          size="small"
          v-model="state.videoView.heading"
          :min="0"
          :max="360"
          @change="changeHeading"
        />
      </div>
      <div class="bet">
        <span class="demonstration">俯仰角度:</span>
        <el-slider
          @input="changePitch"
          style="width: 200px; margin-right: 10px"
          size="small"
          :min="-180"
          :max="180"
          v-model="state.videoView.pitch"
        />
        <el-input-number
          step="0.1"
          size="small"
          v-model="state.videoView.pitch"
          :min="-180"
          :max="180"
          @change="changePitch"
        />
      </div>
      <div class="divFlex" style="margin: 10px 0">
        <span class="demonstration">视椎框线:</span>
        <el-checkbox
          @change="changeFrustum"
          v-model:checked="state.videoView.showFrustum"
          >是否显示</el-checkbox
        >
      </div>
      <!-- @input="changeOpacity" -->
      <div class="column mousePoint" style="margin-top: 8px">
        <div class="bet">
          <span class="demonstration">固定视口:</span>
          <div class="pickPst" @click="pickViews">选取视口</div>
        </div>
        <SpaceDiv height="10" />
        <div class="valueDiv">
          <span>{{ state.videoView.views }} </span>
        </div>
      </div>

      <SpaceDiv height="60" />
      <div class="divFlex bottomBtn">
        <div
          class="activeBtn"
          :style="duibi() ? '' : 'opacity:0.5;pointer-events:none;'"
          @click="saveOption()"
        >
          <span>保存设置</span>
        </div>
        <div class="disBtn" @click="clearView">
          <span>取消</span>
        </div>
      </div>
      <video
        v-if="info.video_url"
        :id="idStr"
        ref="livePlayer_Box"
        class="video-js vjs-default-skin vjs-big-play-centered livePlayer_Box"
        controls
        autoplay
        muted
        preload="auto"
        width="300"
        height="250"
      >
      
      <!-- src=" http://mars3d.cn/editor-vue.html?key=ex_7_10_9&id=graphic/video/video3D-flv" -->
        <source
          id="source"
          :src="info.video_url"
          type="application/x-mpegURL"
        />
      </video>
    </div>
  </div>
</template>

<script>
let videoElement;
export let graphicLayer; // 矢量图层对象

import http from "@/api/server";
import {
  h,
  ref,
  reactive,
  watch,
  computed,
  getCurrentInstance,
  onMounted,
  onActivated,
  onDeactivated,
  onUnmounted,
} from "vue";

export default {
  name: "MarsDialog",
  props: ["info", "token", "ptz", "viewArr", "idStr"],
  setup(props, ctx) {
    const _this = getCurrentInstance().proxy;
    const state = reactive({
      videoView: {
        id: "",
        angle: 46.3,
        angle2: 15.5,
        heading: 88.5,
        pitch: -49.5,
        distance: 78,
        views: "",
        showFrustum: false,
      },
      // myVideoSrc: "",
      mounted: false,
      is: false,
    });

    const duibi = () => {
      // return false
      let { angle, angle2, heading, pitch, distance, views, showFrustum } =
        state.videoView;
      if (
        angle == props.ptz.angle &&
        angle2 == props.ptz.angle2 &&
        heading == props.ptz.heading &&
        pitch == props.ptz.pitch &&
        distance == props.ptz.distance &&
        views == props.ptz.views &&
        showFrustum == props.ptz.showFrustum
      ) {
        return false;
      }
      return true;
    };

    const addDemoGraphic1 = () => {
      map.scene.globe.depthTestAgainstTerrain = true;
      videoElement = _this.$refs.livePlayer_Box;

      const video2D = new mars3d.graphic.Video3D({
        position: props.info.position,
        style: Object.assign(
          {
            container: videoElement,
          },
          state.videoView
        ),
        // flyTo: true,
      });
      // console.log(props.ptz);
      if (props.ptz?.views) {
        let views = JSON.parse(props.ptz.views);
        map.setCameraView(views);
      } else {
        map.flyToPoint(props.info.position, {
          radius: 97, // 距离目标点的距离
          duration: 2,
        });
      }

      video2D.on(mars3d.EventType.click, function (event) {});
      video2D.id = "hls视频";
      graphicLayer.addGraphic(video2D);
    };

    // 修改水平角度
    const changeAngle = (value) => {
      if (state.videoView) {
        state.videoView.angle = value;
        changeOption("angle");
      }
    };

    // 修改垂直角度
    const changeAngle2 = (value) => {
      if (state.videoView) {
        state.videoView.angle2 = value;
        changeOption("angle2");
      }
    };

    // 修改投射距离
    const changeDistance = (value) => {
      if (state.videoView) {
        state.videoView.distance = value;
        changeOption("distance");
      }
    };

    // 修改四周距离 value 修改后的数值
    const changeHeading = (value) => {
      if (state.videoView) {
        state.videoView.heading = value;
        changeOption("heading");
      }
    };

    const changeOpacity = (value) => {
      if (state.videoView) {
        state.videoView.opacity = value;
        console.log(state.videoView.opacity);

        changeOption("opacity");
      }
    };
    const changeFrustum = (value) => {
      if (state.videoView) {
        state.videoView.showFrustum = value;
        changeOption("showFrustum");
      }
    };
    //  修改俯仰角数值   value 修改后的数值
    const changePitch = (value) => {
      if (state.videoView) {
        state.videoView.pitch = value;
        changeOption("pitch");
      }
    };

    const changeOption = (key) => {
      graphicLayer.eachGraphic((graphic) => {
        if (
          graphic &&
          graphic.state == "added" &&
          graphic.id.indexOf("hls视频") != -1
        ) {
          // console.log(graphic);
          graphic[key] = state.videoView[key];
        }
      });
    };
    const clearView = () => {
      state.is = false;
      // ctx.emit("closeViewShow", false);
      // map.scene.globe.depthTestAgainstTerrain = false;
    };

    const close = () => {
      state.is = false;
      ctx.emit("closeViewShow", false);
      map.scene.globe.depthTestAgainstTerrain = false;
    };
    
    const saveOption = () => {
      if (props.viewArr.length == 0) {
        return _this.$message({
          message: "请添加预设点后再保存配置",
          type: "warning",
        });
      }
      state.videoView.id = props.ptz.id;
      if (!state.videoView.views) {
        return _this.$message({
          message: "请选取并保存固定视角！！！！",
          type: "warning",
        });
      }
      state.videoView.views =
        typeof state.videoView.views == "string"
          ? state.videoView.views
          : JSON.stringify(state.videoView.views);
      http.saveVideoConfig(state.videoView).then((res) => {
        _this.$message({
          message: res.msg,
          type: res.msg == "操作成功" ? "success" : "warning",
        });
        ctx.emit("getNewArr", props.info);
      });
    };
    const playVideo = async () => {
      // alert(1111)
      // console.log(props.info);
      videojs(props.idStr, {
        bigPlayButton: true,
        textTrackDisplay: false,
        posterImage: false,
        errorDisplay: false,
        controlBar: true,
      });

      setTimeout(() => {
        addDemoGraphic1();
      }, 500);
    };
    const pickViews = () => {
      state.videoView.views = map.getCameraView();
    };
    watch(
      () => props.ptz,
      async (val) => {
        // console.log(props.info.video_url)
        // console.log(props.ptz)

        let { angle, angle2, heading, pitch, distance, views, showFrustum } =
          val;
        state.videoView = {
          angle: angle ? Number(angle) : 46.3,
          angle2: angle2 ? Number(angle2) : 15.5,
          heading: heading ? Number(heading) : 270,
          pitch: pitch ? Number(pitch) : -49.5,
          distance: distance ? Number(distance) : 78,
          views: views ? views : "",
          showFrustum: showFrustum ? showFrustum : false,
        };

        state.mounted = true;
        setTimeout(() => {
          graphicLayer.eachGraphic((graphic) => {
            if (
              graphic &&
              graphic.state == "added" &&
              graphic.id.indexOf("hls视频") != -1
            ) {
              // console.log(graphic);
              graphic.remove(true);
            }
          });
          playVideo();
        }, 800);
      },
      {
        deep: true,
        immediate: true,
      }
    );

    onMounted(async () => {
      graphicLayer = new mars3d.layer.GraphicLayer();
      // graphicLayer.on(mars3d.EventType.click, function (event) {});
      map.addLayer(graphicLayer);
    });

    onActivated(async () => {
      // alert('onActivated')
      graphicLayer.show = true;
    });

    onDeactivated(async () => {
      // alert('onDeactivated')
      graphicLayer.show = false;
    });

    onUnmounted(() => {
      // console.log(12.1213)
      map.scene.globe.depthTestAgainstTerrain = false;
      graphicLayer.remove();
    });
    return {
      state,
      changeAngle,
      changeAngle2,
      changeDistance,
      changeHeading,
      changePitch,
      changeOpacity,
      changeOption,
      playVideo,
      saveOption,
      clearView,
      duibi,
      close,
      pickViews,
      changeFrustum,
    };
  },
};
</script>

<style lang="scss" scoped>
.bu {
  position: fixed;
  z-index: 12;
  right: 30px;
  top: 106px;
  cursor: pointer;
}

.mars-dialog {
  position: fixed;
  box-sizing: border-box;
  padding: 20px;
  z-index: 100;
  right: 30px;
  top: 166px;
  background: rgba(2, 11, 18, 0.8);
  border: 1px solid rgba(2, 152, 251, 0.8);
  border-radius: 10px;
  .marsHeader {
    span {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #0ff3ee;
      line-height: 20px;
    }

    img {
      cursor: pointer;
    }

    .editBtn {
      cursor: pointer;
      margin-left: 40px;
      width: 50px;
      height: 20px;
      background: rgba(69, 205, 245, 0.5);
      border: 1px solid #45cdf5;
      border-radius: 10px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #befff6;
      line-height: 20px;
      text-align: center;
    }
  }

  .bet,
  .divFlex {
    .demonstration {
      width: 80px;
      box-sizing: border-box;
      display: block;
      // text-align: right;
      color: white;
      min-width: 60px;
      font-size: 14px;
    }
  }
}
.bottomBtn {
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;

  justify-content: center;
  .activeBtn {
    margin: 0 5px;
    width: 100px;
    height: 30px;
    border: 1px solid rgba(38, 120, 213, 1);
    border-radius: 5px;
    padding: 4px 9px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    background: rgba(0, 178, 255, 0.25);
    border: 1px solid rgba(0, 178, 255, 0.5);
    border-radius: 15px;
    span {
      font-size: 14px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #738ea2;
      background: linear-gradient(
        to top,
        rgba(138, 199, 255, 1) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .disBtn {
    margin: 0 5px;
    width: 100px;
    height: 30px;
    background: rgba(105, 124, 167, 0.25);
    border: 1px solid rgba(105, 124, 167, 0.5);

    border-radius: 15px;
    padding: 4px 9px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

    span {
      font-size: 14px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: rgba(115, 142, 162, 1);
    }
  }
}

.livePlayer_Box {
  width: 20px;
  height: 20px;
  position: fixed;
  left: 50px;
  top: 10px;
  z-index: -22;
  opacity: 0;
  pointer-events: none;
}

.pickPst {
  width: 60px;
  height: 16px;
  background: rgba(38, 130, 240, 0.2);
  border: 1px solid rgba(38, 182, 240, 0.8);
  box-shadow: inset 0px -2px 10px 0px rgba(4, 244, 252, 0.3),
    0px -1px 20px 0px rgba(38, 182, 240, 0.6);
  border-radius: 3px;

  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #04f4fc;
  line-height: 16px;
  text-align: center;
  margin-right: 6px;
}

.valueDiv {
  width: 300px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #697ca7;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  span:nth-child(1) {
    max-width: 180px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
