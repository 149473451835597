<template>
  <div class="divFlex alignCenter mousePoint checkParent">
    <div class="dot" style="opacity: 0"></div>
    <el-checkbox
      @change="changeParent(obj.checked, title, obj)"
      v-model="obj.checked"
      size="large"
    />
    <div
      v-if="borderShow"
      class="borderDiv"
      :style="`border: 1px ${obj.line?obj.line:'solid'}; border-color:${obj.color};background:${hexToRgb(
        obj.backGround,
        obj.opacity
      )}`"
    ></div>
    <span class="checkLeaderName">{{ title }}</span>
  </div>
</template>

<script>
// VUE
import { reactive, getCurrentInstance, onMounted, watch, inject } from "vue";
export default {
  name: "BuildingType",
  components: {},
  props: {
    obj: {
      default: {},
    },
    borderShow: {
      default: true,
    },
    title: "",
  },

  setup(props, context) {
    const _this = getCurrentInstance().proxy;
    const globalData = inject("globalData");
    let state = reactive({}); // 数据
    const changeParent = (value, name, obj) => {
      globalData.wmsObj = false;
      globalData.clickType = 1;

      obj.name = name;
      obj.value = value;
      globalData.wmsObj = obj;

      if (!obj.isMultipleOptions) {
        for (let key in globalData.chooseObj) {
          if (![name].includes(key)) {
            globalData.chooseObj[key].checked = false;
          }
        }
      }

      // if(globalData.chooseObj)
    };
    onMounted(async () => {});

    return {
      // 变量
      state,
      //方法
      changeParent,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";

.borderDiv {
  width: 14px;
  height: 14px;
  background: rgba(19, 201, 130, 0);
  border: 1px solid #1577ff;
  border-radius: 3px;
  box-sizing: border-box;
  margin-top: 1px;
  margin-left: 10px;
}
</style>
