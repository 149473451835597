<template>
  <div
    class="divFlex alignCenter mousePoint checkParent relative"
   
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1024 1024"
      data-v-ea893728=""
      class="svg"
      :style="state.open ? 'rotate: 90deg;' : ''"
      @click="state.open = !state.open"
    >
      <path
        fill="currentColor"
        d="M338.752 104.704a64 64 0 0 0 0 90.496l316.8 316.8-316.8 316.8a64 64 0 0 0 90.496 90.496l362.048-362.048a64 64 0 0 0 0-90.496L429.248 104.704a64 64 0 0 0-90.496 0z"
      ></path>
    </svg>

    <div class="dot" style="opacity: 0"></div>
    <el-checkbox
      @change="changeParent(obj.checked, title)"
      v-model="obj.checked"
      size="large"
    />
    <span  @click="state.open = !state.open" class="checkLeaderName">{{ title }}</span>
  </div>
  <el-collapse-transition>
    <div class="column wrap hid" v-if="state.open">
      <div
        class="divFlex alignCenter checkChild mousePoint alignCenter"
        :key="idx + 'kkkkkfff'"
        style="margin-bottom: 20px"
        v-for="(item, idx) in Object.keys(obj).splice(1)"
      >
        <el-checkbox
          @change="changeChild(obj[item].checked, item)"
          v-model="obj[item].checked"
          size="large"
        />
        <div
          :style="`background: ${obj[item].background};border: 1px dashed ${obj[item].border};`"
          class="borderLine"
        ></div>
        <span class="checkName">{{ item }}</span>
      </div>
    </div>
  </el-collapse-transition>
</template>

<script>
// VUE
import { reactive, getCurrentInstance, onMounted, watch, inject } from "vue";
export default {
  name: "BuildingType1",
  components: {},
  props: {
    obj: {
      default: {},
    },
    borderShow: {
      default: true,
    },
    title: "",
  },

  setup(props, context) {
    const _this = getCurrentInstance().proxy;
    let state = reactive({
      open: false,
    }); // 数据
    const globalData = inject("globalData");
    const changeParent = (value, name) => {
      console.log(state.open == value);
      state.open = state.open == value ? state.open : value;
      globalData.wmsObj = {
        name,
        value,
      };
      for (let key in props.obj) {
        if (key != "checked") {
          props.obj[key].checked = value;
        }
      }
    };
    const changeChild = (value, name) => {
      globalData.wmsObj = {
        name,
        value,
      };
      if (value) {
        props.obj.checked = true;
        return;
      }
      let bool = false;
      for (let key in props.obj) {
        if (key != "checked" && props.obj[key].checked == true) {
          bool = true;
        }
      }
      props.obj.checked = bool;
    };
    onMounted(async () => {});

    return {
      // 变量
      state,
      //方法
      changeChild,
      changeParent,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";

.svg {
  position: absolute;
  left: 10px;
  top: 13px;
  z-index: 1;
  color: #738ea2;
  width: 10px;
  transition: all 0.3s;
}
</style>
