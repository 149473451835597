<template>
  <div class="divFlex alignCenter mousePoint checkParent relative">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1024 1024"
      data-v-ea893728=""
      class="svg"
      :style="state.open ? 'rotate: 90deg;' : ''"
      @click="state.open = !state.open"
    >
      <path
        fill="currentColor"
        d="M338.752 104.704a64 64 0 0 0 0 90.496l316.8 316.8-316.8 316.8a64 64 0 0 0 90.496 90.496l362.048-362.048a64 64 0 0 0 0-90.496L429.248 104.704a64 64 0 0 0-90.496 0z"
      ></path>
    </svg>

    <div class="dot" style="opacity: 0"></div>
    <!-- v-if="obj.isAllCheckChildren" -->
    <el-checkbox
      v-if="obj.isAllCheckChildren"
      @change="changeParent(obj.checked, title, obj)"
      v-model="obj.checked"
      size="large"
    />
    <!-- :style="!obj.isAllCheckChildren?'margin-left:0':''" -->
    <span
      :style="!obj.isAllCheckChildren ? 'margin-left:0' : ''"
      @click="state.open = !state.open"
      class="checkLeaderName"
      >{{ title }}</span
    >
  </div>
  <el-collapse-transition>
    <div class="column wrap hid" v-if="state.open">
      <div
        :class="
          item.isCheckChildren
            ? 'divFlex alignCenter checkChild mousePoint'
            : 'divFlex alignCenter checkChild mousePoint notCheckChildren'
        "
        :key="idx + 'kkkkkfff'"
        style="margin-bottom: 20px"
        v-for="(item, idx) in obj.children"
      >
        <el-checkbox
          @change="changeChild(item)"
          v-model="item.checked"
          size="large"
        />
        <div
          v-if="borderShow"
          :style="`border: 1px  ${item.line ? item.line : 'solid'}  ${
            item.color
          }; background: ${hexToRgb(item.backGround, item.opacity)};`"
          class="borderLine"
        ></div>
        <span class="checkName" style="margin-left: 9px">{{ item.name }}</span>
      </div>
    </div>
  </el-collapse-transition>
</template>

<script>
// VUE
import { reactive, getCurrentInstance, onMounted, watch, inject } from "vue";
export default {
  name: "BuildingType2",
  components: {},
  props: {
    obj: {
      default: {},
    },
    borderShow: {
      default: true,
    },
    title: "",
  },

  setup(props, context) {
    const _this = getCurrentInstance().proxy;
    let state = reactive({
      open: false,
    }); // 数据
    const globalData = inject("globalData");
    const changeParent = (value, name, item) => {
      state.open = state.open == value ? state.open : value;
      globalData.clickType = 2;
      if (props.title == "地图") {
        //此处弃用
        // 单选的方法
        if (!value) {
          props.obj.children.forEach((element) => {
            element.checked = value;
          });
        }
      } else {
        // 多选的方法
        globalData.singleObj = item;
        if (props.obj.isAllCheckChildren == 1) {
          props.obj.children.forEach((element) => {
            element.checked = value;
          });
        }

        if (!item.isMultipleOptions) {
          for (let key in globalData.chooseObj) {
            if (![item.type].includes(key)) {
              globalData.chooseObj[key].checked = false;
              if (
                globalData.chooseObj[key].children &&
                globalData.chooseObj[key].children.length > 0
              ) {
                let data = globalData.chooseObj[key].children;
                data.forEach((e) => {
                  e.checked = false;
                });
              }
            }
          }
        }
      }
    };

    const changeChild = (item) => {
      globalData.clickType = 2;
      if (!item.isMultipleOptions) {
        for (let key in globalData.chooseObj) {
          if (![item.type].includes(key)) {
            globalData.chooseObj[key].checked = false;
            if (
              globalData.chooseObj[key].children &&
              globalData.chooseObj[key].children.length > 0
            ) {
              let data = globalData.chooseObj[key].children;
              data.forEach((e) => {
                e.checked = false;
              });
            }
          }
        }
      }

      globalData.singleObj = item;
      if (props.title == "地图") {
        //此处弃用
        props.obj.children.forEach((e) => {
          if (e.name != item.name) {
            e.checked = false;
          }
        });

        if (item.checked) {
          props.obj.checked = true;
          return;
        }
        let bool = false;

        props.obj.children.forEach((e) => {
          if (e.checked == true) {
            bool = true;
          }
        });
        props.obj.checked = bool;
      } else {
        if (item.isCheckChildren) {
          // 多选的方法
          if (item.checked) {
            props.obj.checked = true;
            return;
          }
          let bool = false;
          props.obj.children.forEach((e) => {
            if (e.checked == true) {
              bool = true;
            }
          });
          props.obj.checked = bool;
        } else {
          // console.log(111111)
          // 单选的方法
          props.obj.children.forEach((e) => {
            if (e.id == item.id) {
              e.checked = item.checked;
            } else {
              e.checked = false;
            }
            props.obj.checked = item.checked;
          });
        }
      }
    };
    onMounted(async () => {
      // console.log(props.obj)
      // console.log(props.title)
    });

    return {
      // 变量
      state,
      //方法
      changeChild,
      changeParent,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";

.svg {
  position: absolute;
  left: 10px;
  top: 13px;
  z-index: 1;
  color: #738ea2;
  width: 10px;
  transition: all 0.3s;
}
</style>
