<template>
  <div class="trapezoidBox divFlex alignCenter">
    <img src="@/assets/dc/图层 607.png" />
    <div class="trapezoid divFlex alignCenter">
      <img
        @click="globalData.allNavShow = true"
        class="navLogo"
        :src="
          secMenu.id == '1750330232077389825'
            ? require(`../..//assets/dc/p.png`)
            : require(`../..//assets/dc/pp.png`)
        "
        alt=""
      />
      <div
        :class="
          globalData.secUrl == item.url
            ? secMenu.id == '1750330232077389825'
              ? 'party'
              : 'activeText'
            : secMenu.id == '1750330232077389825'
            ? 'disParty'
            : 'disText'
        "
        @click="to(item)"
        v-for="(item, idx) in secMenu.children"
        :key="item + idx"
      >
        <span>{{ item.name }}</span>
        <img
          v-if="globalData.secUrl == item.url"
          :src="
            secMenu.id == '1750330232077389825'
              ? require('../../assets/menu/1212.png')
              : require('../../assets/menu/menu11111.png')
          "
        />
      </div>
    </div>
    <img src="@/assets/dc/图层 609.png" />
  </div>
</template>

<script>
// VUE
import {
  ref,
  reactive,
  computed,
  watch,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  onActivated,
  onDeactivated,
  inject,
} from "vue";
export default {
  props: {
    secMenu: {
      default: {},
    },
  },
  setup(props, context) {
    const globalData = inject("globalData");
    const _this = getCurrentInstance().proxy;
    let state = reactive({
      navShow: false,
    }); // 数据

    const to = (val) => {
      _this.setCookie("secName", JSON.stringify({ secName: val.name }));
      if (val.url == "船舶监测") {
        window.open("https://www.shipxy.com/", "_blank");
        return;
      }
      if (val.url) {
        globalData.secUrl = val.url;
        _this.$router.push(val.url);
      }
    };
    onMounted(async () => {});
    return { state, to, globalData };
  },
};
</script>

<style lang="scss" scoped>
.navLogo {
  width: fit-content;
  height: fit-content;
  margin-top: 10px;
}
.trapezoidBox {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10;
}
.trapezoid {
  cursor: pointer;

  // border-bottom: 55px solid rgba(35, 42, 50, 0.5);
  // border-left: 30px solid transparent;
  // border-right: 30px solid transparent;
  height: 55px;
  width: auto;
  // background: linear-gradient(
  //   to bottom,
  //   rgba(35, 42, 50, 0.5) 0%,
  //   rgba(35, 42, 50, 0.5) 100%
  // );
  background-image: url("../../assets/dc/多边形 606 拷贝.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .activeText {
    font-family: Source Han Sans SC;
    font-weight: bold;
    font-size: 18px;
    color: #22e2ff;
    // text-shadow: 0px 2px 2px rgba(40, 40, 40, 0.2),
    //   0px 4px 8px rgba(40, 40, 40, 0.4);
    // text-stroke: 1px #ffffff;
    background: linear-gradient(
      to right,
      rgba(34, 226, 255, 1) 0%,
      rgba(166, 255, 239, 1) 65%
    );
    // -webkit-text-stroke: 1px #ffffff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 13px;
    position: relative;
    margin-left: 20px;
    height: fit-content;
    white-space: nowrap;
    img {
      position: absolute;
      bottom: -3px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .party {
    background: linear-gradient(
      to right,
      rgba(255, 89, 88, 1) 0%,
      rgba(255, 203, 80, 1) 65%
    );

    font-family: Source Han Sans SC;
    font-weight: bold;
    font-size: 18px;
    color: #22e2ff;

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 13px;
    position: relative;
    margin-left: 20px;
    height: fit-content;
    white-space: nowrap;
    img {
      position: absolute;
      bottom: -3px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .disParty {
    font-family: Source Han Sans SC;
    font-weight: bold;
    font-size: 16px;
    color: rgba(255, 89, 88, 0.75);
    margin-top: 10px;
    position: relative;
    margin-left: 20px;
    white-space: nowrap;
    img {
      position: absolute;
      bottom: -3px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .disText {
    font-family: Source Han Sans SC;
    font-weight: bold;
    font-size: 16px;
    color: rgba(34, 226, 255, 0.75);
    margin-top: 10px;
    position: relative;
    margin-left: 20px;
    white-space: nowrap;
    img {
      position: absolute;
      bottom: -3px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
</style>
