<template>
  <div class="bigDiv">
    <!-- <el-drawer v-model="show"  :with-header="false" direction="ltr" size="100%" :modal="false"> -->
    <svg
      @click="clearYSY()"
      class="box_camera_clear"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm0 393.664L407.936 353.6a38.4 38.4 0 1 0-54.336 54.336L457.664 512 353.6 616.064a38.4 38.4 0 1 0 54.336 54.336L512 566.336 616.064 670.4a38.4 38.4 0 1 0 54.336-54.336L566.336 512 670.4 407.936a38.4 38.4 0 1 0-54.336-54.336L512 457.664z"
      ></path>
    </svg>
    <!-- v-if="cameraType" -->
    <div class="box_camera" v-if="cameraType">
      <div class="box_camera_content" id="videContainer_Box" ref="viewtools">
        <Hk
          v-if="livePlayUrl && !RecordVideoShow"
          idStr="videContainer"
          :camera="camera"
          :allUrls="livePlayUrl"
          splitNum="1"
          :mode="1"
          :chooseBtn="chooseBtn"
          :needCapture="true"
        />
        <playback
          v-if="RecordVideoShow"
          :dateTime="recordVideoTime"
          :cameraIndexCode="camera.deviceNumber"
          ref="childVideo"
          classStr="childVideoBox"
        />
        <PresuppositionView
          :setUp="true"
          :array="viewArr"
          :token="token"
          :cameraInfo="camera"
          type="2"
          @setViewArray="(val) => (viewArr = val)"
          @setiStreamType="setiStreamType"
        />
        <Viewcamera
          style="bottom: 50px"
          :token="token"
          :cameraInfo="camera"
        />
      </div>
      <div class="box_camera_datas">
        <div class="box_camera_name">
          <!-- <img v-if="camera.type == '1'" src="@/assets/static/f6.png" />
          <img v-if="camera.type == '2'" src="@/assets/static/f5.png" /> -->
          {{ camera.type == "1" ? "AI智能感知摄像机" : "高位球机" }}
          <!-- {{ camera.type == "1" ? "定点直播" : "高位球机" }} -->
        </div>
        <div class="box_camera_data">
          设备名称：<span>{{ camera.name || camera.equipmentName }}</span>
        </div>
        <div class="box_camera_data">
          设备型号：<span>{{ camera.serialNumber }}</span>
        </div>
        <div class="box_camera_data">
          设备位置：<span>{{ camera.address }}</span>
        </div>
        <div class="box_camera_data">
          是否在线：<span>{{ camera.status == "2" ? "是" : "否" }}</span>
        </div>

        <button class="box_camera_button" @click="lishi()">
          {{ !RecordVideoShow ? "历史回放" : "实时直播" }}
        </button>
      </div>
    </div>

    <div class="rox_camera" v-else>
      <div class="box_camera_content">
        <div id="videContainer"></div>
      </div>
      <div class="box_camera_datas">
        <div class="box_camera_name">
          <Hk
            v-if="livePlayUrl && !RecordVideoShow"
            idStr="videContainer"
            :camera="camera"
            :allUrls="livePlayUrl"
            splitNum="1"
            :mode="1"
            :chooseBtn="chooseBtn"
            :needCapture="true"
          />
          <playback
            v-if="RecordVideoShow"
            :dateTime="recordVideoTime"
            :cameraIndexCode="camera.deviceNumber"
            ref="childVideo"
            classStr="childVideoBox"
          />
          <Icons name="a-jiankong2" class="icon3" />
          高位全景摄像机
        </div>
        <div class="box_camera_data">
          设备名称：<span>{{ camera.monitorName }}</span>
        </div>
        <div class="box_camera_data">
          设备位置：<span>{{ camera.address }}</span>
        </div>
        <div class="box_camera_data">
          是否在线：<span>{{ camera.status == "2" ? "是" : "否" }}</span>
        </div>
        <button class="box_camera_button" @click="lishi()">
          {{ !RecordVideoShow ? "历史回放" : "实时直播" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import EZUIKit from "ezuikit-js";
import { Viewcamera} from "@/components";
import PresuppositionView from "@/components/PresuppositionView.vue";
import {createSeaPage } from "@/api";
import Icons from "@/components/Icons.vue";
import http from "@/api/server";
import Playback from "@/components/Playback.vue";
import Hk from "@/components/Hk.vue";
var player = null;
// var window.player = null;
export default {
  name: "Ysy",
  components: { Viewcamera, PresuppositionView, Icons, Hk, Playback },
  props: ["camera"],
  watch: {},
  inject: ["globalData"],
  data() {
    return {
      show: true,
      isHui: true,
      token: false,
      cameraType: false,
      viewArr: [],
      livePlayUrl: "",
      RecordVideoShow: false,
      recordVideoTime: false,
    };
  },
  mounted() {
    createSeaPage({ monitorId: this.camera.id }).then((res) => {
      this.viewArr = res.data.records;
    });
    this.cameraType = this.camera.type != 3;
    this.ec();
  },
  setup(props, ctx) {
    const clearClick = (data) => {
      ctx.emit("clearClick", data);
    };
    return { clearClick };
  },
  methods: {
    clearYSY(fun = false) {
      // this.callChildMethod();
      if (player) {
        player.stop().then(() => {
          // player.destroy().then(() => {
          if (fun === false) {
            this.show = false;
            setTimeout(() => {
              this.clearClick();
            }, 200);
          } else {
            fun();
          }
          // });
        });
      } else {
        if (fun === false) {
          this.show = false;
          setTimeout(() => {
            this.clearClick();
          }, 200);
        } else {
          fun();
        }
      }
    },

    lishi() {
      this.RecordVideoShow = !this.RecordVideoShow;
      this.recordVideoTime = [
        this.subtractSeconds(this.camera.createTime, -5),
        this.subtractSeconds(this.camera.createTime, 5),
      ];
    },
    ec() {
      http
        .iscBatchPlayAddressNew({
          cameraIndexCodes: this.camera.deviceNumber,
          protocol: "ws",
          streamType: 0,
          url: this.globalData.IP,
        })
        .then((res) => {
          this.livePlayUrl = res.data.map((e) => {
            return e.url;
          });
        });
    },
  },
  beforeDestroy() {
    this.clearYSY();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/default.scss";

.bigDiv {
  width: 1380px;
  // height: 844px;
  position: fixed;
  left: 0;
  top: 130px;
  z-index: 99;
  box-sizing: border-box;
}

.box_camera {
  width: 1380px;
  height: 816px;
  background: rgba(40, 40, 40, 1);
  box-shadow: 0px 5px 15px 0px rgba(36, 98, 112, 0.1);
  border-radius: 0px 20px 20px 0px;
  position: fixed;
  // left: 0;
  // top: 155px;
  box-sizing: border-box;
  padding: 10px;

  .box_camera_content {
    width: 1360px;
    height: 744px;
    background: #000;
    border-radius: 14px;
    overflow: hidden;
    position: relative;
  }
}

.rox_camera {
  width: 1380px;
  height: 640px;
  background: rgba(40, 40, 40, 1);
  box-shadow: 0px 5px 15px 0px rgba(36, 98, 112, 0.1);
  border-radius: 0 20px 20px 0;
  position: fixed;
  left: 0;
  top: 155px;
  // bottom: 200px;
  box-sizing: border-box;
  padding: 10px;

  .box_camera_content {
    width: 100%;
    height: 520px;
    background: #000;
    border-radius: 14px;
    overflow: hidden;
    position: relative;
  }
}

.box_camera_datas {
  margin: 0px auto 0 auto;
  max-width: 1500px;
  height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  // padding-right: 100px;
  .box_camera_name {
    font-size: 26px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: rgba(208, 242, 255, 1);

    img {
      width: 22px;
      height: 22px;
      margin-right: 5px;
    }
  }

  .box_camera_data {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: rgba(107, 142, 162, 1);
    line-height: 26px;

    span {
      color: rgba(166, 196, 207, 1);
    }
  }
}

.box_camera_clear {
  position: absolute;
  left: 1300px;
  top: 20px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 9;
  color: rgb(236, 87, 78);
  background: #fff;
  border-radius: 50%;
}

.box_camera_button {
  width: 160px;
  height: 40px;
  background: #22e2ff;
  border: 1px solid #22e2ff;
  box-shadow: 0px 5px 15px 0px rgba(1, 198, 255, 0.25),
    0px 10px 50px 0px rgba(1, 198, 255, 0.25);
  border-radius: 18px;
  margin-bottom: -5px;
  font-size: 24px;
  font-family: YouSheBiaoTiHei;
  font-weight: 400;
  color: #ffffff;
  // -webkit-text-stroke: 0.5px #03CC8C;
  // text-stroke: 0.5px #03CC8C;
  cursor: pointer;
}

#videContainer {
  width: 100%;
  height: 100%;
  margin-top: -48px;
}
</style>
