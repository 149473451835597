<template>
  <div
    id="app1"
    @dblclick.stop="handleDoubleClick"
    @mousedown.stop="startDrawing"
    @mousemove.stop="draw"
    @mouseup.stop="stopDrawing"
    @wheel.stop="handleWheel"
  >
    <div v-if="isDrawing" :style="divStyle"></div>
    <!-- <div v-if="camera.monitorType == 'AI球机'"> -->
    <div
      class="cDiv1"
      @mouseup.stop="mouseUpPTZControl()"
      @mousedown.stop="mouseDownPTZControl(2)"
      @mouseenter.stop="
        () => {
          index = 2;
        }
      "
      @mouseout.stop="
        () => {
          index = false;
        }
      "
      :style="index === 2 ? 'opacity:1' : 'opacity:0'"
    >
      <img class="l1" src="@/assets/images/左.png" alt="" />
    </div>

    <div
      class="cDiv2"
      @mouseup.stop="mouseUpPTZControl()"
      @mousedown.stop="mouseDownPTZControl(0)"
      @mouseenter.stop="
        () => {
          index = 0;
        }
      "
      @mouseout.stop="
        () => {
          index = false;
        }
      "
      :style="index === 0 ? 'opacity:1' : 'opacity:0'"
    >
      <img class="l2" src="@/assets/images/上.png" alt="" />
    </div>
    <div
      class="cDiv3"
      @mouseenter.stop="
        () => {
          index = 3;
        }
      "
      @mouseout.stop="
        () => {
          index = false;
        }
      "
      @mouseup.stop="mouseUpPTZControl()"
      @mousedown.stop="mouseDownPTZControl(3)"
      :style="index === 3 ? 'opacity:1' : 'opacity:0'"
    >
      <img class="l3" src="@/assets/images/右.png" alt="" />
    </div>

    <div
      class="cDiv4"
      @mouseup.stop="mouseUpPTZControl()"
      @mousedown.stop="mouseDownPTZControl(1)"
      @mouseenter.stop="
        () => {
          index = 1;
        }
      "
      @mouseout.stop="
        () => {
          index = false;
        }
      "
      :style="index === 1 ? 'opacity:1' : 'opacity:0'"
    >
      <img class="l4" src="@/assets/images/下.png" alt="" />
    </div>

    <img
      class="j1"
      @mouseup.stop="mouseUpPTZControl()"
      @mousedown.stop="mouseDownPTZControl(4)"
      @mouseenter.stop="
        () => {
          index = 4;
        }
      "
      @mouseout.stop="
        () => {
          index = false;
        }
      "
      src="@/assets/images/左上.png"
      alt=""
      :style="index === 4 ? 'opacity:1' : 'opacity:0'"
    />
    <img
      class="j2"
      @mouseup.stop="mouseUpPTZControl()"
      @mousedown.stop="mouseDownPTZControl(6)"
      @mouseenter.stop="
        () => {
          index = 6;
        }
      "
      @mouseout.stop="
        () => {
          index = false;
        }
      "
      src="@/assets/images/右上.png"
      alt=""
      :style="index === 6 ? 'opacity:1' : 'opacity:0'"
    />
    <img
      class="j3"
      @mouseup.stop="mouseUpPTZControl()"
      @mousedown.stop="mouseDownPTZControl(7)"
      @mouseenter.stop="
        () => {
          index = 7;
        }
      "
      @mouseout.stop="
        () => {
          index = false;
        }
      "
      src="@/assets/images/右下.png"
      alt=""
      :style="index === 7 ? 'opacity:1' : 'opacity:0'"
    />
    <img
      class="j4"
      @mouseup.stop="mouseUpPTZControl()"
      @mousedown.stop="mouseDownPTZControl(5)"
      @mouseenter.stop="
        () => {
          index = 5;
        }
      "
      @mouseout.stop="
        () => {
          index = false;
        }
      "
      src="@/assets/images/左下.png"
      alt=""
      :style="index === 5 ? 'opacity:1' : 'opacity:0'"
    />
    <!-- </div> -->
  </div>
</template>

<script>
import { Throttle,Debounce } from "@/utils";
import http from "@/api/server";
export default {
  name: "Test",
  props: ["camera"],
  inject:['globalData'],
  data() {
    return {
      index: false,
      selection: {
        startX: 0,
        startY: 0,
        endX: 0,
        endY: 0,
      },
      isDrawing: false,
      startX: 0,
      startY: 0,
      endX: 0,
      endY: 0,
      divStyle: {
        position: "fixed",
        border: "1px solid red",
      },
      start: false,
      iPTZIndex: false,
      scrollTimeout: false,
      mouseWheel:true
    };
  },
  watch: {
    // index(val) {
    //   console.log(val);
    // },
  },
  methods: {
    calculateSelection(start, end) {
      let wid = $(".box_camera_content").eq(0).width();
      let hgt = $(".box_camera_content").eq(0).height();
      let startX = Math.ceil(((start.x - 40) / wid) * 255);
      let startY = Math.ceil(((start.y - 160) / hgt) * 255);
      let endX = Math.ceil(((end.x - 40) / wid) * 255);
      let endY = Math.ceil(((end.y - 160) / hgt) * 255);
      return { startX, startY, endX, endY };
    },
    handleDoubleClick(event) {
      // console.log("handleDoubleClickhandleDoubleClickhandleDoubleClick");
      let wid = $(".box_camera_content").eq(0).width();
      let hgt = $(".box_camera_content").eq(0).height();
      this.selection = {
        startX: (event.x / wid) * 255,
        startY: (event.y / hgt) * 255,
        endX: (event.x / wid) * 255,
        endY: (event.y / hgt) * 255,
      };
      this.selection.cameraIndexCode = this.camera.deviceNumber;
      this.selection.url = this.globalData.IP
      http.iscPtzSelZoom(this.selection).then((res) => {
        this.start = null;
        this.selection = {
          startX: 0,
          startY: 0,
          endX: 0,
          endY: 0,
        };
      });
    },
    startDrawing(event) {
      // console.log("startDrawingstartDrawingstartDrawing");
      this.start = { x: event.clientX, y: event.clientY };
      this.isDrawing = true;
      this.startX = event.clientX;
      this.startY = event.clientY;
    },
    draw(event) {
      if (this.isDrawing) {
        let end = { x: event.clientX, y: event.clientY };
        this.selection = this.calculateSelection(this.start, end);
        // console.log(this.selection);
        this.endX = event.clientX;
        this.endY = event.clientY;
        this.divStyle.width = Math.abs(this.endX - this.startX) + "px";
        this.divStyle.height = Math.abs(this.endY - this.startY) + "px";
        this.divStyle.left = Math.min(this.startX, this.endX) + "px";
        this.divStyle.top = Math.min(this.startY, this.endY) + "px";
      }
    },
    stopDrawing() {
      let { startX, startY, endX, endY } = this.selection;
      if (startX == 0 && startY == 0 && endX == 0 && endY == 0) {
        this.start = null;
        this.isDrawing = false;
        return;
      }
      this.isDrawing = false;
      this.divStyle = {
        position: "fixed",
        border: "1px solid red",
      };

      this.draw(); // 确保结束选择时更新选择框位置
      this.selection.cameraIndexCode = this.camera.deviceNumber;
      this.selection.url = this.globalData.IP
      http.iscPtzSelZoom(this.selection).then((res) => {
        this.start = null;
        this.selection = {
          startX: 0,
          startY: 0,
          endX: 0,
          endY: 0,
        };
      });
    },
    handleWheel(event) {
      // 处理滚轮事件
      // console.log("滚轮事件触发", event);
      // return
      if(!this.mouseWheel) return;
      this.mouseWheel = false;
      if (this.scrollTimeout) {
        clearTimeout(this.scrollTimeout);
        this.scrollTimeout = false;
      }

      const delta = event.wheelDelta || -event.detail;
      const direction = delta > 0 ? "向上滚动" : "向下滚动";

      if (direction == "向上滚动") {
        this.mouseDownPTZControl(8);
      } else if (direction == "向下滚动") {
        this.mouseDownPTZControl(9);
      }

      this.scrollTimeout = setTimeout(() => {
        this.mouseUpPTZControl();
      }, 1000);

      setTimeout(() => {
        this.mouseWheel = true;
      }, 2000);
    },

    mouseDownPTZControl: Throttle(function (iPTZIndex) {
      // console.log(this.token,'this.token');
      // console.log(this.cameraInfo,'this.cameraInfo');
      this.iPTZIndex = iPTZIndex;
      http
        .ptzStart({
          cameraIndexCode: this.camera.serialNumber || this.camera.deviceNumber,
          direction: iPTZIndex,
          url:this.globalData.IP
        })
        .then((res) => {});
    }, 800),
    mouseUpPTZControl: Throttle(function () {
      http
        .ptzStop({
          cameraIndexCode: this.camera.serialNumber || this.camera.deviceNumber,
          direction: this.iPTZIndex,
          url:this.globalData.IP
        })
        .then((res) => {
          this.iPTZIndex = false;
        });
    }, 800),
  },
  mounted() {
    console.log(this.camera);
  },
};
</script>

<style>
#app1 {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.l1 {
  /* width: 100px; */
}
.l2 {
  /* rotate: 90deg; */
  /* width: 100px; */
}

.l3 {
  /* rotate: 180deg; */
  /* width: 100px; */
}

.l4 {
  /* rotate: 270deg; */
  /* width: 100px; */
}

.j1 {
  position: absolute;
  left: 0;
  top: 0%;
  /* transform: translate(0, 0%); */
  z-index: 5;
  width: 100px;
}
.j2 {
  position: absolute;
  right: 0%;
  top: 0%;
  /* transform: translate(0%, 0%); */
  z-index: 5;
  /* rotate: 90deg; */
  width: 100px;
}

.j3 {
  position: absolute;
  right: 0;
  bottom: 0%;
  /* transform: translate(0%, -50%); */
  z-index: 5;
  /* rotate: 180deg; */
  width: 100px;
}

.j4 {
  position: absolute;
  left: 0%;
  bottom: 0%;
  /* transform: translate(-50%, 0%); */
  z-index: 5;
  /* rotate: 270deg; */
  width: 100px;
}

.cDiv1 {
  width: 80px;
  height: 520px;
  /* background: ghostwhite; */
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cDiv2 {
  width: 520px;
  height: 80px;
  /* background: ghostwhite; */
  position: absolute;
  left: 50%;
  top: 0%;
  transform: translate(-50%, 0%);
  z-index: 5;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.cDiv3 {
  width: 80px;
  height: 520px;
  /* background: ghostwhite; */
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0%, -50%);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cDiv4 {
  width: 520x;
  height: 80px;
  /* background: ghostwhite; */
  position: absolute;
  left: 50%;
  bottom: 0%;
  transform: translate(-50%, 0%);
  z-index: 5;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
</style>
