<template>
  <div class="bottomBtn">
    <div
      v-for="(item, idx) in arr"
      :key="item + idx"
      :style="size ? 'width:' + size + 'px' : ''"
      @click="chooseThis(item)"
      :class="state.btn == item ? 'activeBtn' : 'disBtn'"
    >
      {{ idx }}
    </div>
  </div>
</template>

<script>
import {
  h,
  ref,
  reactive,
  watch,
  computed,
  getCurrentInstance,
  onMounted,
  onActivated,
  onDeactivated,
} from "vue";

export default {
  props: ["arr", "size", "btnVal"],
  setup(props, ctx) {
    const _this = getCurrentInstance().proxy;
    const state = reactive({
      btn: 0,
    });
    watch(
      () => props.arr,
      (val) => {
        state.btn = props.btnVal ? props.btnVal : 0;
      },
      {
        deep: true,
        immediate: true,
      }
    );
    const chooseThis = (idx) => {
      state.btn = idx;
      ctx.emit("chooseThis", idx);
    };
    onMounted(() => {
      // _this.chooseCss()
      if (props.btnVal) {
        state.btn = props.btnVal;
      }
    });

    return {
      state,
      chooseThis,
    };
  },
};
</script>

<style lang="scss" scoped>
.bottomBtn {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  cursor: pointer;

  .activeBtn {
    height: 24px;
    background: var(--nameBox);
    width: 80px;
    border: 1px solid var(--sspercent);
    border-radius: 5px;
    padding: 4px 9px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: var(--activeBtn);
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }

  .disBtn {
    height: 24px;
    width: 80px;
    background: var(--disBtn);
    border: 1px solid var(--ssprogress2);
    border-radius: 5px;
    padding: 4px 9px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: var(--ssprogress2);
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }
}
</style>
